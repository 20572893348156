import Link from '@mui/material/Link';

interface ButtonLinkProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined,
  underline?: "none" | "hover" | "always" | undefined,
  navigateTo: () => void,
  buttonText: string
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({ variant, underline, navigateTo, buttonText }) => {
  return (
    <Link
      component="button"
      variant={variant}
      underline={underline}
      onClick={navigateTo}
    >
      {buttonText}
    </Link>
  );
}