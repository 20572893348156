// import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Admin from "./Admin";
import { DatabaseIcon } from "./CustomIcons/VegaIcons";
import "./Homepage.scss";
import { DISPLAY_WORKSHEET, LAVENDER_VIOLET, SIGN_UP, WHITE } from "./models/constants";
import {
  loginAuthState,
  resetLoginAuthState,
  // setAccessToken,
  setHeader,
} from "./onBoarding/LoginAuthSlice";
import Profile from "./Profile";
import { toastError, toastInfo } from "./Toasts";
import { CustomizedTreeView } from "./schema/CustomTreeView";
import { WorkspaceListState, setWorkspaceList } from "./Workspace/WorkspaceListSlice";
import { workspaceState, setWorkspace } from "./Workspace/WorkspaceSlice";
import { TextButton } from "./buttons/TextButton";
import { ArrowOutwardSharp } from "@mui/icons-material";
import { OrgUserName, Workspace, WorkspaceDetail, WorkspaceDetailList } from "./models";
import { useGetWorkspaceListQuery } from "../api/workspaceApi_rtk";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface IconName {
  iconName: string;
  header: string;
}

const menus = [
  "Workspaces",
  "Data Warehouse",
  "Database",
  "Worksheets",
  "-",
  "Admin",
];

const excludeMenus = [menus[0], menus[5]];

const VegaIcon = ({ iconName, header }: IconName) => {
  const iconColor = { color: iconName === header ? WHITE : "#676780" };
  switch (iconName) {
    case menus[0]:
      return <WorkspacesOutlinedIcon sx={iconColor} />;
    case menus[1]:
      return <DnsOutlinedIcon sx={iconColor} />;
    case menus[2]:
      return <DatabaseIcon sx={iconColor} />;
    case menus[3]:
      return <DescriptionOutlinedIcon sx={iconColor} />;
    case menus[5]:
      return <AdminPanelSettingsOutlinedIcon sx={iconColor} />;
    default:
      return <WorkspacesOutlinedIcon sx={iconColor} />;
  }
};

const paths = {
  [menus[0]]: "/workspaces",
  [menus[1]]: "/warehouses",
  [menus[2]]: "/database",
  [menus[3]]: "/worksheets",
  [menus[5]]: "/admin",
};
interface HomepageProps {
  children: React.ReactNode;
}
interface MiniDrawerProps {
  children: React.ReactNode;
}

export const Homepage: React.FC<HomepageProps> = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  // const [header, setHeader] = useState(menus[0]);
  const navigate = useNavigate();
  // const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const loginAuth = useAppSelector(loginAuthState);
  const header = loginAuth.header;
  const { workspaces } = useAppSelector(WorkspaceListState);
  const { workspace } = useAppSelector(workspaceState);

  const checkWorkspaces = async (menu: string) => {
    const path = paths[menu];
    if (
      !excludeMenus.includes(menu) &&
      (workspaces === undefined ||
        workspaces.length === 0 ||
        workspace === undefined)
    ) {
      dispatch(setHeader(menus[0]));
      navigate(paths[menus[0]], { state: { menu } });

    } else {
      if (header !== menu) {
        dispatch(setHeader(menu));
        if (menu === "Warehouses") {
          dispatch(setHeader("Data Warehouse"));
        }
      }
      navigate(paths[menu], { state: { menu } });
    }
  };
  const location = useLocation();
  const [showProfile, setShowProfile] = useState<boolean>(true);
  useEffect(() => {
    if (location.pathname.split('/').length > 1 ) {
      if ((location.pathname.split('/').includes(DISPLAY_WORKSHEET) )) {
        setShowProfile(false);
        handleDrawerClose();
      }
      else if (location.pathname.split('/').includes("database")){
        setShowProfile(false);
      } else {
        setShowProfile(true);
      }
      const menuPath = location.pathname.split('/')[1];
      const menu = menuPath.charAt(0).toUpperCase() + menuPath.slice(1);
      
      if (header !== menu) {
        dispatch(setHeader(menu));
        if (menu === "Warehouses") {
          dispatch(setHeader("Data Warehouse"));
        }
      }
      if (menu !== "Workspaces" && menu !== "Admin" && workspace === undefined) {
        dispatch(setHeader(menus[0]));
        navigate(paths[menus[0]], { state: { menu } });
      }
    }
  }, [location]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  console.log(showProfile);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const MiniDrawer: React.FC<MiniDrawerProps> = ({ children }) => {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className="drawerHeader">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                margin: 0,
                ...(open && { display: "none" }),
              }}
            >
              <Avatar sx={{ width: 32, height: 32 }}>V</Avatar>
            </IconButton>
            <Typography
              className="header"
              variant="h5"
              align="left"
              sx={{
                ...(!open && { display: "none" }),
              }}
            >
              VegaDB
            </Typography>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                ...(!open && { display: "none" }),
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menus.map((menu, index) => {
              if (menu === "-") {
                return <Divider key={"Divider" + menu + index} />;
              } else {
                return (
                  <ListItem key={menu} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => {
                        checkWorkspaces(menu);
                      }}
                      sx={{
                        height: 40,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor:
                          menu === header ? LAVENDER_VIOLET : WHITE,
                        borderRadius: open ? "8px" : "50%",
                        margin: open ? "5%" : "20%",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 0 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton>
                          <VegaIcon iconName={menu} header={header} />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText
                        primary={menu}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: menu === header ? WHITE : "#676780",
                          fontFamily: "DM Sans !important",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "18px",

                          letterSpacing: "-0.2px",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
        {showProfile ?
          <Box component="main" sx={{ flexGrow: 1, padding: "10px" }}>
            <ProfileLayout header={header} workspace={workspace} workspaces={workspaces} />
            {children}
          </Box> : <Box component="main" sx={{ flexGrow: 1, marginTop: "5px" }} >{children}</Box>}
      </Box>
    );
  };

  return <MiniDrawer children={children} />;
};

interface ProfileLayoutProps {
  header: string;
  workspace: Workspace | undefined;
  workspaces: WorkspaceDetail[] | undefined;

}

export const ProfileLayout: React.FC<ProfileLayoutProps> = ({ header, workspace, workspaces }) => {
  const dispatch = useAppDispatch();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      <Typography
        className="title title-bold text-common-styles"
        gutterBottom
      >
        {header}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {header === menus[3] &&
          (process.env.REACT_APP_ENV === "dev") && (
            <TextButton
              handleClick={() => {
                alert("view documentation is not implemented yet");
              }}
              buttonText={"View Documentation"}
              variant={"outlined"}
              fullWidth={false}
              disabled={false}
              materialIcon={<ArrowOutwardSharp />}
            />
          )}
        {!excludeMenus.includes(header) && (
          <Stack direction="row" alignContent={"center"} justifyContent={"center"} alignItems={"center"} spacing={1}>
            <div><VegaIcon iconName={menus[0]} header={""} /></div>
            <FormControl sx={{ minWidth: "150px", marginBottom: "5px" }}>
              <Select
                labelId="select-workspace"
                id="select-workspace"
                value={workspace?.workspace_id}
                size="small"
              >
                {workspaces?.map((workspace) => (
                  <MenuItem
                    key={workspace.workspace_id}
                    value={workspace.workspace_id}
                    onClick={() => {
                      console.log({ workspace });
                      dispatch(setWorkspace(workspace));
                    }}
                  >
                    {workspace.workspace_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
        <Profile />
      </Stack>
    </Stack>
  );
}