import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryObj } from "./fetchBaseQueryObj";
import { AUTH0MANAGER } from "../components/models/constants";
import { Role, User_Invite, User_Admin, InvitedUser,InviteeUser } from "../components/models";
type UsersFetchRes = {
  organizationId: string;
  name: string;
  email: string;
  userId: string;
  rolesLst: Role[];
};

type UserPostReq = {
  organization: string;
  user: User_Admin;
};

export const adminApi = createApi({
  reducerPath: "adminpagepath",
  baseQuery: fetchBaseQueryObj,
  tagTypes: ['Users','Roles'],
  endpoints: (builder) => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: `${AUTH0MANAGER}get-available-roles`,
        method: "GET",
      }),
      providesTags: ['Roles'],
      transformResponse: (response: Role[], _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getUsers: builder.query<UsersFetchRes[], string>({
      query: (organizationId) => ({
        url: `${AUTH0MANAGER}organization-members`,
        method: "GET",
      }),
      providesTags: ['Users'],
      transformResponse: (response: UsersFetchRes[], _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    createInvites: builder.mutation<void, User_Invite>({
      query: (user) => ({
        url: `${AUTH0MANAGER}createinvites`,
        method: "POST",
        body: user,
      }),
    }),
    postAcceptInvite: builder.mutation<InviteeUser,InvitedUser>({
      query:(invitedUser)=>({
        url:`${AUTH0MANAGER}signup-invitee/${invitedUser.invitation}/${invitedUser.organization_id}/${invitedUser.organization_name}`,
        method: "POST",
      }),
      transformResponse: (response: InviteeUser, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,    
    }),
    updateUser: builder.mutation<User_Admin, UserPostReq>({
      query: (user) => ({
        url: `${AUTH0MANAGER}${user.organization}/update-user`,
        method: "POST",
        body: user.user,
      }),
       invalidatesTags: ['Users','Roles'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetUsersQuery,
  useCreateInvitesMutation,
  useUpdateUserMutation,
  usePostAcceptInviteMutation
} = adminApi;
