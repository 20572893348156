import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSchemaApi } from "../../api/schemaApi";
import { RootState } from "../../app/store";

export interface GetSchemaState {
  schemas: string[];
  error: string;
  status: "idle" | "loading" | "failed";
}

const initialState: GetSchemaState = {
  schemas: [],
  error: "",
  status: "idle",
};

export const getSchemas = createAsyncThunk(
  "worksheet/getSchemas",
  async () => {
    try {
      const response = await getSchemaApi().then(
        (response) => {
          return response;
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const GetSchemaSlice = createSlice({
  name: "tableDef",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchemas.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchemas.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload !== null && action.payload !== undefined) {
          if (action.payload.status === 200) {
            state.schemas = action.payload.data.schemas;
            state.error = "";
          } else if (
            action.payload.code &&
            action.payload.code === "ERR_NETWORK"
          ) {
            state.schemas = initialState.schemas;
            state.error = action.payload.message;
          }
        }
      })
      .addCase(getSchemas.rejected, (state) => {
        state.schemas = initialState.schemas;
        state.status = "failed";
      });
  },
});

export const { reset: resetGetSchemaState } = GetSchemaSlice.actions;

export const getSchemaState = (state: RootState) => state.getSchemas;

export default GetSchemaSlice.reducer;
