import { useEffect, useState } from 'react';
import { useGetWorkspaceListQuery } from '../../api/workspaceApi_rtk';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { OrgUserName, WorkspaceDetail } from '../models';
import { loginAuthState } from '../onBoarding/LoginAuthSlice';
import { CreateWorkspaceDialog } from './CreateWorkspace';
import { WorkspaceList } from './WorkspaceList';
import { setWorkspaceList } from './WorkspaceListSlice';
import './Workspaces.scss';
import { workspaceState, setWorkspace } from './WorkspaceSlice';
import { WORKSPACES } from '../../testValues';


import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { IconOnButton } from '../buttons/IconOnButton';
import { LoadingPage } from '../../loader/LoadingPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastError } from '../Toasts';

export const Workspaces = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openCreateWorkspace, setOpenCreateWorkspace] = useState(false);
  const handleOpenCreateWorkspace = () => {
    // dispatch(resetWorkspaceState());
    setOpenCreateWorkspace(true)
  };
  const handleCloseCreateWorkspace = () => setOpenCreateWorkspace(false);

  const [openConfigureCloudProvider, setOpenConfigureCloudProvider] = useState(false);
  const [hasRefetched, setHasRefetched]=useState<boolean>(false)


  const handleOpenConfigureCloudProvider = () => {
    setOpenConfigureCloudProvider(true)
  };
  const handleCloseConfigureCloudProvider = () => setOpenConfigureCloudProvider(false);
  const loginAuth = useAppSelector(loginAuthState);
  const orgUserName: OrgUserName = {
    org_name: loginAuth.organizationName,
    vegaUserName: loginAuth.user?.sub,
    trigger: true
  }; 
  const { data:workspaceList, isSuccess,isError, isLoading, refetch } = useGetWorkspaceListQuery(
    orgUserName,
    {
      pollingInterval: 30000,
      
    }
  );

  
useEffect(() => {
  if (isError && !hasRefetched ) {
    refetch()
    .unwrap()
      .then((response) => {
        setHasRefetched(true);
        console.log("fetch succeeded:", response);
      })
      .catch((error) => {
         toastError("unable to fetch workspaces");
        console.log("fetch failed");
      });
  }
}, [isError, refetch, hasRefetched]);

  const { workspace } = useAppSelector(workspaceState);

  useEffect(() => {
    if (workspaceList !== undefined) {
      dispatch(setWorkspaceList(workspaceList));
      if (workspace === undefined) {
        dispatch(setWorkspace(workspaceList[0]));
      }
    }
    // else {
    //   dispatch(setWorkspaceList(WORKSPACES));
    //   setWorkspaceList(WORKSPACES);
    //   if (workspace === undefined) {
    //     dispatch(setWorkspace(WORKSPACES[0]));
    //   }
    // }
  // //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList]);
  useEffect(()=>{
    if(workspace !== undefined){
      const path:string = (location.state=== null) ? "/": location.state?.menu;
      switch (path){
        case 'Worksheets':
          navigate("/"+"worksheets");
          break;
        case 'Warehouses':
          navigate("/"+"warehouses");
          break;
        case 'Database':
          navigate("/"+"database");
          break;
        default: 
          break;
      }
    }
  },[workspace]);
  return (
    <div>
     {
       ( isLoading) ?
       <LoadingPage isLoading={isLoading}/>:
         <WorkspaceList handleOpen={handleOpenCreateWorkspace} refresh={refetch} />
      }
      {openCreateWorkspace
        && <CreateWorkspaceDialog open={openCreateWorkspace}
          handleClose={handleCloseCreateWorkspace} handleOpen={handleOpenConfigureCloudProvider} />}
    </div>);
}