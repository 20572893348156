import { TextField } from "@mui/material";
import {
  DataGrid,
  GridEventListener,
  GridColDef,
  GridRowsProp,
  GridRowHeightParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useState } from "react";

type DataGridTableProps = {
  data: Object[] | undefined;
  handleRowClick: GridEventListener<"rowClick">;
  mainId: string;
  skipKeys: string[];
  renderCells?: string[];
  table?: string;
  showSearch?: boolean;

};

export const DataGridTable: React.FC<DataGridTableProps> = ({
  data,
  handleRowClick,
  mainId,
  skipKeys,
  renderCells,
  table,
  showSearch,
}) => {
  const rows: GridRowsProp = data !== undefined ? data : ([] as Object[]);

  const format = (field: string) => {
    let headerName = field.replace(/([a-z])([A-Z])/g, "$1 $2");
    headerName = headerName.replace("_", " ");
    return headerName.charAt(0).toUpperCase() + headerName.slice(1);
  };
 const [searchQuery, setSearchQuery] = useState('');
 const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
   const columns: GridColDef[] = Object.keys(data?.values().next().value)
    .filter((key) => !skipKeys.includes(key))
    .map((key) => {
      if (renderCells?.includes(key)) {
        return {
          field: key,
          headerName: format(key),
          ...(key === "options"
            ? {
              resizable: true,
              width: 125,
              minWidth: 150,
              maxWidth: 200,
              justifyContent: "flex-end",
            }
            : { flex: 1 }),
          renderCell({ row }) {
            return row[key];
          },
        };
      } else {
        return {
          field: key,
          headerName: format(key),
          flex: 1,
        };
      }
    });
    
    function CustomToolbar() {
     
            return (
              <GridToolbarContainer style={{ justifyContent: "flex-end"}}>
       
              <GridToolbarFilterButton />
              </GridToolbarContainer>
            );
          }

  return (
    
    <div style={{ height: "100%", width: "100%", padding: "1%", }}>
          { showSearch && <TextField
    sx={{  marginLeft:"auto",display:"flex",justifyContent: "flex-end", width:"17em",marginTop:"1px",marginBottom:"15px" }}
     label="Search"
     value={searchQuery}
     onChange={(e: any) => setSearchQuery(e.target.value)}
     inputProps={{ onKeyDown: (e: any) => e.stopPropagation() }}
     margin="normal"
    size="small"
     />}
      <DataGrid
        autoPageSize
        // rows={rows}
        rows={filteredRows}
        getRowId={(row) => row[mainId]}
        onRowClick={handleRowClick}
        columns={columns}
        // disableColumnFilter
          disableColumnMenu
          components={{
           Toolbar: CustomToolbar,
           }}
          
        sx={{
          borderRadius: "8px",
          minHeight: "250px",
          "&>.MuiDataGrid-main": {
            // '&>.MuiDataGrid-columnHeaders': {
            //   borderBottom: 'none',
            // },

            "& div div div div >.MuiDataGrid-cell": {
              borderBottom: "none",
            },
          },
        }}
        getRowHeight={
          table === "admin"
            ? () => "auto"
            : ({ id, densityFactor }: GridRowHeightParams) => {
              if ((id as number) % 2 === 0) {
                return 100 * densityFactor;
              }
              return null;
            }
        }
      />
    </div>
  );
};