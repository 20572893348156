import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { workspaceState } from "../Workspace/WorkspaceSlice";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { DataGridTable } from "../DataGridTable";
import { useNavigate } from "react-router-dom";
import { GridEventListener } from "@mui/x-data-grid";
import { useGetWorksheetsQuery } from "../../api/worksheetApi_rtk";
import { DISPLAY_WORKSHEET } from "../models/constants";




interface getWorksheetsProps {
  folderId: string
}

export const GetWorksheets: React.FC<getWorksheetsProps> = ({ folderId }) => {
  const { workspace } = useAppSelector(workspaceState);
  const { organizationName } = useAppSelector(loginAuthState);
  const navigate = useNavigate();

  const { data: getWorksheetsData } = useGetWorksheetsQuery({
    organizationName: organizationName,
    workspace_id: workspace?.workspace_id,
    folder_id: folderId,
  });

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log(`Worksheet Id "${params.row.worksheet_id}" was clicked`);
    navigate(`${DISPLAY_WORKSHEET}/${params.row.worksheet_id}`);
  };
  console.log(folderId)


  return (
    <Box sx={{ height: "44vh" }}>
      {getWorksheetsData !== undefined ? (
        getWorksheetsData.length > 0 ? (
          <DataGridTable
            data={getWorksheetsData}
            handleRowClick={handleRowClick}
            mainId="worksheet_id"
            skipKeys={[
              "worksheet_id",
              "folder_id",
              "workspace_id",
              "tenant_id",
              "user_id",
              "lastCatalog",
              "lastDatabase",
              "lastQueryId"
            ]}
          />
        ) : (
          <Box sx={{ height: "50px" }}>
            <Typography sx={{ backgroundColor: "lightGrey", padding: "1em" }}>
              Either the folder is empty or worksheets have not loaded yet.
            </Typography>
          </Box>
        )
      ) : (
        <div>Loading...</div>
      )}
    </Box>
  );
};
