import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { KeyboardArrowDown } from '@mui/icons-material';
import { Fragment, useState, MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loginAuthState, resetLoginAuthState } from "../onBoarding/LoginAuthSlice";
import { resetWorkspaceListState } from "../Workspace/WorkspaceListSlice";
import { resetWorkspaceState } from "../Workspace/WorkspaceSlice";

const Profile = () => {
  const { user } = useAppSelector(loginAuthState);
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 const userLogout = () => {
    dispatch(resetLoginAuthState());
    dispatch(resetWorkspaceListState());
    dispatch(resetWorkspaceState());
    logout();
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={-1}
      >
        <Avatar sx={{ width: 32, height: 32 }} alt={user?.name} src={user?.picture} />
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <KeyboardArrowDown />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >{(process.env.REACT_APP_ENV==="dev")&&<>
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>Settings</MenuItem></>}
          <MenuItem onClick={userLogout}>Logout</MenuItem>
        </Menu>
      </Stack>
    </Fragment>
  )
}

export default Profile;