import { Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { ButtonLink } from "../../ButtonLink";
import { LOG_IN } from "../models/constants";
import { BasicInformation } from "./BasicInformation";
import "./SignUp.scss";
import { useMd } from "../../mediaQuery";

export const SignUp = () => {
  const md = useMd();
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      height='100%'>
      <Typography className="header" variant="h6">VegaDB</Typography>
      <Typography className="description" variant="body1" marginBottom={md ? '5% !important' : '20% !important'}>Providing the best price/performance of any known modern data warehouse at a large scale!</Typography>
      <BasicInformation />
      <Typography sx={{ bottom: "1%" }}>Already have an account? {<ButtonLink variant="body1" underline="hover" navigateTo={() => { navigate(`/${LOG_IN}`) }} buttonText="Sign in" />}</Typography>
    </Stack >
  );
};
