import {
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  FormHelperText,
  Stack,
} from "@mui/material";
import {
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  Fragment,
} from "react";
import { TextButton } from "../buttons/TextButton";
import { DialogComponent } from "../DialogComponent";
import { Role } from "../models";
import { Theme, useTheme } from "@mui/material/styles";
import { LoadingPage } from "../../loader/LoadingPage";

type InvitePeopleProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  // personName: string;
  // setPersonName: Dispatch<SetStateAction<string>>;
  personEmail: string;
  setPersonEmail: Dispatch<SetStateAction<string>>;
  selectedRoles: string[];
  setSelectedRoles: Dispatch<SetStateAction<string[]>>;
  roles: Role[] | undefined;
  invitePeople: () => void;
  isLoading: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(role: string, selectedRoles: string[], theme: Theme) {
  return {
    fontWeight:
      selectedRoles.indexOf(role) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const InvitePeople: React.FC<InvitePeopleProps> = ({
  open,
  setOpen,
  // personName,
  // setPersonName,
  personEmail,
  setPersonEmail,
  selectedRoles,
  setSelectedRoles,
  roles,
  invitePeople,
  isLoading
}) => {
  const theme = useTheme();
  // const [personNameError, setPersonNameError] = useState(false);
  const [personEmailError, setPersonEmailError] = useState(false);
  const [selectedRolesError, setSelectedRolesError] = useState(false);

  const handleClose = () => {
    setOpen(false);
    // setPersonNameError(false);
    setPersonEmailError(false);
    setSelectedRolesError(false);
    setPersonEmail("");
    // setPersonName("");
    setSelectedRoles([]);
  };

  // const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   if (name === "person_name") {
  //     setPersonName(value);
  //     setPersonNameError(value === "");
  //   }
  // };
  const handleContinue = () =>{
    handleClose();
    invitePeople();
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "person_email") {
      setPersonEmail(value);
      const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      setPersonEmailError((value === "" || (!regex.test(value))));
    }
  };

  const onMultiSelect = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value },
    } = event;
    console.log({ value }, typeof value);
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedRolesError(value.length === 0);
  };

  const InvitePeopleDialogContent = () => {
    return (
      <FormControl component="fieldset">
        
        {/* <TextField
          autoFocus
          margin="normal"
          id="person_name"
          label="Title"
          name="person_name"
          placeholder="Ex: Marketing spends"
          value={personName}
          onChange={handleChangeName}
          type="text"
          fullWidth
          variant="outlined"
          error={personNameError}
          helperText={personNameError ? "Please enter the person name." : ""}
        /> */}


        <TextField
          autoFocus
          margin="normal"
          id="person_email"
          label="Email Id"
          name="person_email"
          placeholder="Ex: john@vegalake.com"
          value={personEmail}
          onChange={handleChangeEmail}
          type="text"
          fullWidth
          variant="outlined"
          error={personEmailError}
          helperText={personEmailError ? "Please enter a valid Email Id." : ""}
        />
        
        <FormControl>
          <InputLabel id="multiple-role-label">Role(s)</InputLabel>
          <Select
            labelId="multiple-role-label"
            id="multiple-role"
            multiple
            value={selectedRoles}
            onChange={onMultiSelect}
            input={<OutlinedInput label="Role" />}
            MenuProps={MenuProps}
            error={selectedRolesError}
            margin="dense"
          >
            {roles?.map((role) => (
              <MenuItem
                key={role.id}
                value={role.id}
                style={getStyles(role.name, selectedRoles, theme)}
              >
                {role.name}
              </MenuItem>
            ))}
          </Select>
          {selectedRolesError && <FormHelperText error>Please select at least one role.</FormHelperText>}
        </FormControl>
      </FormControl>
    );
  };

  const InvitePeopleDialogActions = () => {
    return (
      <Fragment>
        <TextButton
          handleClick={handleClose}
          buttonText={"Cancel"}
          variant={"outlined"}
          fullWidth={false}
          disabled={isLoading}
        />
        <TextButton
          handleClick={handleContinue}
          buttonText={"Continue"}
          variant={"contained"}
          fullWidth={false}
          disabled={selectedRoles.length === 0 || isLoading || personEmail===""}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <LoadingPage isLoading={isLoading} />
      <DialogComponent
        open={open}
        title="Invite People"
        handleClose={handleClose}
        dialogContent={<InvitePeopleDialogContent />}
        dialogActions={<InvitePeopleDialogActions />}
        isLoading={isLoading}
      />
    </Fragment>
  );
};
