import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryObj } from "./fetchBaseQueryObj";
import { AUTH0MANAGER } from "../components/models/constants";
import {BillingProfile, ProfileAddress, stripeCustomerModel,TenantDetails} from "../components/models/index";

export const billingApi = createApi({
    reducerPath: "stripePaymentPath",
    baseQuery: fetchBaseQueryObj,
    tagTypes: ['Updated','GetCompany'],
    endpoints: (builder) => ({
        initiateSubscription: builder.mutation<stripeCustomerModel,stripeCustomerModel>({
            query: (stripeCustomerDetails) => ({
                url: `${AUTH0MANAGER}initiate-subscription`,
                method: "POST",
                body: stripeCustomerDetails
            }),
            transformResponse: (response:stripeCustomerModel , _meta, _arg) => response,
            transformErrorResponse: (
                response: { status: string | number },
                _meta,
                _arg
            ) => response.status,
        }),
        subscribe: builder.mutation<stripeCustomerModel,stripeCustomerModel>({
            query: (stripeCustomerDetails) => ({
                url: `${AUTH0MANAGER}subscribe`,
                method: "POST",
                body: stripeCustomerDetails
            }),
            invalidatesTags: ['Updated'],
            transformResponse: (response:stripeCustomerModel , _meta, _arg) => response,
            transformErrorResponse: (
                response: { status: string | number },
                _meta,
                _arg
            ) => response.status,
        }),
        initiateSubscriptionUpdate: builder.mutation<stripeCustomerModel,stripeCustomerModel>({
            query: (stripeCustomerDetails) => ({
                url: `${AUTH0MANAGER}initiate-subscription-update`,
                method: "POST",
                body: stripeCustomerDetails
            }),
            transformResponse: (response: stripeCustomerModel, _meta, _arg) => response,
            transformErrorResponse: (
                response: { status: string | number },
                _meta,
                _arg
            ) => response.status,
        }),
        updateSubscription: builder.mutation<stripeCustomerModel,stripeCustomerModel>({
            query: (stripeCustomerDetails) => ({
                url: `${AUTH0MANAGER}update-subscription`,
                method: "POST",
                body: stripeCustomerDetails
            }),
            invalidatesTags: ['Updated'],
            transformResponse: (response:stripeCustomerModel , _meta, _arg) => response,
            transformErrorResponse: (
                response: { status: string | number },
                _meta,
                _arg
            ) => response.status,
        }),
        getPaymentInfo:builder.query<TenantDetails,void>({
            query: () => ({
                url: `${AUTH0MANAGER}get-payment-info`,
                method: "GET",
            }),
            providesTags: ['Updated'],
            transformResponse: (response:TenantDetails , _meta, _arg) => response,
            transformErrorResponse: (
                response: { status: string | number },
                _meta,
                _arg
            ) => response.status,
    }),
    addCompanyDetail:builder.mutation<ProfileAddress,ProfileAddress >({
        query: (profileAddress) => ({
            url: `${AUTH0MANAGER}add-company-details`,
            method: "POST",
            body:profileAddress,
        }),
        invalidatesTags: ['GetCompany'],
        transformResponse: (response:ProfileAddress , _meta, _arg) => response,
        transformErrorResponse: (
            response: { status: string | number },
            _meta,
            _arg
        ) => response.status,
    }),
    getCompanyDetail:builder.query<BillingProfile,void>({
        query: () => ({
            url: `${AUTH0MANAGER}get-company-details`,
            method: "GET",
        }),
        providesTags: ['GetCompany'],
        transformResponse: (response:BillingProfile , _meta, _arg) => response,
        transformErrorResponse: (
            response: { status: string | number },
            _meta,
            _arg
        ) => response.status,
}),
})
});

export const { useInitiateSubscriptionMutation,
    useInitiateSubscriptionUpdateMutation,
    useSubscribeMutation,
    useUpdateSubscriptionMutation,
    useGetPaymentInfoQuery,
    useAddCompanyDetailMutation,
    useGetCompanyDetailQuery} = billingApi;
