import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Address, stripeCustomerModel } from "../models";
import { useSubscribeMutation, useUpdateSubscriptionMutation } from "../../api/billingApi_rtk";
import { toastError, toastSuccess } from "../Toasts";
import { Box, Stack } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { TextButton } from "../buttons/TextButton";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";

type StripeElementsProps = {
    email: string;
    addressState: Address;
    customerModel: stripeCustomerModel;
    setCustomerModel: React.Dispatch<React.SetStateAction<stripeCustomerModel>>;
    setClientSecret: React.Dispatch<React.SetStateAction<string | undefined>>;
    cardHolderName: string;
    setShowComponent: React.Dispatch<React.SetStateAction<boolean>>;
    // subscribe: MutationTrigger<MutationDefinition<stripeCustomerModel, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "Post" | "Added", stripeCustomerModel, "stripePaymentPath">>
    type: string;
};

export const Elements: React.FC<StripeElementsProps> = (
    {
        email,
        addressState,
        customerModel,
        setCustomerModel,
        setClientSecret,
        cardHolderName,
        setShowComponent,
        type
    }
) => {
    const stripe = useStripe();
    const elements = useElements();
    const [firstSubscription] = useSubscribeMutation();
    const [updateSubscription] = useUpdateSubscriptionMutation();
    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet
            return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);

        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            // Card elements have not been initialized yet
            return;
        }
        if (customerModel.stripe_payment_intent_secret === undefined) {
            return
        }

        const { setupIntent } = await stripe.confirmCardSetup(customerModel.stripe_payment_intent_secret,
            {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        name: cardHolderName,
                        email: email,
                        address: {
                            line1: addressState.line1,
                            line2: (addressState.line2 == null) ? undefined : addressState.line2,
                            country: addressState.country,
                            city: addressState.city,
                            state: addressState.state,
                            postal_code: addressState.postal_code
                        }
                    }
                }
            }
        );
        const subscribe = type === "subscribe" ? firstSubscription : updateSubscription;
        if (setupIntent !== undefined) {
            await subscribe(customerModel).unwrap()
                .then((response) => {
                    setCustomerModel(response);
                    toastSuccess("subscription is successful");
                    setShowComponent(false);
                })
                .catch((error) => {
                    console.log(error);
                    toastError("Failed to subscribe");
                });
        }
        setClientSecret(undefined);
    };
    const STYLES = {
        backgroundColor: "white",
        height: "2.5rem",
        width: '7rem',
        padding: '12px 10px',
        borderRadius: "8px",
        margin: "5px 0"
    }
    const CardInterface = () => {
        return <>
            <Box>
                <Stack>
                    <Typography variant="body2">Card Number</Typography>
                    <Box
                        sx={{
                            backgroundColor: "white",
                            height: "2.5rem",
                            padding: '12px 10px',
                            borderRadius: "8px",
                            margin: "5px 0"
                        }}>
                        <CardNumberElement id="cardNumber" />
                    </Box>

                    <Typography variant="body2">Expiry Date</Typography>
                    <Box
                        sx={STYLES}>
                        <CardExpiryElement id="expiry" />
                    </Box>


                    <Typography variant="body2">CVC</Typography>
                    <Box
                        sx={STYLES}>
                        <CardCvcElement id="cvc" />
                    </Box>
                </Stack>
            </Box>
        </>;
    }


    return <>

        <Box sx={{ marginLeft: '20px' }}>

            <Typography variant="body2" sx={{ color: "#794EFF", marginBottom: '10px' }}>
                2. CREDIT CARD INFO
            </Typography>
            <CardInterface />
            <Box sx={{ position: "absolute", bottom: '20px', right: '20px', marginTop: "10px" }}>
                <TextButton
                    buttonText="Save"
                    disabled={!stripe || !elements || addressState.line1 == "" || cardHolderName == "" || email == ""}
                    fullWidth={false}
                    handleClick={handleSubmit}
                    variant={"contained"}
                />
            </Box>
        </Box>
    </>;


}