import { useEffect, useState } from "react";
import { useGetPaymentInfoQuery} from "../../api/billingApi_rtk";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { useAppSelector } from "../../app/hooks";
import { CardDetails } from "../models";
import { TabMessage, Tabs } from "../Tabs";
import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { toastError } from "../Toasts";
import { PaymentMethodsInitiationAndUpdation } from "./PaymentMethodsInitiationAndUpdation";



const STACK = {
  height: '30px',
  backgroundColor: "white",
  padding: '5px',
  borderRadius: '5px',
  margin: '12px auto',
  textAlign: 'center'
}
export const DisplayCardRegistration = () => {
  const companyDetails = useAppSelector(loginAuthState);
  const { data,refetch, isError,isLoading} = useGetPaymentInfoQuery();
  const [cardInformation, setCardInformation] = useState<CardDetails>();
  const [showComponent, setShowComponent] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);

  useEffect(() => {
    if (data === undefined ){
      refetch()
      .unwrap()
      .then((response)=>{
        console.log(response);
      })
      .catch ((error)=>{
        console.log("error:"+ error);
        toastError("error when fetching card");
      });
    }
    if(data!==undefined && data?.cardDetails!==null){
      setCardInformation(data.cardDetails);
      console.log(data)
    }
  }, [data]);
 
  

  const PaymentInfoTable = () => {
    const handleClick = () => {
      setShowComponent(!showComponent)
    };
   
    if (cardInformation !== undefined) {


      return (<>
       <Box sx={{background: "#0908320A",
        borderRadius:"10px",
        margin:"10px auto",
        padding:"15px",}}>
        <Typography variant="body1" sx={{ padding: "30px" }}>
          CARD IN CURRENT USE
        </Typography>
        <Box
          sx={{
            width: 374,
            height: 211,
            marginLeft: "30px",
            background: "linear-gradient(6.23deg, rgba(121, 78, 255, 0.47) 7.77%, #794EFF 95.63%)",
            borderRadius: "30px",
            position: "relative",
            color: "#fff",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
          }}
        >
          <Box height={20} width={20} borderRadius="50%" bgcolor="rgba(250, 250, 250, 0.5)" position="absolute" top="50px" left="50px" />

          <Box height={20} width={20} borderRadius="50%" bgcolor="rgba(250, 250, 250, 1)" position="absolute" top="50px" left="60px" />

          <Box height="130px" width="130px" borderRadius="50%" bgcolor="rgba(0, 0, 0, 0.13)" position="absolute" right="-40px" top="0" />

          <Box height="150px" width="150px" borderRadius="50%" bgcolor="rgba(0, 0, 0, 0.13)" position="absolute" bottom="-25px" right="-10px" />

          <Typography variant="caption" position="absolute" bottom="60px" left="20px" style={{
            textShadow: '2px 2px 4px rgba(0, 0, 0, 2)'
          }}>  **** **** **** {cardInformation.cardNumber}</Typography>

          <Typography variant="caption" position="absolute" bottom="40px" left="20px" style={{
            textShadow: '2px 2px 4px rgba(0, 0, 0, 2)'
          }}>   {cardInformation.cardHolderName}</Typography>

          <Typography variant="caption" position="absolute" bottom="40px" right="20px" style={{
            textShadow: '2px 2px 4px rgba(0, 0, 0, 2)'
          }}>   {cardInformation.expMonth} / {cardInformation.expYear}</Typography>
        </Box>
        {
          !showComponent &&(
            <Button
              variant="text"
              onClick={handleClick}
              sx={{
                background: "white",
                color: "#383759",
                width: "100px",
                borderRadius: "10px",
                margin: "30px"
              }}>Edit</Button>) 

         }
       </Box>
      </>
      );

    }
    return <div>no card</div>;
  }

  const ComponentToDisplay = () => {
    const [tabValue, setTabValue] = useState('credit_card');
    const tabValueChange = (event: React.SyntheticEvent, tabValue: string) => {
      setTabValue(tabValue);
    };
    const tabContent = [
      {
        label: 'CREDIT CARD',
        value: 'credit_card',
        component: (showComponent)?          
         <PaymentMethodsInitiationAndUpdation setShowComponent={setShowComponent} showComponent={showComponent} type="update" />:        
        (cardInformation === undefined || data?.cardDetails===null)? 
        <PaymentMethodsInitiationAndUpdation setShowComponent={setShowComponent} showComponent={showComponent} type="subscribe"/>:
        <PaymentInfoTable />

      },
      {
        label: ' OTHER PAYMENTS',
        value: 'other_payments',
        component: ((false)
          ? (<div />)
          : (<TabMessage message={'Yet to implement'} />)
        )
      }

    ];
    
    return <Tabs tabValue={tabValue} tabValueChange={tabValueChange} tabContents={tabContent} tabHeight='60vh' divider={false}/>;
  }
  const ButtonWithToggle = () => {

    return (
      <Box sx={{ margin: '10px auto' }}> 
        <ComponentToDisplay />
      </Box>
    );
  };

  return <>
    <div style={{ 
      margin: "10px",
      borderRadius: "10px"
    }}>
      <ButtonWithToggle />
    </div>
  </>;
}


