import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { ChangeEvent, Fragment, MouseEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TextButton } from '../buttons/TextButton';
import { DialogProps, OrgWorkspace, Workspace } from '../models';
import { toastError, toastSuccess } from '../Toasts';
import { loginAuthState } from '../onBoarding/LoginAuthSlice';
import { usePostWorkspaceMutation } from '../../api/workspaceApi_rtk';
import { setWorkspace } from './WorkspaceSlice';
import './WorkspaceDialog.scss';
import { DialogComponent } from '../DialogComponent';
import { IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';


const regions = [
  ...(process.env.REACT_APP_ENV === "dev" ? [
    {
      value: 'ap-south-1',
      label: 'Asia Pacific (Mumbai)',
    },
  ] : []),
  ...(process.env.REACT_APP_ENV === "stage" ? [
    {
      value: 'ap-northeast-1',
      label: 'Asia Pacific (Tokyo)',
    },
  ] : []),
  ...(process.env.REACT_APP_ENV === "app" ? [
    {
      value: 'us-west-2',
      label: 'US West (Oregon)',
    },
  ] : []),
  {
    value: 'dubai-central',
    label: 'Dubai Central',
  },
  {
    value: 'us-east-1',
    label: 'US East',
  },
  {
    value: 'us-west-1',
    label: 'US West',
  },
 
  {
    value: 'disabled',
    label: 'reach us at support@vegalake.com for other regions',
  },
];

const cloudProviders = [
  {
    value: 'aws',
    label: 'AWS',
  },
  {
    value: 'others',
    label: 'OTHERS',
  }
];

const pricingTiers = [
  {
    value: 'basic',
    label: 'Basic $1/credit'
  },
  {
    value: 'premium',
    label: 'Premium $2/credit'
  },
  {
    value: 'enterprise',
    label: 'Enterprise $3/credit'
  }
]
const disabledValues = ['disabled'];
const availableRegions = (process.env.REACT_APP_ENV === "dev" ? ['ap-south-1'] : ['dubai-central',...(process.env.REACT_APP_ENV === "stage" ? ['ap-northeast-1']:[]),...(process.env.REACT_APP_ENV === "app" ? ['us-west-2']:[])]);

const radioStyles = {
  flexBasis: `${96 / cloudProviders.length}%`,
  marginBottom: '11px'
};

const handleAzureButtonClick = () => {
  // Your logic when Azure button is clicked
  alert('Azure button clicked!');
};

export const CreateWorkspaceDialog: React.FC<DialogProps> = ({ open, handleClose, handleOpen }) => {
  // const { workspace, error: createWorkspaceError } = useAppSelector(createWorkspaceState);
  const dispatch = useAppDispatch();
  const loginAuth = useAppSelector(loginAuthState);

  const initialState: Workspace = {
    workspace_id: '',
    workspace_name: '',
    cloud_provider: cloudProviders[0].value,
    aws_region:  regions[0].value,
    redirectUrl: '',
    role_name: '',
    s3BucketName: '',
    stack_name: '',
    vegaUserName: loginAuth.user?.sub,
    status: "",
    tenant_id: loginAuth.organizationName,
    pricingTier: "basic"
  };
  const [workspaceDetail, setWorkspaceDetail] = useState(initialState);
  const [workspaceNameError, setWorkspaceNameError] = useState(false);
  const [regionAvailable, setRegionAvailable] = useState(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setWorkspaceDetail({ ...workspaceDetail, [name]: value });
    if (name === 'aws_region' && !availableRegions.includes(value)) {
      toastError("reach us at support@vegalake.com for this region");
      setRegionAvailable(true);
    }
    else {
      setRegionAvailable(false);
    }
    if ('workspace_name')
      setWorkspaceNameError(value === "");
  };

  const [postWorkspace, { isLoading: workspaceLoading }] = usePostWorkspaceMutation();
  const orgWorkspace: OrgWorkspace = {
    org_name: loginAuth.organizationName,
    workspace: workspaceDetail
  }

  const handleContinue = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await postWorkspace(orgWorkspace)
      .unwrap()
      .then((response) => {
        dispatch(setWorkspace(response));
        toastSuccess("Workspace has been created");
        handleClose();
        handleOpen();
      }).catch((error) => {
        console.log(error);
        toastError('unable to create workspace');
      });
  };

  const CreateWorkspaceContent = () => {
    return (
      <FormControl component='fieldset' margin='dense'>
        <FormLabel htmlFor='workspace_name'>Workspace Name</FormLabel>
        <TextField
          autoFocus
          margin='dense'
          id='workspace_name'
          name='workspace_name'
          placeholder='Ex: Singapore Office'
          value={workspaceDetail.workspace_name}
          onChange={handleChange}
          type='text'
          fullWidth
          variant='outlined'
          error={workspaceNameError}
          helperText={workspaceNameError ? "Please enter your workspace name." : ""}
        />
        <FormLabel id='cloudProvider'>Choose Cloud Provider</FormLabel>
        <RadioGroup
          row
          className='radio-group'
          aria-labelledby='cloudProvider'
          defaultValue={workspaceDetail.cloud_provider}
          onChange={handleChange}
          sx={{
            marginTop: '8px',
            marginBottom: '4px'
          }}
        >
          {cloudProviders.map((cloudProvider) => (
            <FormControlLabel
              style={radioStyles}
              className='radio-border'
              key={cloudProvider.value}
              value={cloudProvider.value}
              name='cloud_provider'
              control={<Radio disabled={cloudProvider.value === 'others'} />}
              label={
                <>
                  {cloudProvider.label}
                  {cloudProvider.value === 'others' && (
                    <IconButton
                      disabled={cloudProvider.value === 'others'}
                      onClick={handleAzureButtonClick}
                      color="warning"
                    >
                      <ErrorIcon />
                    </IconButton>
                  )}
                </>
              }
              // Add the tooltip here
              title={
                cloudProvider.value === 'others'
                  ? 'For other cloud providers, contact support@vegalake.com'
                  : ''
              }
            />
          ))}
        </RadioGroup>
        <FormLabel id='aws_region'>Choose Region</FormLabel>
        <TextField
          id='aws_region'
          select
          margin='dense'
          name='aws_region'
          placeholder={`Ex: ${regions[2].label}`}
          value={workspaceDetail.aws_region}
          onChange={handleChange}
          helperText='Please select your region'
        >
          {regions.map(region => (
            <MenuItem key={region.value} value={region.value} disabled={disabledValues.includes(region.value)}>
              {region.label}
            </MenuItem>
          ))}
        </TextField>
        <FormLabel id='pricingTier'>Choose your pricing plan</FormLabel>
        <RadioGroup
          row
          className='radio-group'
          aria-labelledby='pricingTier'
          defaultValue={workspaceDetail.pricingTier}
          onChange={handleChange}
          sx={{
            marginTop: '8px',
            marginBottom: '4px'
          }}
        >
          {pricingTiers.map((pricingTier) => (
            <FormControlLabel
              style={radioStyles}
              className='radio-border'
              key={pricingTier.value}
              value={pricingTier.value}
              name='pricingTier'
              control={<Radio />}
              label={pricingTier.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const CreateWorkspaceActions = () => {
    return (
      <Fragment>
        <TextButton
          handleClick={handleClose}
          buttonText={'Cancel'}
          variant={'outlined'}
          fullWidth={false}
          disabled={false}
        />
        <TextButton
          handleClick={handleContinue}
          buttonText={'Continue'}
          variant={'contained'}
          fullWidth={false}
          disabled={workspaceDetail.workspace_name === "" || regionAvailable}
        />
      </Fragment>
    );
  };

  return (
    <DialogComponent
      open={open}
      title="Create Workspace"
      handleClose={handleClose}
      dialogContent={<CreateWorkspaceContent />}
      dialogActions={<CreateWorkspaceActions />}
      isLoading={workspaceLoading}
    />
  );
};
