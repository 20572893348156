import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useReducer, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DatabaseIcon } from '../CustomIcons/VegaIcons';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, InputAdornment, ListItemIcon, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from '@react-spring/web';
import { IconOnButton } from "../buttons/IconOnButton";
import { getSchemas, getSchemaState } from './GetSchemaSlice';
import { getTableDefs, getTableDefState } from './GetTableDefSlice';
import { getTables, getTableState } from './GetTableSlice';
import './CustomTreeView.scss';
import { useGetCatalogsQuery, useGetDatabasesMutation, useGetSchemaMutation, useGetTablesMutation, useGetviewsMutation } from '../../api/schemaApi_rtk';
import { toastError } from '../Toasts';
import { CatalogOrDatabasesOrTablesOrViews, SchemaOfTableOrView } from '../models';

// function TransitionComponent(props: TransitionProps) {
//   const style = useSpring({
//     from: {
//       // opacity: 0,
//       // transform: 'translate3d(20px,0,0)',
//     },
//     to: {
//       // opacity: props.in ? 1 : 0,
//       // transform: `translate3d(${props.in ? 0 : 5}px,0,0)`,
//     },
//   });

//   return (
//     <animated.div style={style}>
//       <Collapse {...props} />
//     </animated.div>
//   );
// }

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    background: "white",
    paddingLeft: "8%",
    borderRightStyle: "solid ",
    borderBottomStyle: "solid ",
    borderLeftStyle: "solid ",
    borderRadius: "0 0 20px 20px",
    marginLeft: "0",
    borderColor: "#C1AEFF",
    borderWidth: "1px",
    overflowWrap: "break-word",
  },
  [`& .${treeItemClasses.content}`]: {
    flexDirection: "row-reverse",
    // overflowWrap:"break-word",
  },
  [`& .${treeItemClasses.expanded}`]: {
    background: "#C1AEFF",
    borderRadius: "20px 20px 0 0",
    overflowWrap: "break-word",
  },

  [`& .${treeItemClasses.selected}`]: {
    color: "blue",
    '& .MuiListItemIcon-root': {
      color: "blue",
    },
  },
}));
interface databases {
  [catalogId: string]: string[];
}
interface tables {
  [database: string]: string[];
}
interface views {
  [database: string]: string[];
}

interface CustomizedTreeViewProps {

  children?: React.ReactNode;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDatabase?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedTable?: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleTableSchemaClick?: (catalogId: string, databaseId: string, tableId: string) => Promise<void>;
  handleViewSchemaClick?: (catalogId: string, databaseId: string, viewId: string) => Promise<void>;
  setCatalogTable?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCatalog?: React.Dispatch<React.SetStateAction<string[]>>;
  setDatabaseName?: React.Dispatch<React.SetStateAction<string>>;

}
export const CustomizedTreeView: React.FC<CustomizedTreeViewProps> = ({ children,
  setSelectedTab,
  setSelectedDatabase,
  setSelectedTable,
  handleTableSchemaClick,
  handleViewSchemaClick,
  setCatalogTable,
  setSelectedCatalog,
  setDatabaseName, }) => {

  const [hasCatalogFetched, setCatalogFetched] = useState<boolean>(false);

  const { data: catalogsList, refetch: catalog, isError: catalogErr, isSuccess: catalogSuccess } = useGetCatalogsQuery();
  const [catalogs, setCatalogs] = useState<string[]>([]);
  const [databases, setDatabses] = useState<databases>();
  const [getDatabase, { data }] = useGetDatabasesMutation();
  const [getTables] = useGetTablesMutation();
  const [getViews] = useGetviewsMutation();
  const [getSchemas] = useGetSchemaMutation();
  useEffect(() => {
    if (catalogErr && !hasCatalogFetched) {
      catalog()
        .unwrap()
        .then((response) => {
          setCatalogFetched(true);
          // console.log("fetch succeeded:", response);
        })
        .catch((error) => {
          // console.log("error:" + error);
          toastError("unable to fetch catalogs");
        });
    }
  }, [catalogErr, catalog, hasCatalogFetched]);
  useEffect(() => {
    if (catalogsList !== undefined && catalogsList !== null && catalogSuccess) {
      console.log("catalogLists are: " + catalogsList);
      if (setCatalogTable) {
        setCatalogTable(catalogsList?.map(catalog => catalog.name));
      }
      setCatalogs(catalogsList.map(catalog => catalog.name));
    }
    if (setSelectedTab) {
      setSelectedTab("Catalog");
    }
  }, [catalogsList]);
  // const [databaseList, setDatabaseList] = useState<CatalogOrDatabasesOrTablesOrViews[]>();
  // useEffect(() => {
  //   if (catalogs !== undefined) {
  //     catalogs.map(catalog => {
  //       getDatabase(catalog)
  //       .unwrap()
  //       .then((response) => {
  //         setDatabses((previous) => {
  //           return {
  //             ...previous,
  //             [catalog]: response.map(database => database.name)
  //           }
  //         })
  //       })
  //       .catch((error) => {
  //         //
  //       });
  //     })
  //   }
  // }, [catalogs]);
  const handleCatalogClick = (catalogId: string) => {
    // if (databases?.[catalogId] === undefined) {
    if (setDatabaseName) {
      setDatabaseName("");
    }
    if(setSelectedTable){
      setSelectedTable("");
    }
    getDatabase(catalogId)
      .unwrap()
      .then((response) => {
        // console.log("fetch succeeded:", response);
        // setDatabaseList(response);
        if (response !== undefined && response !== null) {
          setDatabses((previous) => {
            return {
              ...previous,
              [catalogId]: response?.map(database => database.name)
            }
          })
        }
        if (setSelectedCatalog) {
          setSelectedCatalog(response?.map(table => table.name));
        }
      })
      .catch((error) => {
        // console.log("error:" + error);
        toastError("unable to fetch database");
      });
    // }
    if (setSelectedTab) {
      setSelectedTab("Database");
    }

  }
  const [tableFetched, setTableFetched] = useState<boolean>(false);
  const [viewFetched, setViewFetched] = useState<boolean>(false);
  const [tablesList, setTablesList] = useState<tables>();
  const [viewsList, setViewsList] = useState<views>();
  // useEffect(() => {
  //   if (catalogs !== undefined && databases !== undefined) {
  //     catalogs.map(catalog => {
  //       if (databases?.[catalog] !== undefined) {
  //         databases?.[catalog].map(database => { 
  //           handleDatabaseClick(catalog, database) 
  //         });
  //       }
  //     })
  //   }
  // }, [catalogs, databases]);
  const handleDatabaseClick = async (databaseId: string, catalogId: string) => {
    if (setSelectedTable) {
      setSelectedTable("");
    }
    if (setDatabaseName) {
      setDatabaseName(databaseId);
    }
    // if (tablesList?.[catalogId + "__" + databaseId] === undefined) {
    await getTables({ catalogId, databaseId })
      .unwrap()
      .then((response) => {
        if (response !== undefined && response !== null) {
          setTablesList((previous) => {
            return {
              ...previous,
              [catalogId + "__" + databaseId]: response?.map(table => table.name)
            }
          })

          // console.log("fetch succeeded:", response);
        }
        if (setSelectedDatabase) {
          setSelectedDatabase(response?.map(table => table.name));
        }
      })
      .catch((error) => {
        // console.log("error:" + error);
        toastError("unable to fetch tables");
      });
    if (setSelectedTab) {
      setSelectedTab("Tables");
    }
  }
  const handleViewsClick = async (databaseId: string, catalogId: string) => {

    // if (viewsList?.[catalogId + "__" + databaseId] === undefined) {
    await getViews({ catalogId, databaseId })
      .unwrap()
      .then((response) => {
        if (response !== undefined && response !== null) {
          setViewsList((previous) => {
            return {
              ...previous,
              [catalogId + "__" + databaseId]: response?.map(view => view.name)
            }
          })
          // console.log("fetch succeeded:", response);
        }
      })
      .catch((error) => {
        // console.log("error:" + error);
        toastError("unable to fetch viewss");
      });

    // }
  }
  const handleTableClick = (catalogId: string, databaseId: string, tableId: string) => {
    if (handleTableSchemaClick) {
      handleTableSchemaClick(catalogId, databaseId, tableId);
    }
  }

  const handleViewClick = (catalogId: string, databaseId: string, viewId: string) => {
    if (handleViewSchemaClick) {
      handleViewSchemaClick(catalogId, databaseId, viewId);
    }

  }


  const TreeLabel = ({ database }: { database: string }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width="100%">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" className="parent-tree-item" spacing={1} >
          <DatabaseIcon className="database-icon" /><Typography sx={{ paddingRight: "25px !important" }}>{database}</Typography>
        </Stack>
      </Stack>
    )
  }

  const [searchInput, setSearchInput] = useState('');
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([])

  const requestSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setExpanded([]);
      setSelected([]);
      if (searchInput === "") {
        setExpanded([]);
        setSelected([]);
      }
      else {
        //Catalog
        const filteredRows = catalogs?.filter((catalog) => {
          return catalog
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
        //Database search

        catalogs.map((catalogId) => {
          const matchingDatabases = databases?.[catalogId]?.filter((value) =>
            value.toLowerCase().includes(searchInput.toLowerCase())
          );
          // if(filteredRows.length===0){
          //   catalogs.map((catalogId)=>handleCatalogClick(catalogId));

          // }
          if (filteredRows && filteredRows.length > 0) {
            const catalog = filteredRows[0];
            handleCatalogClick(catalog);
            setExpanded(filteredRows);
          }
          if (matchingDatabases && matchingDatabases.length > 0) {
            
            const databaseId = matchingDatabases[0];
            handleDatabaseClick(databaseId, catalogId);
            setExpanded((previous) => [...previous, catalogId, ...matchingDatabases]);
          }
        });
        //Table search
        let matchingTables: string[]=[];
        catalogs.map((catalogId) => {
          databases?.[catalogId]?.map((database) => {
            const matchingTables = tablesList?.[catalogId + "__" + database]?.filter((table) =>
              table.toLowerCase().includes(searchInput.toLowerCase())
            );
            const matchingViews = viewsList?.[catalogId + "__" + database]?.filter((table) =>
              table.toLowerCase().includes(searchInput.toLowerCase())
            ); 
            if (matchingViews && matchingViews.length > 0) {
              if(matchingViews.length ===1){
              const viewId = matchingViews[0];
              handleViewClick(catalogId, database, viewId);
              }
              const viewIdsWithPrefix = matchingViews.map(view => `view_${view}`);

              setExpanded((previous) => [...previous, catalogId, database]);
              setSelected((previous) => [...previous, ...viewIdsWithPrefix]);
            }
            // console.log()
          
            if (matchingTables && matchingTables.length > 0) {
              if(matchingTables.length ===1){
                const tableId = matchingTables[0];
                handleTableClick(catalogId, database, tableId);
              }
              setExpanded((previous) => [...previous, catalogId, database]);
              setSelected((previous) => [...previous, ...matchingTables]);
              
            }
          });
          
        });
        
      }
    }
  };
  const MainTrees = () => {
    return (<div>
      <Box textAlign='center'>
        <IconOnButton handleClick={catalog} materialIcon={<RefreshRoundedIcon />} />
      </Box>
      {catalogs?.map((catalog) => (
        <StyledTreeItem
          key={catalog}
          sx={{ marginBottom: '5%', minWidth: "fit-content" }}
          nodeId={catalog}
          label={<TreeLabel database={catalog} />}
          onClick={() => handleCatalogClick(catalog)}
        ><></>
          {databases?.[catalog]?.map((database) => (
            <StyledTreeItem
              key={database}
              nodeId={database}
              label={<TreeLabel database={database} />}
              sx={{ margin: '10px' }}
              onClick={() => handleDatabaseClick(database, catalog)}
            >
              <Typography className="sub-header">Tables</Typography>

              {tablesList?.[catalog + "__" + database]?.map((table) => (
                <StyledTreeItem
                  key={table}
                  nodeId={table}
                  label={<Stack direction="row" alignItems="center" >
                    <ListItemIcon className="child-item-icon">
                      <BackupTableRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={table} />
                  </Stack>}
                  sx={{ margin: '10px' }}
                  onClick={() => handleTableClick(catalog, database, table)}
                />
              ))}

              <Typography className="sub-header" onClick={() => handleViewsClick(database, catalog)}>Views</Typography>
              {viewsList?.[catalog + "__" + database]?.map((view) => (
                <StyledTreeItem
                  key={`view_${view}`}
                  nodeId={`view_${view}`}
                  label={<Stack direction="row" alignItems="center">
                    <ListItemIcon className="child-item-icon">
                      <BackupTableRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={view} />
                  </Stack>}
                  sx={{ margin: '10px' }}
                  onClick={() => handleViewClick(catalog, database, view)}
                />
              ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      ))}
    </div>);
  }
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    let expandedTemp = expanded;
    expandedTemp = nodeIds;
    setExpanded(expandedTemp);
  };
  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);

  };

  return (
    <Stack direction='column' height='100%' spacing={0} marginRight='1%' padding="10px 3px 10px 10px">
      <Stack direction="row" spacing={.5} alignItems={'center'}>
        <TextField
          id="search"
          label="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={requestSearch}
        />
        <Box >{children}</Box>
      </Stack>
      <TreeView
        aria-label="customizedMain"
        defaultCollapseIcon={<KeyboardArrowUpIcon />}
        defaultExpandIcon={<KeyboardArrowDownIcon />}
        sx={{ margin: '3% 0', height: '40%', flexGrow: 1, overflowY: 'auto' }}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}

      >
        <MainTrees />
      </TreeView>
    </Stack>
  );
}