import { Box, Grid, TextField, Typography, MenuItem, fabClasses } from "@mui/material";
import { useState } from "react";
import { ProfileAddress } from "../models";
import Select from "react-select";
import { ICountry, Country, IState, ICity, State, City } from "country-state-city";
import { useAddCompanyDetailMutation, useGetCompanyDetailQuery } from "../../api/billingApi_rtk";
import { TextButton } from "../buttons/TextButton";
import { toastSuccess, toastError } from "../Toasts";
import "./BillingProfile.scss";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    outline: "none",
    boxShadow: "none",
    border: "none",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    margin: "10px -30px 10px 2rem",
    height: "40px",

  }),

};
export const BillingProfile = () => {

  const [addCompanyDetails] = useAddCompanyDetailMutation();
  const [companyName, setCompanyName] = useState<string>("");
  const [addressState, setAddressState] = useState<ProfileAddress>({
    line1: "",
    line2: "",
    city: "",
    country: "",
    state: "",
    postal_code: "",
    tax_id: "",
  });
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);
  const { data } = useGetCompanyDetailQuery();
  console.log(data);

  const countries: ICountry[] | undefined = Country.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country
  }));

  const updatedStates = (countryId: string) =>
    State
      .getStatesOfCountry(countryId)
      .map((state: IState) => ({ label: state.name, value: state.isoCode, ...state }));

  const updatedCities = (countryId: string, stateId: string) =>
    City
      .getCitiesOfState(countryId, stateId)
      .map((city: ICity) => ({ label: city.name, value: `${city.countryCode}-${city.stateCode}`, ...city }));

  const handleSubmit = async () => {
    await addCompanyDetails(addressState).unwrap()
      .then((response) => {
        toastSuccess("Company details added successfullly");
      })
      .catch((error) => {
        console.log(error);
        toastError("Failed to add details");
      });

  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "companyName") {
      setCompanyName(value);
    }
    setAddressState((prevState) => ({
      ...prevState,
      [name]: value,
      state: selectedState?.name,
      country: selectedCountry?.name,
      city: selectedCity?.name,
    }));

  };

  return (
    <Box>
      <Grid container spacing={2} xs={11.9} className="parentContainer" >
        <Grid item xs={6}>
          <Typography variant="body1" className="profileHeader">
            1. BUSINESS LEGAL ADDRESS
          </Typography>

          <Box sx={{ padding: "0 50px 0 0" }}>
            <Typography variant="subtitle2" className="label">
              COMPANY NAME
            </Typography>
            <TextField
              name="companyName"
              variant="standard"
              fullWidth
              onChange={handleChange}
              InputProps={{
                disableUnderline: true,
                sx: { borderRadius: "10px", backgroundColor: "#ffffff", margin: "10px 2rem", height: "40px", paddingLeft: "10px" },
              }}
            />
            <Typography variant="subtitle2" className="label">
              ADDRESS
            </Typography>
            <TextField name="line1" variant="standard" fullWidth onChange={handleChange}
              InputProps={{
                disableUnderline: true,
                sx: { borderRadius: "10px", backgroundColor: "#ffffff", margin: "10px 2rem", height: "40px", paddingLeft: "10px" },
              }}
            />
            <Typography variant="subtitle2" className="label">
              COUNTRY / REGION
            </Typography>
            <Select
              id="country"
              name="country"
              options={updatedCountries}
              value={selectedCountry}
              onChange={(value) => setSelectedCountry(value)}
              styles={customStyles}
              placeholder=""
            />

            <Typography variant="subtitle2" className="label">
              STATE
            </Typography>
            <Select
              id="state"
              name="state"
              options={selectedCountry ? updatedStates(selectedCountry.isoCode) : []}
              value={selectedState}
              onChange={(value) => setSelectedState(value)}
              styles={customStyles}
              menuPosition="fixed"
              placeholder=""
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className="label">
                  CITY
                </Typography>
                <Select
                  id="city"
                  name="city"
                  options={selectedState && selectedCountry ? updatedCities(selectedCountry.isoCode, selectedState.isoCode) : []}
                  value={selectedCity}
                  onChange={(value) => setSelectedCity(value)}
                  styles={customStyles}
                  menuPosition="fixed"
                  placeholder=""
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className="label">
                  POSTAL CODE
                </Typography>
                <TextField name="postal_code" variant="standard" fullWidth onChange={handleChange}
                  InputProps={{
                    disableUnderline: true,
                    sx: { borderRadius: "10px", backgroundColor: "#ffffff", margin: "10px 2rem", paddingLeft: "10px", height: "40px" },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={5.2} sx={{ position: "relative" }}>
          <Typography variant="body1" className="profileHeader">
            2. TAX REGISTRATIONS
          </Typography>
          <Box className="taxRegContainer">
            <Typography variant="subtitle2" className="label">
              VAT / GST ID
            </Typography>
            <TextField
              name="tax_id"
              variant="standard"
              fullWidth
              onChange={handleChange}
              InputProps={{
                disableUnderline: true,
                sx: { borderRadius: "10px", backgroundColor: "#ffffff", margin: "10px 2rem", paddingLeft: "10px", height: "40px" },
              }}
            />
          </Box>
          <Box className="buttonContainer">
            <TextButton
              buttonText="Save"
              disabled={addressState.line1 === "" || addressState.postal_code === "" || addressState.country === ""}
              fullWidth={false}
              handleClick={handleSubmit}
              variant={"contained"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
