import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "./fetchBaseQueryObj";
import { VERSION_API } from "../components/models/constants";
import { QueryTypeDefType } from "../components/models";

type WorksheetCreateReq = {
  organizationName: string;
  worksheet: {
    workspace_id: string | undefined;
    worksheet_name: string;
    folder_id: string | undefined;
  };
};

type WorksheetCreateRes = {
  workspace_id: string;
  worksheet_id: string;
  worksheet_name: string;
  user_id: string;
  folder_id: string;
  recently_accessed: string;
  created_by: string;
  created_time: string;
};

type FolderCreateReq = {
  organizationName: string;
  folder: {
    workspace_id: string | undefined;
    folder_name: string;
  };
};

type FolderCreateRes = {
  workspace_id: string;
  folder_id: string;
  folder_name: string;
  created_by: string;
  created_time: string;
};

type FoldersReq = {
  organizationName: string;
  workspace_id: string | undefined;
};

type FoldersRes = {
  folder_id: string;
  folder_name: string;
  created_by: string;
  created_time: string;
};

type WorksheetsReq = {
  organizationName: string;
  workspace_id: string | undefined;
  folder_id: string | undefined;
};

type WorksheetsRes = {
  worksheet_id: string;
  workspace_id: string;
  worksheet_name: string;
  tenant_id: string;
  user_id: string;
  folder_id: string;
  recently_accessed: string;
  created_by: string;
  created_time: string;
};

type WorksheetDetailRes = {
  worksheet_id: string;
  workspace_id: string;
  worksheet_name: string;
  tenant_id: string;
  user_id: string;
  folder_id: string;
  recently_accessed: string;
  created_by: string;
  created_time: string;
  sql_query: string;
  lastCatalog: string;
  lastDatabase:string;
  lastQueryId:string;
};

type WorksheetDetailReq = {
  organizationName: string;
  workspace_id: string | undefined;
  worksheet_id: string | undefined;
};

type WorksheetContentReq = {
  worksheet_id: string | undefined;
  version: string;
};

type WorksheetContentRes = {
  worksheet_id: string;
  contents: string;
  version: string;
  tenant_id: string;
};

type WorksheetUpdateReq = {
  organizationName: string;
  workspace_id: string | undefined;
  worksheet: {
    worksheet_name: string;
    worksheet_id: string | undefined;
    lastCatalog: string;
    lastDatabase:string;
    lastQueryId:string;
    folder_id:string| undefined;
  };
};

type WorksheetUpdateRes = {
  worksheet_id: string;
  worksheet_name: string;
};

type WorksheetSaveReq = {
  organizationName: string;
  worksheet: {
    worksheet_id: string | undefined;
    contents: string;
  };
};

type WorksheetSaveRes = {
  version: string;
};

type VersionsReq = {
  worksheetId: string | undefined;
};

type VersionsRes = {
  version: string;
};

type RunScriptRes = {
  mainId: string;
  data: Object[];
  message: string;
};

const WORKSHEET = "/api/v1/vegadb/worksheets/";
const WORKSHEET_FOLDERS = "/api/v1/vegadb/folders/";

export const worksheetApi = createApi({
  reducerPath: "worksheetPath",
  baseQuery: dynamicBaseQuery,
  tagTypes: ['Folders','Worksheets'],
  endpoints: (builder) => ({
    postFolder: builder.mutation<FolderCreateRes, FolderCreateReq>({
      query: (newFolder) => ({
        url: `${WORKSHEET_FOLDERS}create-folder`,
        method: "POST",
        body: newFolder.folder,
      }),
      invalidatesTags: ['Folders'],
      transformResponse: (response: FolderCreateRes, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    postWorksheet: builder.mutation<WorksheetCreateRes, WorksheetCreateReq>({
      query: (newWorksheet) => ({
        url: `${WORKSHEET}create-worksheet`,
        method: "POST",
        body: newWorksheet.worksheet,
      }),
      invalidatesTags: ['Worksheets'],
      transformResponse: (response: WorksheetCreateRes, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getFolders: builder.query<FoldersRes[], FoldersReq>({
      query: ({ organizationName, workspace_id }) => ({
        url: `${WORKSHEET_FOLDERS}fetch-folders-byuser`,
        method: "GET",
      }),
      providesTags: ['Folders'],
      transformResponse: (response: FoldersRes[], _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getWorksheets: builder.query<WorksheetsRes[], WorksheetsReq>({
      query: ({ organizationName, workspace_id, folder_id }) => ({
        url: `${WORKSHEET}fetch-worksheet-by-folder/${folder_id}`,
        method: "GET",
      }),
      providesTags: ['Worksheets'],
      transformResponse: (response: WorksheetsRes[], _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getWorksheet: builder.query<WorksheetDetailRes, WorksheetDetailReq>({
      query: ({ organizationName, workspace_id, worksheet_id }) => ({
        url: `${WORKSHEET}fetchworksheet-by-id/${worksheet_id}`,
        method: "GET",
      }),
      transformResponse: (response: WorksheetDetailRes, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getWorksheetContent: builder.mutation<WorksheetContentRes,WorksheetContentReq>({
      query: ({ worksheet_id, version }) => ({
        url: `${WORKSHEET}fetch-versioncontent/${worksheet_id}/${version}`,
        method: "GET",
      }),
      // providesTags: ['Content'],
      transformResponse: (response: WorksheetContentRes, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    updateWorksheet: builder.mutation<WorksheetUpdateRes, WorksheetUpdateReq>({
      query: ({ organizationName, workspace_id, worksheet }) => ({
        url: `${WORKSHEET}update-worksheet`,
        method: "POST",
        body: worksheet,
      }),
      // invalidatesTags: ['Worksheets'],
      transformResponse: (response: WorksheetUpdateRes, meta, arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    saveWorksheet: builder.mutation<WorksheetSaveRes[], WorksheetSaveReq>({
      query: (worksheet) => ({
        url: `${WORKSHEET}save-editor-versions`,
        method: "POST",
        body: worksheet.worksheet,
      }),
      // invalidatesTags: ['Version','Content'],
      transformResponse: (response: WorksheetSaveRes[], _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getWorksheetVersions: builder.query<VersionsRes[], VersionsReq>({
      query: ({ worksheetId }) => ({
        url: `${WORKSHEET}fetch-versions-by-worksheet/${worksheetId}`,
        method: "GET",
      }),
      // providesTags: ['Version'],
      transformResponse: (response: VersionsRes[], _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    runScript: builder.mutation<RunScriptRes, QueryTypeDefType>({
      query: (query) => ({
        url: `${WORKSHEET}runScript`,
        method: "POST",
        body: query,
      }),
      transformResponse: (response: RunScriptRes, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
  }),
});

export const {
  usePostFolderMutation,
  usePostWorksheetMutation,
  useGetFoldersQuery,
  useGetWorksheetsQuery,
  useGetWorksheetQuery,
  useGetWorksheetContentMutation,
  useUpdateWorksheetMutation,
  useSaveWorksheetMutation,
  useGetWorksheetVersionsQuery,
  useRunScriptMutation,
} = worksheetApi;
