export const SIGN_UP = "sign-up";
export const LOG_IN = "login";
export const HOMEPAGE = "homePage";
export const INVITEE_SIGN_UP ="invitee-sign-up";
export const VERIFY_PAGE="verification-mail"

//urls
export const VERSION_API = "v1/api/";
export const API_VERSION = "api/v1/";
export const AUTH0MANAGER = `${API_VERSION}global/auth/`;
export const VEGA_GLOBAL = `${API_VERSION}global/`;
export const DISPLAY_WORKSHEET='display-worksheet';

export const WHITE = "white";
export const LAVENDER_VIOLET = "#794eff";

export const BOX_STYLE = {
  width: "100%",
  height: "85vh",
  minHeight:"600px",
  background: WHITE,
  borderRadius: "18px",
  margin: "1% 0",
};
