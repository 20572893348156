import { FormControl, FormLabel, MenuItem, TextField } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState
} from "react";
import { TextButton } from "../buttons/TextButton";
import { DialogComponent } from "../DialogComponent";

type Folder = {
  folder_id: string;
  folder_name: string;
  created_by: string;
  created_time: string;
};

type CreateWorksheetProps = {
  folderId: string;
  setFolderId: Dispatch<SetStateAction<string>>;
  folders: Folder[] | undefined;
  worksheetName: string;
  setWorksheetName: Dispatch<SetStateAction<string>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  createWorksheet: () => void;
  isLoading : boolean;
};

export const CreateWorksheet: React.FC<CreateWorksheetProps> = ({
  folderId,
  setFolderId,
  folders,
  worksheetName,
  setWorksheetName,
  open,
  setOpen,
  createWorksheet,
  isLoading
}) => {
  const [worksheetNameError, setWorksheetNameError] = useState(false);

  const handleClose = () => {
    setWorksheetName("");
    setWorksheetNameError(false);
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "worksheet_name") {
      setWorksheetName(value);
      setWorksheetNameError(value === "");
    } else {
      setFolderId(value);
    }
  };

  const WorksheetDialogContent = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel id='worksheet_name'>Worksheet Name</FormLabel>
        <TextField
          autoFocus
          margin="dense"
          id="worksheet_name"
          label="Title"
          name="worksheet_name"
          placeholder="Ex: Marketing spends"
          value={worksheetName}
          onChange={handleChange}
          type="text"
          fullWidth
          variant="outlined"
          error={worksheetNameError}
          helperText={
            worksheetNameError ? "Please enter your worksheet name." : ""
          }
        />
        <FormLabel id='folder_id'>Folder</FormLabel>
        <TextField
          id="folder_id"
          select
          label="Select"
          name="folder_id"
          placeholder="Select a folder"
          value={folderId}
          onChange={handleChange}
          helperText="Please select one Folder"
          margin="dense"
        >
          {folders?.map((folder) => (
            <MenuItem key={folder.folder_id} value={folder.folder_id}>
              {folder.folder_name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };

  const WorksheetDialogActions = () => {
    return (
      <Fragment>
        <TextButton
          handleClick={handleClose}
          buttonText={"Cancel"}
          variant={"outlined"}
          fullWidth={false}
          disabled={false}
        />
        <TextButton
          handleClick={createWorksheet}
          buttonText={"Continue"}
          variant={"contained"}
          fullWidth={false}
          disabled={worksheetName === ""}
        />
      </Fragment>
    );
  };

  return (
    <DialogComponent
      open={open}
      title="Create Worksheet"
      handleClose={handleClose}
      dialogContent={<WorksheetDialogContent />}
      dialogActions={<WorksheetDialogActions />}
      isLoading={isLoading}
    />
  );
};
