import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { Box, MenuItem, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { Fragment, ReactNode, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  WORKSHEETS as worksheets_test,
  FOLDERS as folders_test,
} from "../../testValues";
import { GridEventListener } from "@mui/x-data-grid";
import {
  useGetFoldersQuery,
  usePostFolderMutation,
  usePostWorksheetMutation,
} from "../../api/worksheetApi_rtk";
import { useAppSelector } from "../../app/hooks";
import { LoadingPage } from "../../loader/LoadingPage";
import { IconOnButton } from "../buttons/IconOnButton";
import { TextButton } from "../buttons/TextButton";
import { CollapsibleTable } from "../CollapsibleTable";
import { DataGridTable } from "../DataGridTable";
import { BOX_STYLE, DISPLAY_WORKSHEET } from "../models/constants";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { workspaceState } from "../Workspace/WorkspaceSlice";
import { CreateFolder } from "./CreateFolder";
import { CreateWorksheet } from "./CreateWorksheet";
import { toastError } from "../Toasts";
import { GetWorksheets } from "./GetWorksheets";

export const FoldersWorksheetTree = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  let folderWorksheetsMap = new Map<string, Object[] | undefined>();
  const [folderWorksheetsMapState, setFolderWorksheetsMapState] =
    useState(folderWorksheetsMap);

  const getWorksheetsByFolderId = async (folder_id: string) => {
    const folderWorksheets = folderWorksheetsMapState.get(folder_id);
  }


  let temp: Object[] | undefined;

  type Worksheet = {
    worksheet_id: string;
    worksheet_name: string;
    recently_accessed: string;
    created_time: string;
    created_by: string;
    folder_id: string;
  };


  let displayContent = new Map<string, React.ReactNode>();
  const [displayContentState, setDisplayContentState] =
    useState(displayContent);

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log(`Worksheet Id "${params.row.worksheet_id}" was clicked`);
    navigate(`${DISPLAY_WORKSHEET}/${params.row.worksheet_id}`);
  };

  let isSubTreeLoaded = new Map<string, boolean>();
  const [isSubTreeLoadedState, setIsSubTreeLoaded] =
    useState<Map<string, boolean>>(isSubTreeLoaded);


  type Folder = {
    folder_id: string;
    folder_name: string;
    created_by: string;
    created_time: string;
  };

  const { workspace } = useAppSelector(workspaceState);
  const { organizationName } = useAppSelector(loginAuthState);

  const { isLoading: isGetFoldersLoading, data: folders, isError: folderErr, refetch: getFolders, isSuccess } =
    useGetFoldersQuery({
      organizationName: organizationName,
      workspace_id: workspace?.workspace_id,
    });

  const [rows, setRows] = useState<Folder[] | undefined>(folders);
  const [searched, setSearched] = useState<string>("");
  const [hasRefetched, setHasRefetched] = useState<boolean>(false);

  // const refresh = async () =>
  //   await getFolders({
  //     organizationName: organizationName,
  //     workspace_id: workspace?.workspace_id,
  //   })
  //     .unwrap()
  //     .then((response) => {
  //       console.log({ response });
  //       setRows(response);
  //       if (response.length < 1) createFolder();
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       toastError("Error, when fetching folders");
  //       // setRows(folders_test);
  //     });

  const MINUTE_MS = 60000 * (1 / 2);

  // useEffect(() => {
  //   refresh();

  //   // This will fire only on mount.
  //   // const interval = setInterval(() => {
  //   //   setTimer(prevTime => prevTime + (MINUTE_MS / 1000));
  //   // }, MINUTE_MS);

  //   // return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (folderErr && !hasRefetched) {
      getFolders()
        .unwrap()
        .then((response) => {
          setHasRefetched(true);
          // console.log("fetch succeeded:", response);
        })
        .catch((error) => {
          // console.log("error:" + error);
          toastError("unable to fetch folders");
        });
    }
  }, [folderErr, getFolders, hasRefetched]);

  useEffect(() => {

    if (folders !== undefined) {
      setRows(folders)
      console.log(folders)
      if (folders.length < 1) createFolder();

    }
  }, [folders]);
  // if(isError){
  //   console.log("error")
  //   toastError("error when fetching folders")
  // }  

  let menuOptions = new Map<string, React.ReactNode>();
  const [menuOptionsState, setMenuOptionsState] = useState(menuOptions);

  useEffect(() => {
    let displayContent = new Map<string, ReactNode>();
    let isSubTreeLoaded = new Map<string, boolean>();

    rows?.forEach((folder: Folder) => {
      const folderId = folder.folder_id.toString();
      displayContent.set(
        folderId,
        <GetWorksheets folderId={folderId} />
      );
      isSubTreeLoaded.set(
        folderId,
        displayContent.get(folderId) === undefined ? false : true
      );
    });

    setDisplayContentState(displayContent);
    setIsSubTreeLoaded(isSubTreeLoaded);
  }, [rows]);


  useEffect(() => {
    menuOptions = menuOptionsState;
    isSubTreeLoaded = isSubTreeLoadedState;
    rows?.forEach((folder: Folder) => {
      const folderId = folder.folder_id.toString();
      menuOptions.set(
        folderId,
        <MenuItem
          onClick={() =>
            alert(
              `Delete function is not implemented, Workspace id: ${folder.folder_id}`
            )
          }
        >
          Delete Folder
        </MenuItem>
      );
      isSubTreeLoaded.set(
        folderId,
        displayContent.get(folderId) === undefined ? false : true
      );
    });
    setMenuOptionsState(menuOptions);
    setIsSubTreeLoaded(isSubTreeLoaded);
  }, [rows]);

  const requestSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const filteredRows = folders?.filter((folder) => {
        return folder.folder_name
          .toLowerCase()
          .includes(searched.toLowerCase());
      });
      console.log({ filteredRows });
      setRows(filteredRows);
    }
  };

  const [folderName, setFolderName] = useState("Default");
  const [postFolder, { data: newFolder, isLoading: folderLoading }] = usePostFolderMutation();
  const createFolder = () => {
    const folder = {
      workspace_id: workspace?.workspace_id,
      folder_name: folderName,
    };

    postFolder({ organizationName, folder })
      .unwrap()
      .then((response) => {
        console.log({ response });
        setRows(
          rows?.concat({
            folder_id: response.folder_id,
            folder_name: response.folder_name,
            created_time: response.created_time,
            created_by: response.created_by,
          })
        );
      })
      .catch((error) => {
        console.log({ error, folder });
        // setRows(
        //   rows?.concat({
        //     folder_id: "11",
        //     folder_name: folderName,
        //     created_time: "Created on 24 Dec, 2022",
        //     created_by: "Yatzhee",
        //   })
        // );
        toastError('unable to create folders');
      });
    setOpenCreateFolder(false);
  };
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const boxWidth = isSmallScreen ? "800px" : "100%";

  useEffect(() => {
    console.log("Success Post Folder", { newFolder });
  }, [newFolder]);

  const [openCreateFolder, setOpenCreateFolder] = useState(false);

  const [worksheetName, setWorksheetName] = useState("");
  const [folderId, setFolderId] = useState("");
  const [
    postWorksheet,
    { isSuccess: isPostWorksheetSuccess, data: newWorksheet, isLoading: wroksheetLoading },
  ] = usePostWorksheetMutation();
  const createWorksheet = () => {
    const worksheet = {
      workspace_id: workspace?.workspace_id,
      worksheet_name: worksheetName,
      folder_id: folderId,
    };

    postWorksheet({ organizationName, worksheet })
      .unwrap()
      .then((response) => {
        console.log({ response });
      })
      .catch((error) => {
        console.log({ error });
        toastError('unable to create worksheet');
      });
    setOpenCreateWorksheet(false);
  };

  useEffect(() => {
    if (isPostWorksheetSuccess)
      console.log("Success Post Worksheet", { newWorksheet });
  }, [isPostWorksheetSuccess]);

  const [openCreateWorksheet, setOpenCreateWorksheet] = useState(false);

  const filteredFolders = rows?.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searched.toLowerCase())
    )
  );
  return (
    <Fragment>
      <LoadingPage isLoading={isGetFoldersLoading} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
        spacing={1}
        marginTop={1}
        marginBottom={2}
      >
        <IconOnButton
          handleClick={() => {
            // refresh();
            getFolders();
          }}
          materialIcon={<RefreshRoundedIcon />}
        />
        <TextButton
          handleClick={() => setOpenCreateFolder(true)}
          buttonText={"Create Folder"}
          variant={"contained"}
          fullWidth={false}
          disabled={false}
        />
        <TextButton
          handleClick={() => setOpenCreateWorksheet(true)}
          buttonText={"Create Worksheet"}
          variant={"contained"}
          fullWidth={false}
          disabled={false}
        />
      </Stack>
      <Box sx={{ ...BOX_STYLE, width: boxWidth }}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ height: "inherit" }}
        >
          <Stack
            width={"100%"}
            marginRight={5}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <TextField
              id="search_bar"
              label="Search"
              value={searched}
              onChange={(e) => setSearched(e.target.value)}
              onKeyDown={requestSearch}
              margin="normal"
              size="small"
            />
          </Stack>
          {filteredFolders !== undefined && filteredFolders?.length > 0 ? (
            <CollapsibleTable
              data={filteredFolders}
              mainId="folder_id"
              page="worksheets"
              skipKeys={[
                "folder_id",
                "workspace_id",
                "recently_accessed",
                "tenant_id",
                "user_id",
                "message",
              ]}
              displayContent={displayContentState}
              menuOptions={menuOptionsState}
              rowAction={getWorksheetsByFolderId}
              isSubTreeLoaded={isSubTreeLoadedState}
              height={"500px"}
            />
          ) : (
            "There are no folders. Wait for 1-2 minutes, if you have just created a workspace."
          )}
        </Stack>
      </Box>
      <CreateFolder
        folderName={folderName}
        setFolderName={setFolderName}
        open={openCreateFolder}
        setOpen={setOpenCreateFolder}
        createFolder={createFolder}
        isLoading={folderLoading}
      />
      <CreateWorksheet
        folderId={folderId}
        setFolderId={setFolderId}
        folders={rows}
        worksheetName={worksheetName}
        setWorksheetName={setWorksheetName}
        open={openCreateWorksheet}
        setOpen={setOpenCreateWorksheet}
        createWorksheet={createWorksheet}
        isLoading={wroksheetLoading}
      />
    </Fragment>
  );
};