import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import './WorkspaceDialog.scss';
import { useEffect, useState } from 'react';
import { toastError, toastSuccess } from '../Toasts';
import { useGetWorkspaceQuery } from '../../api/workspaceApi_rtk';
import { useLocation } from 'react-router-dom';
import { WorkspaceDetail } from './WorkspaceDetail';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { workspaceState } from './WorkspaceSlice';
import { setWorkspace } from './WorkspaceSlice';
import { Workspace } from '../models';
import './ConfigureCloudProvider.scss'

export const ConfigureCloudProvider = () => {
  const { workspace } = useAppSelector(workspaceState);
  const location = useLocation();
  const { workspace_id } = location.state;
  const capitalize = (text: string) => {
    return text !== undefined ? text.toUpperCase() : text;
  };
  const [workspaceDetail, setWorkspaceDetail] = useState<Workspace>();

  const { data: workspaces, refetch, isError } = useGetWorkspaceQuery(workspace_id);
  const [hasRefetched, setHasRefetched] = useState<boolean>(false);
  useEffect(() => {
    if (isError && !hasRefetched) {
      refetch()
        .unwrap()
        .then((response) => {
          setHasRefetched(true);
          console.log("fetch succeeded:", response);
        })
        .catch((error) => {
          toastError("unable to fetch workspace");
          console.log("fetch failed");
        });
    }
  }, [isError, refetch, hasRefetched]);

  useEffect(() => {
    if (workspaces !== undefined) {
      setWorkspaceDetail(workspace);
    }

  }, [workspaces])

  if (workspace?.s3BucketName === "" || workspace?.status === "PROVISIONING" ||
    workspace?.stack_name === "" ||
    workspace?.role_name === ""
  ) {
    return (
      <>
        <Box className="display-container">
          <Stack>
            <Typography gutterBottom className="list-item">
              Workspace Name: <b className="inner-text">{capitalize(workspaceDetail?.workspace_name ?? "Workspace Name")}</b>
            </Typography>
            <Typography variant="body1" gutterBottom className="list-item">
              Workspace Id: <b className="inner-text">{capitalize(workspaceDetail?.workspace_id ?? "Workspace Name")}</b>
            </Typography>
            <Typography variant="body1" gutterBottom className="list-item">
              Cloud Provider: <b className="inner-text">{capitalize(workspaceDetail?.cloud_provider ?? "Cloud Provider")}</b>
            </Typography>
            <Typography variant="body1" gutterBottom className="list-item">
              Status: <b className="inner-text">{capitalize(workspace?.status ?? "Status")}</b>
            </Typography>
            <Typography variant="body1" gutterBottom className="list-item">
              Region: <b className="inner-text">{capitalize(workspaceDetail?.aws_region ?? "Region")}</b>
            </Typography>
          </Stack>
        </Box>

        <Box className="cloud-configure-container" >
          <Stack>
            <Typography variant="h6" gutterBottom>
              Configure {capitalize(workspaceDetail?.cloud_provider ?? "Cloud Provider")}
            </Typography>
            <Divider />
            <Typography variant="body1" gutterBottom sx={{ marginTop: '20px' }}>
              <b>You need to sign in to {capitalize(workspaceDetail?.cloud_provider ?? "Cloud Provider")} Console to configure your account.</b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              After you sign in, we will pre-populate a <b>CloudFormation template</b> that creates an <b>IAM role</b> and <b>S3 bucket</b> for you and deploys your workspace. If you encounter any issues, check out our documentation for assistance.
            </Typography>
            <Button
              className="button-01"
              href={workspaceDetail?.redirectUrl ?? ""}
              target="_blank"
              variant="contained"
            >
              <Typography
                className='button-text-01 text-common-styles'
                variant='button'
                display='flex'
                gutterBottom
              >
                Open {capitalize(workspaceDetail?.cloud_provider ?? "Cloud Provider")} to Configure
              </Typography>
              <ArrowOutwardSharpIcon style={{ fontSize: '20px' }} />
            </Button>
          </Stack>
        </Box>
        <Stack sx={{ margin: "0 20px", textAlign: 'center' }}>
          <Typography variant="caption" >
            Once you’ve configured AWS, Warehouse setup will take around (5-10 mins) to complete. If you
            encounter any issues while configuring AWS, check out our <b onClick={() => { alert('Need to implement redirect to documentation page') }}><u>documentation</u></b> for assistance. Once the
            warehouse setup is completed, you can start creating a Data Warehouse.
          </Typography>
        </Stack>

      </>
    );
  } else {
    return <WorkspaceDetail workspaceDetail={workspaceDetail} />;
  }
};
