import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import { usePostCreateAccountMutation } from "../../api/signUpApi_rtk";
import { TextButton } from "../buttons/TextButton";
import { BasicInfo, User } from "../models";
import { toastError, toastSuccess } from '../Toasts';
import { Password } from "./Password";
import { resetLoginAuthState } from "./LoginAuthSlice";
import { useAppDispatch } from "../../app/hooks";
import { useMd } from "../../mediaQuery";
import { useNavigate } from "react-router-dom";

export const BasicInformation = () => {
  const md = useMd();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetLoginAuthState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [postCreateAccount, result] = usePostCreateAccountMutation();
  const [password, setPassword] = useState(String);
  const [isDisabled, setIsDisabled] = useState(true);

  const [values, setValues] = useState<BasicInfo>({
    name: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  const regex = {
    name: /^[\p{L}\p{M}\p{S}\p{Z}]*$/u,
    email:
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  };

  const handleChange = (name: keyof BasicInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValues({ ...values, [name]: value });
    const isInputValid = regex[name as keyof typeof regex].test(value) && (value !== '');
    setErrors({ ...errors, [name]: !isInputValid });
  };

  const signUpUser = async () => {
    const user: User = {
      name: values.name,
      email: values.email,
      password: password
    }
    await postCreateAccount(user)
      .unwrap()
      .then((response) => {
        console.log({ response });
        const { tenantId } = response;
        const{email}=response;
        navigate(`/verification-mail`, { state: { email, tenantId } });
       
      }).catch((error) => {
        console.log({ error })
        toastError("Internal Error");
      });
  }

  return (
    <Box
      autoComplete="off"
      component="form"
      width='100%'
      noValidate
    >
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
        sx={{
          height: '100%',
          width: '100%'
        }}
      >
        <Typography className="title" variant="body2"><b>Sign up to VegaDB</b></Typography>
        <Stack component={Paper}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            margin: '0.6% 0.6%',
            padding: md ? '1.3125% 1.3125%' : '3% 3%',
            height: '100%',
            width: md ? '33.5%' : '90%',
            boxShadow: '0px 4px 28px rgba(9, 8, 50, 0.04)',
            borderRadius: '18px',
          }}
        >
          <TextField
            id="name"
            label="Your Full Name"
            value={values.name}
            onChange={handleChange('name')}
            margin="normal"
            error={errors.name}
            helperText={errors.name ? "Please enter your full name using only letters." : ""}
            fullWidth
            data-testid="name-field"
          />
          <TextField
            id="email"
            label="Your Work Email"
            value={values.email}
            onChange={handleChange('email')}
            margin="normal"
            error={errors.email}
            helperText={errors.email ? "Please enter a valid email address in the format 'name@domain.com'." : ""}
            fullWidth
            data-testid="test-field"
          />
          <Password password={password} setPassword={setPassword} setIsDisabled={setIsDisabled} />
          <TextButton
            handleClick={signUpUser}
            buttonText="Create Account"
            variant="contained"
            fullWidth={true}
            disabled={errors.name || errors.email || (values.name === '') || (values.email === '') || isDisabled || result.isLoading}
          />
          <Box sx={{ width: '100%' }}>
            {result.isLoading && <LinearProgress />}
          </Box>
        </Stack>
      </Stack>
    </Box >
  )
}