import { useState } from "react";
import { SQL_HISTORIES } from "../../testValues";
import { DataGridTable } from "../DataGridTable";
import { TabMessage, Tabs } from "../Tabs";

export const LogsTable = () => {
  const [tabValue, setTabValue] = useState('sql_history');

  const tabValueChange = (event: React.SyntheticEvent, tabValue: string) => {
    setTabValue(tabValue);
  };

  const tabContent = [
    {
      label: 'SQL History',
      value: 'sql_history',
      component: ((SQL_HISTORIES !== undefined && SQL_HISTORIES.length > 0)
        ? (<DataGridTable
          data={SQL_HISTORIES}
          handleRowClick={() => { }}
          mainId="history_id"
          skipKeys={['history_id']}
        />)
        : (<TabMessage message={"There are no sql histories"} />)
      )
    },
    {
      label: 'General Logs',
      value: 'general_logs',
      component: ((SQL_HISTORIES !== undefined && SQL_HISTORIES.length > 0)
        ? (<DataGridTable
          data={SQL_HISTORIES}
          handleRowClick={() => { }}
          mainId="history_id"
          skipKeys={['history_id']}
        />)
        : (<TabMessage message={'Yet to implement'} />)
      )
    }
  ];

  return (
    <Tabs tabValue={tabValue} tabValueChange={tabValueChange} tabContents={tabContent} tabHeight='70vh' divider={true} />
  )
}