import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EmptyPage } from "../EmptyPage";
import { CreateWarehouseDialog } from "./CreateWarehouse";
import { useGetWarehouseListQuery } from "../../api/warehouseApi_rtk";
import { WAREHOUSES } from "../../testValues";
import { DataWarehouseModel } from "../models";
import { WarehouseList } from "./WarehouseList";
import { warehouseState, setWarehouses } from "./WarehouseSlice";
import { WorkspaceListState } from "../Workspace/WorkspaceListSlice";
import { toastError } from "../Toasts";
import { workspaceState } from "../Workspace/WorkspaceSlice";
import { LoadingPage } from "../../loader/LoadingPage";

export const Warehouses = () => {
  const { workspace } = useAppSelector(workspaceState);
  const { workspaces } = useAppSelector(WorkspaceListState);
  const { warehouses } = useAppSelector(warehouseState);
  const [warehouseList, setWarehouseList] =useState<DataWarehouseModel[]>(warehouses);
  const [openCreateWarehouse, setOpenCreateWarehouse] = useState(false);
  const {data:WarehousesList,isError,isLoading,isSuccess,refetch:getWarehouseList} = useGetWarehouseListQuery({
    workspace_id: workspace?.workspace_id
  },{
    pollingInterval: openCreateWarehouse? 0:3000,
  }
  );
  const dispatch = useAppDispatch();
  // const [timer, setTimer] = useState(0);

  // useEffect(() => {
  //   console.log({ timer });
  //   refresh();
  // }, [timer])
  const handleOpenCreateWarehouse = () => {
    setOpenCreateWarehouse(true);
  };
  const handleCloseCreateWarehouse = () => setOpenCreateWarehouse(false);
  const [hasRefetched, setHasRefetched]=useState<boolean>(false);
  // const fetchWarehouses = async () => {
  //   await getWarehouseList()
  //     .unwrap()
  //     .then((response) => {
  //       console.log({ response });
  //       dispatch(setWarehouses(response));
  //       setWarehouseList(response);
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       toastError('unable to fetch warehouse list');

  //       // dispatch(setWarehouses(WAREHOUSES));
  //       // setWarehouseList(WAREHOUSES);
  //     });
  // };

  // const refresh = () => fetchWarehouses();

  // const MINUTE_MS = 60000 * (1 / 2);
  useEffect(() => {
    if (isError && !hasRefetched ) {
      getWarehouseList()
      .unwrap()
        .then((response) => {
          setHasRefetched(true);
          console.log("fetch succeeded:", response);
        })
        .catch((error) => {
          console.log("error:" + error);
          toastError("unable to fetch warehouses");
        });
    }
  }, [isError, getWarehouseList, hasRefetched]);

  useEffect(() => {
    // refresh();

    // // This will fire only on mount.
    // const interval = setInterval(() => {
    //   setTimer(prevTime => prevTime + (MINUTE_MS / 1000));
    // }, MINUTE_MS);

    // return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    if(WarehousesList !== undefined) {
      dispatch(setWarehouses(WarehousesList));
      setWarehouseList(WarehousesList);
    } 
  }, [WarehousesList]);

  return (
    <div>
      {workspaces?.length === 0 ? (
        <EmptyPage
          title="You need to create a Workspace before creating a data warehouse"
          body="You can create multiple workspaces and assign relevant cloud resources."
          button="Create Workspace"
          handleOpen={() => { }}
          image="VegaLakeCloudUI_03"
          altImage="Data services and monitors"
        />
      ) : isLoading ? <LoadingPage isLoading={isLoading} /> :
      warehouseList.length === 0 ? (
        <EmptyPage
          title="Create your first Data Warehouse"
          body="You can choose from basic to large computing capacity based on the data you're going to process."
          button="Create Data Warehouse"
          handleOpen={handleOpenCreateWarehouse}
          image="Datawarehousesvg"
          altImage="Data services and monitors"
        />
      ) : (
        <WarehouseList handleOpen={handleOpenCreateWarehouse} refresh={getWarehouseList} />
      )}
      {openCreateWarehouse && (
        <CreateWarehouseDialog
          open={openCreateWarehouse}
          handleClose={handleCloseCreateWarehouse}
          handleOpen={() => { }}
        />
      )}
    </div>
  );
};
