import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { WorkspaceDetail } from "../models";
import type { PayloadAction } from "@reduxjs/toolkit";

type WorkspaceList = {
  workspaces?: WorkspaceDetail[];
};

const workspaceList: WorkspaceList = { workspaces: [] };

const WorkspaceListSlice = createSlice({
  name: "WorkspaceList",
  initialState: workspaceList,
  reducers: {
    reset: () => workspaceList,
    setState: (state, action: PayloadAction<WorkspaceDetail[]>) => {
      state.workspaces = action.payload;
    },
  },
});

export const { reset: resetWorkspaceListState, setState: setWorkspaceList } =
  WorkspaceListSlice.actions;

export const WorkspaceListState = (state: RootState) => state.workspaceList;

export default WorkspaceListSlice.reducer;
