import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTableDefApi } from "../../api/schemaApi";
import { RootState } from "../../app/store";
import { DatabaseTableType } from "../models";

interface TableDefType {
  field: string;
  type: string;
  null_indicator: string;
}

export interface GetTableDefState {
  tableDefs: TableDefType[];
  error: string;
  status: "idle" | "loading" | "failed";
}

const initialState: GetTableDefState = {
  tableDefs: [],
  error: "",
  status: "idle",
};

export const getTableDefs = createAsyncThunk(
  "worksheet/getTableDef",
  async (databaseTable: DatabaseTableType) => {
    try {
      const response = await getTableDefApi(databaseTable).then(
        (response) => {
          return response;
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const GetTableDefSlice = createSlice({
  name: "tableDef",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTableDefs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTableDefs.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload !== null && action.payload !== undefined) {
          if (action.payload.status === 200) {
            state.tableDefs = action.payload.data;
            state.error = "";
          } else if (
            action.payload.code &&
            action.payload.code === "ERR_NETWORK"
          ) {
            state.tableDefs = initialState.tableDefs;
            state.error = action.payload.message;
          } else {
            state.tableDefs = initialState.tableDefs;
          }
        }
      })
      .addCase(getTableDefs.rejected, (state) => {
        state.tableDefs = initialState.tableDefs;
        state.status = "failed";
      });
  },
});

export const { reset: resetGetTableDefState } = GetTableDefSlice.actions;

export const getTableDefState = (state: RootState) => state.getTableDefs;

export default GetTableDefSlice.reducer;
