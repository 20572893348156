import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Divider } from "@mui/material";
import { Fragment, memo } from "react";

export interface TabContent {
  label: string;
  value: string;
  component: React.ReactNode;
}

interface TabProps {
  tabValue: string;
  tabValueChange: ((event: React.SyntheticEvent, tabValue: string) => void) | (() => void);
  tabContents: TabContent[];
  tabHeight: string;
  divider?: boolean;
}

export const TabMessage = ({ message }: { message: string }) => {
  return <Box component="span" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>{message}</Box >;
}

export const Tabs: React.FC<TabProps> = memo(({ tabValue, tabValueChange, tabContents, tabHeight,divider }) => {
  const TabHeader = () => {
    return (
      <TabList onChange={tabValueChange} aria-label="lab API tabs example">
        {tabContents.map((tabContent: TabContent) => (<Tab key={tabContent.value} label={tabContent.label} value={tabContent.value} />))}
      </TabList>
    )
  }

  const TabPanels = () => {
    return (
      <Fragment>
        {tabContents.map(
          (tabContent: TabContent) => (
            <TabPanel sx={{ height: tabHeight, padding: 0 }}
              key={tabContent.value}
              value={tabContent.value}>
              {tabContent.component}
            </TabPanel>))}
      </Fragment>
    )
  }

  return (
    <TabContext value={tabValue}>
      <Box>
        <TabHeader />
      </Box>
      {divider && <Divider />}
      <TabPanels />
    </TabContext>
  );
})