import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { User } from "@auth0/auth0-spa-js";

export interface LoginAuthState {
  user: User | undefined;
  // accessToken: string | undefined;
  isAuthenticated: boolean;
  organizationName: string;
  organizationId: string;
  header: string;
}

const initialState: LoginAuthState = {
  user: undefined,
  // accessToken: "",
  isAuthenticated: false,
  organizationName: "",
  organizationId: "",
  header: "Workspaces",
};

const loginAuthSlice = createSlice({
  name: "loginAuth",
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    // setAccessToken: (state, action: PayloadAction<string | undefined>) => {
    //   state.accessToken = action.payload;
    // },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setOrganizationName: (state, action: PayloadAction<string>) => {
      state.organizationName = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      state.organizationId = action.payload;
    },
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
  },
});

export const {
  reset: resetLoginAuthState,
  setUser,
  // setAccessToken,
  setIsAuthenticated,
  setOrganizationName,
  setOrganizationId,
  setHeader,
} = loginAuthSlice.actions;

export const loginAuthState = (state: RootState) => state.loginAuth;

export default loginAuthSlice.reducer;
