import { toast } from "react-toastify";

export const toastSuccess = (successMessage: string) => {
  toast.success(successMessage, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastError = (errorMessage: string) => {
  toast.error(errorMessage, {
    theme: "colored",
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastInfo = (infoMessage: string) => {
  toast.info(infoMessage, {
    theme: "colored",
    position: toast.POSITION.TOP_CENTER,
  });
};
