import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, Stack, Switch, TextField } from "@mui/material";
import { useState, ChangeEvent, MouseEvent, useEffect } from "react";
import { DialogProps } from "../models";
import { workspaceState } from "../Workspace/WorkspaceSlice";
import { WorkspaceListState } from "../Workspace/WorkspaceListSlice";
import { useAppSelector } from "../../app/hooks";
import { useCreateWarehouseMutation } from "../../api/warehouseApi_rtk";
import { Warehouse, DataWarehouseCreateModel } from "../models";
import { TextButton } from "../buttons/TextButton";
import { toastSuccess, toastError } from "../Toasts";
import { DialogComponent } from "../DialogComponent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const autoSuspendTiming = [
  {
    timing: 1,
  },
  {
    timing: 2,
  },
  {
    timing: 5,
  },
  {
    timing: 10,
  },
  {
    timing: 15,
  },
  {
    timing: 30,
  },
  {
    timing: 60,
  },
  {
    timing: 720,
  },
  {
    timing: 1440,
  },

];

const computingPowers = [
  {
    value: "2XSMALL",
    label: "2XSMALL"
  },
  {
    value: "XSMALL",
    label: "XSMALL"
  },
  {
    value: "SMALL",
    label: "SMALL"
  },
  {
    value: "MEDIUM",
    label: "MEDIUM"
  },
  {
    value: "LARGE",
    label: "LARGE"
  },
  {
    value: "XLARGE",
    label: "XLARGE"
  },
  {
    value: "2XLARGE",
    label: "2XLARGE"
  },
  {
    value: "4XLARGE",
    label: "4XLARGE"
  },
];

export const CreateWarehouseDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
}) => {
  const { workspace } = useAppSelector(workspaceState);
  const { workspaces } = useAppSelector(WorkspaceListState);
  const [createWarehouse, { isLoading: warehouseLoading }] = useCreateWarehouseMutation();
  const initialWarehouse = {
    name: "",
    size: "2XSMALL",
    // auto_scale: false,
    // auto_scale_max_size: "",
    auto_resume: true,
    auto_stop: true,
    auto_stop_time: 10,
    // multi_cluster: false,
    // multi_cluster_min: 0,
    // multi_cluster_max: 0,
    // serverless: false,
    // spot: false
  };

  const [warehouseDetail, setWarehouseDetail] = useState(initialWarehouse as DataWarehouseCreateModel);
  const [workspaceId, setWorkspaceId] = useState(workspace?.workspace_id);
  const [warehouseNameEmpty, setWarehouseNameEmpty] = useState<boolean>(true);
  const [autoStop, setAutoStop] = useState<boolean>(true);
  const [autoResume, setAutoResume] = useState<boolean>(true);
  const autoResumption = () => {
    setAutoResume(() => {
      setWarehouseDetail({
        ...warehouseDetail,
        "auto_resume": !autoResume
      });
      return !autoResume;
    }
    );
  }
  const autoSuspenstion = () => {
    setAutoStop(() => {
      if (!autoStop) {
        setWarehouseDetail({
          ...warehouseDetail,
          "auto_stop": !autoStop,
          "auto_stop_time": 10
        })
      } else {
        setWarehouseDetail({
          ...warehouseDetail,
          "auto_stop": !autoStop,
          "auto_stop_time": 0
        })
      }
      return !autoStop;
    });
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ name, value });
    setWarehouseDetail({
      ...warehouseDetail,
      [name]: value,
    });
  };

  const postWarehouse = async () => {
    await createWarehouse(warehouseDetail)
      .unwrap()
      .then((response) => {
        console.log(response);
        toastSuccess("Warehouse has been created");
        handleClose();
      })
      .catch((error) => {
        console.log({ error });
        toastError("Failed to create a warehouse");
      });
  };

  const handleContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    postWarehouse();
  };

  const CreateWarehouseContent = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel id="workspace_id">Workspace</FormLabel>
        <TextField
          id="workspace_id"
          select
          margin="dense"
          label="Select"
          name="workspace_id"
          // placeholder="Select the Workspace"
          value={workspaceId}
        // onChange={(e) => { setWorkspaceId(e.target.value) }}
        // helperText="Please select one Workspace"
        >
          {/* {[workspace]?.map((workspace) => ( */}
          <MenuItem
            key={workspace?.workspace_id}
            value={workspace?.workspace_id}
          >
            {workspace?.workspace_name}
          </MenuItem>
          {/* ))} */}
        </TextField>
        <FormLabel id="warehouse_name">Data Warehouse name</FormLabel>
        <TextField
          autoFocus={warehouseNameEmpty}
          margin="dense"
          id="warehouse_name"
          label="Title"
          name="name"
          placeholder="Enter data warehouse name"
          value={warehouseDetail.name}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          onBlur={() => { setWarehouseNameEmpty(false); }}
          onFocus={() => { setWarehouseNameEmpty(true); }}
        />
        <FormLabel id="size">Choose Computing power</FormLabel>
        <TextField
          id="size"
          select
          margin="dense"
          name="size"
          value={(warehouseDetail.size) ? warehouseDetail.size : computingPowers[0].value}
          onChange={handleChange}
          helperText="Please select one computing power"
        >
          {computingPowers.map((computingPower) => (
            <MenuItem
              key={`cp_${computingPower.label}`}
              value={computingPower.value}
            >
              {computingPower.label}
            </MenuItem>
          ))}
        </TextField>
        {autoStop&& 
        <>
          <FormLabel id="auto_stop_time">Auto Stop Timing</FormLabel>
          <TextField
            id="auto_stop_time"
            select
            margin="dense"
            name="auto_stop_time"
            value={warehouseDetail.auto_stop_time ? warehouseDetail.auto_stop_time : '10'}
            onChange={handleChange}
            helperText="Please select one auto suspend timing"
          >
            {autoSuspendTiming.map((element, index) => {
              const value = element.timing > 60 ? element.timing / 60 : element.timing;
              const label = element.timing > 60 ? `${value} hour${value > 1 ? 's' : ''}` : `${value} min${value > 1 ? 's' : ''}`;
              return (
                <MenuItem key={`ast_${index}`} value={element.timing}>
                  {label}
                </MenuItem>
              );
            })}
          </TextField>
        </>}
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <FormControlLabel
            value={autoResume}
            name="auto_resume"
            onChange={autoResumption}
            control={
              <Switch
                checked={autoResume}
              />}
            label="Auto Resume"
          />
          <FormControlLabel
            value={autoStop}
            name="auto_stop"
            onChange={autoSuspenstion}
            control={
              <Switch
                checked={autoStop}
              />
            }
            label="Auto Stop"
          />
        </Stack>
      </FormControl>
    );
  };

  const CreateWarehouseActions = () => {
    return (
      <TextButton
        handleClick={handleContinue}
        buttonText={"Create Data Warehouse"}
        variant={"contained"}
        fullWidth={true}
        disabled={false}
      />
    );
  };

  return (
    <DialogComponent
      open={open}
      title="Create Warehouse"
      handleClose={handleClose}
      dialogContent={<CreateWarehouseContent />}
      dialogActions={<CreateWarehouseActions />}
      isLoading={warehouseLoading}
    />
  );
};
