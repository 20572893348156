import { useGetWorkspaceQuery } from "../../api/workspaceApi_rtk";
import { useLocation } from 'react-router-dom';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { toastError } from "../Toasts";
import { Workspace } from "../models";
import './WorkspaceDetail.scss'

const BOX_STYLE = {
  width: "14rem",
  minHeight: "35vh",
  padding: "10px",
  background: "white",
  borderRadius: "10px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)"
};
interface WorkspaceDetail {
  workspaceDetail: Workspace | undefined
}

export const WorkspaceDetail: React.FC<WorkspaceDetail> = ({ workspaceDetail }) => {
  const location = useLocation();
  const { workspace_id } = location.state;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box className="flex-container">
            <Stack sx={BOX_STYLE}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Region
              </Typography>
              <Divider />
              <Typography variant="body1" sx={{ margin: "25px auto" }}>
                {workspaceDetail?.aws_region ?? "Cloud Provider"}
              </Typography>

            </Stack>

            <Stack sx={BOX_STYLE}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Storage
              </Typography>
              <Divider />
              <Typography variant="body1" className="text-container">
                <b>Bucket Name:</b> <br />{workspaceDetail?.s3BucketName ?? "s3 BucketName"}
              </Typography>
            </Stack>

            <Stack sx={BOX_STYLE}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Role
              </Typography>
              <Divider />
              <Typography variant="body1" className="text-container">
                {workspaceDetail?.role_name ?? "Role"}
              </Typography>

            </Stack>

          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="box-container">
            <Stack>
              <Typography variant="body1" className="text">
                <b> Workspace Name </b> <br />
                {workspaceDetail?.workspace_name ?? "Workspace"}
              </Typography>
              <Typography variant="body1" className="text">
                <b> Workspace Id </b> <br />
                {workspaceDetail?.workspace_id ?? "Workspace Id"}
              </Typography>
              <Typography variant="body1" className="text">
                <b> Workspace Status </b> <br />
                {workspaceDetail?.status ?? "Status"}
              </Typography>
              <Typography variant="body1" className="text">
                <b> Pricing Tier </b> <br />
                {workspaceDetail?.pricingTier ?? "Pricing Tier"}
              </Typography>
              <Typography variant="body1" className="text">
                <b> Cloud Provider </b> <br />
                {workspaceDetail?.cloud_provider ?? "Cloud Provider"}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>

    </div>
  );
}