import { useState } from "react";
import { TabMessage, Tabs } from "../Tabs";
import { DisplayCardRegistration } from "./DisplayCardRegistration";
import React from "react";
import { BillingProfile } from "./BillingProfile";
export const BillingInterface = ()=>{
    const [tabValue, setTabValue] = useState('payment_methods');

  const tabValueChange = (event: React.SyntheticEvent, tabValue: string) => {
    setTabValue(tabValue);
  };

  const tabContent = [
    {
      label: 'Payment Methods',
      value: 'payment_methods',
      component: <DisplayCardRegistration />
    },
    {
      label: 'Credits',
      value: 'credits',
      component: ((false)
        ? (<div/>)
        : (<TabMessage message={'Yet to implement'} />)
      )
    },
    {
      label: 'BILLING PROFILE',
      value: 'billing_profile',
      component: <BillingProfile />
    }
  ];
    return <Tabs tabValue={tabValue} tabValueChange={tabValueChange} tabContents={tabContent} tabHeight='70vh' divider={true}/>;
}
