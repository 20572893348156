import { useAuth0 } from "@auth0/auth0-react";
import { Box, LinearProgress, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetOrgNamesByEmailMutation } from "../../api/signUpApi_rtk";
import { useAppDispatch } from '../../app/hooks';
import { ButtonLink } from "../../ButtonLink";
import { LoadingPage } from "../../loader/LoadingPage";
import { TextButton } from "../buttons/TextButton";
import { HOMEPAGE, SIGN_UP } from "../models/constants";
import { setUser, setIsAuthenticated, setOrganizationName, setOrganizationId} from "./LoginAuthSlice";
import { useMd } from "../../mediaQuery";

interface LoginProps {
  email: string;
  organization: string;
}

export const Login = () => {
  const md = useMd();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedOrgName, setSelectedOrgName] = useState<string>("");
  const [selectedOrgId, setSelectedOrgId] = useState<string>("");

  const [values, setValues] = useState<LoginProps>({
    email: '',
    organization: '',
  });

  const [errors, setErrors] = useState({
    email: false,
    organization: false,
  });
  
  const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    // getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUser(user));
      dispatch(setIsAuthenticated(isAuthenticated));
      navigate(`/workspaces`,{state:{menu:"Workspaces"}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
 
  const handleChange = (name: keyof LoginProps) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValues({ ...values, [name]: value });
    if (name === 'email') {
      const isInputValid = regex.test(value) && (value !== '');
      setErrors({ ...errors, [name]: !isInputValid });
    }
  };

  const loginUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(setOrganizationName(selectedOrgName));
    dispatch(setOrganizationId(selectedOrgId));
    localStorage.setItem('organizationId', selectedOrgId);
    loginWithRedirect({
      authorizationParams: {
        display: 'page',
        organization: values.organization,
      }
    });
  };

  const [getOrgNamesByEmail, { data: organizationsByEmail, isSuccess: getOrgsSuccess, isLoading: getOrgsLoading, isError }] = useGetOrgNamesByEmailMutation();

  useEffect(() => {
    console.log({ organizationsByEmail });
  }, [organizationsByEmail])

  useEffect(() => {
    setErrors({ ...errors, "organization": isError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && values.email !== '' && !errors.email) {
      getOrgNamesByEmail(values.email);
    }
  };


  return (
    <div>
      <LoadingPage isLoading={isLoading} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Typography className="header" variant="h6" marginBottom={md ? '5% !important' : '20% !important'}>VegaDB</Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
          noValidate
          autoComplete="off"
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <Typography className="title" variant="body2"><b>Log In to VegaDB</b></Typography>
            <Stack component={Paper}
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                margin: '0.6% 0.6%',
                padding: md ? '1.3125% 1.3125%' : '3% 3%',
                height: '45.5%',
                width: md ? '33.5%' : '90%',
                boxShadow: '0px 4px 28px rgba(9, 8, 50, 0.04)',
                borderRadius: '18px',
              }}
            >
              <TextField
                autoFocus={true}
                id="email"
                label="Your Work Email"
                value={values.email}
                onChange={handleChange('email')}
                onKeyDown={handleKeyDown}
                margin="normal"
                error={errors.email}
                helperText={errors.email ? "Please enter a valid email address in the format 'name@domain.com'."
                  : (values.email === "" ? "" : "Press enter key to load organizations")}
                fullWidth
                size="small"
              />
              <TextField
                id="outlined-select-organization"
                select
                label="Select Organization"
                defaultValue=""
                value={values.organization}
                onChange={handleChange('organization')}
                error={errors.organization}
                helperText={errors.organization ? "Unable to fetch organizations, try again" : ""}
                fullWidth
                size="small"
              >
                {(getOrgsSuccess && organizationsByEmail !== undefined) ?
                  organizationsByEmail?.map((organization) => (
                    <MenuItem key={organization.org_id} value={organization.org_id} onClick={() => {
                      setSelectedOrgName(organization.org_name);
                      setSelectedOrgId(organization.org_id);
                    }} >
                      {organization.org_name}
                    </MenuItem>
                  ))
                  :
                  <MenuItem>Select the above field and press enter key to load organizations</MenuItem>
                  // <MenuItem key={"org_cJiLS060vLrHSyky"} value={"org_cJiLS060vLrHSyky"} onClick={() => {
                  //   setSelectedOrgName("7481236857");
                  //   setSelectedOrgId("org_cJiLS060vLrHSyky");
                  // }} >
                  //   {"7481236857"}
                  // </MenuItem>
                }
              </TextField>
              <Box sx={{ width: '100%' }}>
                {getOrgsLoading && <LinearProgress />}
              </Box>
              <TextButton
                handleClick={loginUser}
                buttonText="Log In"
                variant="contained"
                fullWidth={true}
                disabled={errors.organization || errors.email || (values.organization === '') || (values.email === '') || isLoading}
              />
            </Stack>
          </Stack>
        </Box >
        <Typography sx={{ bottom: "1%" }}>Don’t have an account? {<ButtonLink variant="body1" underline="hover" navigateTo={() => { navigate(`/${SIGN_UP}/`) }} buttonText="Create Account" />}</Typography>
      </Stack>
    </div>
  );
}