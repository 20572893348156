import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { DataWarehouseModel } from "../models";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setWarehouses, warehouseState } from "./WarehouseSlice";
import { Stack, Box, useMediaQuery, LinearProgress } from "@mui/material";
import { TextButton } from "../buttons/TextButton";
import { IconOnButton } from "../buttons/IconOnButton";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { BOX_STYLE, LAVENDER_VIOLET } from "../models/constants";
import {
  useStartWarehouseMutation,
  useStopWarehouseMutation,
} from "../../api/warehouseApi_rtk";
import { toastError, toastSuccess } from "../Toasts";

interface WorkspaceListGrid {
  handleOpen: () => void;
  refresh: () => void;
}

interface ColumnData {
  dataKey: keyof DataWarehouseModel;
  label: string;
  numeric?: boolean;
  align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 200,
    label: "Warehouse",
    dataKey: "name",
    align: "left",
  },
  {
    width: 120,
    label: "Auto Suspend",
    dataKey: "auto_stop_time",
    numeric: true,
    align: "right",
  },
  {
    width: 120,
    label: "Created On",
    dataKey: "created_at",
    align: "left",
  },
  {
    width: 120,
    label: "Action",
    dataKey: "status",
    align: "right",
  },
];

const statuses = ["RUNNING", "STOPPED", "UPDATING", "STOPPING", "STARTING"];
// const statuses = ["STARTING","RUNNING","UPDATING","STOPPING","STOPPED"];

const statusColor = (status: string | number | boolean) => {
  switch (status) {
    case statuses[0]:
      return "#00943B";
    case statuses[1]:
      return "#DF6B00";
    default:
      return "#090832";
  }
};

const StatusIcon = (status: string | number | boolean) => {
  switch (status) {
    case statuses[0]:
      return <PauseCircleFilledIcon sx={{ color: "#DF6B00" }} />;
    case statuses[1]:
      return <PlayCircleFilledIcon sx={{ color: LAVENDER_VIOLET }} />;
    default:
      return null;
  }
};

export const WarehouseList: React.FC<WorkspaceListGrid> = ({
  handleOpen,
  refresh,
}) => {
  const { warehouses } = useAppSelector(warehouseState);
  const dispatch  = useAppDispatch();
  const [startWarehouse, { isLoading: startLoading }] = useStartWarehouseMutation();
  const [stopWarehouse, { isLoading: stopLoading }] = useStopWarehouseMutation();
  const updatedWarehouses =(warehouse_id:string,newStatus:string)=>{ return warehouses.map((warehouse) => {
    if (warehouse.id === warehouse_id) {
      return {
        ...warehouse,
        status:newStatus,
      };
    }
    return warehouse;
  });
}
  const startStopWarehouse = (
    status: string | number | boolean,
    warehouse_id: string
  ) => {
    
    switch (status) {
      case statuses[0]:
        dispatch(setWarehouses(updatedWarehouses(warehouse_id,"STOPPING...")));
        stop(warehouse_id);
        break;
      case statuses[1]:
        dispatch(setWarehouses(updatedWarehouses(warehouse_id,"STARTING...")));
        start(warehouse_id);
        break;
    }
  };

  const start = async (warehouse_id: string) => {
    await startWarehouse(warehouse_id)
      .unwrap()
      .then(() => {
        // toastSuccess("warehouse is started, successfully");
        console.log(warehouse_id, "warehouse is started");
      })
      .catch((error) => {
        toastError("Faced an error while starting the warehouse");
      });
  };

  const stop = async (warehouse_id: string) => {
    await stopWarehouse(warehouse_id)
      .unwrap()
      .then(() => {
        // toastSuccess("warehouse is stopped, successfully");
        console.log(warehouse_id, "warehouse is started");
      })
      .catch((error) => {
        toastError("Faced an error while stopping the warehouse");
      });
  };

  const VirtuosoTableComponents: TableComponents<DataWarehouseModel> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} style={{ borderCollapse: "separate" }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.align}
            // style={{ width: column.width }}
            sx={{
              backgroundColor: "white",
              color: "#9796A7",
            }}
          >
            {column.label.toUpperCase()}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index: number, dataWarehouse: DataWarehouseModel) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {/* {dataWarehouse[column.dataKey]} */}
            {getCellContent(column.label, column.dataKey, dataWarehouse)}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const getCellContent = (
    columnName: string,
    columnKey: keyof DataWarehouseModel,
    dataWarehouse: DataWarehouseModel
  ) => {
    switch (columnName) {
      case "Warehouse":
        return (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <DnsOutlinedIcon sx={{ color: "#676780" }} />
            <b>{dataWarehouse[columnKey]}</b> ({dataWarehouse["size"]})
          </Stack>
        );

      case "Created On":
        const createdOnString = dataWarehouse[columnKey];

        if (!createdOnString || typeof createdOnString !== 'string') {
          return "N/A";
        }
        const createdOn = new Date(createdOnString);
        if (isNaN(createdOn.getTime())) {
          return "Invalid Date";
        }
        const formattedCreatedOn = createdOn.toISOString().split('.')[0];
        return formattedCreatedOn;

      case "Action":
        return (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <b style={{ color: statusColor(dataWarehouse[columnKey]) }}>
              {dataWarehouse[columnKey]}
            </b>
            <IconOnButton
              handleClick={() =>
                startStopWarehouse(dataWarehouse["status"], dataWarehouse["id"])
              }
              materialIcon={StatusIcon(dataWarehouse[columnKey])}
            />
            {/* <IconOnButton
              handleClick={() => alert(dataWarehouse[columnKey])}
              materialIcon={<MoreHorizIcon sx={{ color: "#9796A7" }} />}
            /> */}
          </Stack>);
      default:
        return dataWarehouse[columnKey];
    }
  };
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");

  const boxWidth = isSmallScreen ? "900px" : "100%";
  return (
    <Fragment>
      <Box>
        <Stack
          margin={1}
          direction="row"
          justifyContent="flex-end"
          alignItems="stretch"
          spacing={1}
        >
          <IconOnButton
            handleClick={refresh}
            materialIcon={<RefreshRoundedIcon />}
          />
          <TextButton
            handleClick={handleOpen}
            buttonText={"Create Data Warehouse"}
            variant={"contained"}
            fullWidth={false}
            disabled={false}
          />
        </Stack>
      </Box>
      <Box sx={{ ...BOX_STYLE, width: boxWidth }}>
        {/* {(
          stopLoading|| startLoading
        ) ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" , marginTop:"50px"}}>
            <Box sx={{ alignItems: "center" }}><CircularProgress value={10} size={200} /> </Box> 
          </div>
          : */}
          <TableVirtuoso
            data={warehouses}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
      </Box>
    </Fragment>
  );
};
