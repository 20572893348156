import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import "./SignUp.scss";
import { useMd } from "../../mediaQuery";
import { useLocation } from "react-router-dom";
import { loginAuthState, resetLoginAuthState } from "./LoginAuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { useEffect } from "react";
import { toastError, toastSuccess } from "../Toasts";
import { SIGN_UP, LOG_IN, HOMEPAGE } from "../models/constants";
import { TextButton } from "../buttons/TextButton";
import { usePostAcceptInviteMutation } from "../../api/adminApi_rtk";
import { InvitedUser } from "../models";
import { EmptyPage } from "../EmptyPage";
import { resetWorkspaceListState } from "../Workspace/WorkspaceListSlice";
import { resetWorkspaceState } from "../Workspace/WorkspaceSlice";
export const InviteeSignUp = () => {
  const md = useMd();
  // const { isAuthenticated } = useAppSelector(loginAuthState);
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   const checkISAuthenticated = () => {
  //     if (!isAuthenticated) {
  //       toastError("Please login!");
  //       dispatch(resetLoginAuthState());
  //       navigate(`/${SIGN_UP}`);
  //     }
  //   };
  //   checkISAuthenticated();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuthenticated]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const invitation = params.get("invitation");
  const organization = params.get("organization");
  const organizationName = params.get("organization_name");
  const { logout } = useAuth0();
  const [postAcceptInvite, result] = usePostAcceptInviteMutation();
  const acceptInvitationClick = async () => {
    const user: InvitedUser = {
      invitation: invitation === null ? undefined : invitation,
      organization_id: organization === null ? undefined : organization,
      organization_name: organizationName === null ? undefined : organizationName
    }
    await postAcceptInvite(user)
      .unwrap()
      .then((response) => {
        console.log({ response });
        toastSuccess("Thanks for signing up. Please verify your email to continue.");
        dispatch(resetLoginAuthState());
        dispatch(resetWorkspaceListState());
        dispatch(resetWorkspaceState());
        logout();
        navigate(`/${LOG_IN}`);
      }).catch((error) => {
        console.log({ error })
        toastError("Your request is denied");
        navigate(`/workspaces`);
      });
  }
  return (
    <div>
      <Typography className="header" variant="h6">VegaDB</Typography>
      <Typography className="description" variant="body1">Providing the best price/performance of any known modern data warehouse at a large scale!</Typography>
      {/* <Typography className="header" variant="h6" color="black">
        Accept Your Invitation
      </Typography> */}
      {/* <Box
        sx={{
          width: 400,
          height: 250,
          margin: '0 auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      > */}
      {/* <Stack> */}
      {/* <Typography className="header" variant="body1" color="blue">
            You are invited to join the organization -  {organizationName}
          </Typography>
          <EmptyPage 
          image="Vegalake"
          />
          <TextButton
            handleClick={acceptInvitationClick}
            buttonText="Accept"
            variant="contained"
            fullWidth={false}
            disabled={false}
          /> */}
      <EmptyPage
        title='Accept Your Invitation'
        body={' You are invited to join the organization -  ' + organizationName}
        button='Accept'
        handleOpen={acceptInvitationClick}
        image='VegaLakeCloudUI_03'
        altImage='Data services and monitors'
      />
      {/* </Stack> */}
      {/* </Box> */}
    </div>
  );
};
