import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";
// import sessionStorage from "redux-persist/es/storage/session";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";

import getSchemas from "../components/schema/GetSchemaSlice";
import getTables from "../components/schema/GetTableSlice";
import getTableDefs from "../components/schema/GetTableDefSlice";
import { signUpUserApi, loginUserApi } from "../api/signUpApi_rtk";
import loginAuth from "../components/onBoarding/LoginAuthSlice";
import { workspaceApi } from "../api/workspaceApi_rtk";
import workspace from "../components/Workspace/WorkspaceSlice";
import workspaceList from "../components/Workspace/WorkspaceListSlice";
import { warehouseRegionalApi } from "../api/warehouseApi_rtk";
import warehouse from "../components/Warehouse/WarehouseSlice";
import { worksheetApi } from "../api/worksheetApi_rtk";
import { adminApi } from "../api/adminApi_rtk";
import { billingApi } from "../api/billingApi_rtk";
import { schemaApi } from "../api/schemaApi_rtk";
import { interactiveQueryApi } from "../api/interactiveQueryApi_rtk";

const persistLoginAuthConfig = {
  key: "root",
  storage: storage,
};
const persistWorkspaceConfig = {
  key: "workspace",
  storage: storage,
};
const persistWorkspaceListConfig = {
  key: "worksapcelist",
  storage: storage,
};

const persistedLoginReducer = persistReducer(persistLoginAuthConfig, loginAuth);
const persistedWorkspaceReducer = persistReducer(persistWorkspaceConfig, workspace);
const persistedWorkspaceListReducer = persistReducer(
  persistWorkspaceListConfig,
  workspaceList
);

const rootReducers = combineReducers({
  [signUpUserApi.reducerPath]: signUpUserApi.reducer,
  [loginUserApi.reducerPath]: loginUserApi.reducer,
  loginAuth: persistedLoginReducer,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
  workspace:persistedWorkspaceReducer,
  workspaceList:persistedWorkspaceListReducer,
  // [warehouseApi.reducerPath]: warehouseApi.reducer,
  [warehouseRegionalApi.reducerPath]: warehouseRegionalApi.reducer,
  [adminApi.reducerPath]:adminApi.reducer,
  [billingApi.reducerPath]:billingApi.reducer,
  warehouse,
  [worksheetApi.reducerPath]: worksheetApi.reducer,
  [schemaApi.reducerPath]: schemaApi.reducer,
  getSchemas,
  getTables,
  getTableDefs,
  [schemaApi.reducerPath]: schemaApi.reducer,
  [interactiveQueryApi.reducerPath]:interactiveQueryApi.reducer
});

// const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: rootReducers,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(signUpUserApi.middleware)
      .concat(loginUserApi.middleware)
      .concat(workspaceApi.middleware)
      // .concat(warehouseApi.middleware)
      .concat(warehouseRegionalApi.middleware)
      .concat(worksheetApi.middleware)
      .concat(adminApi.middleware)
      .concat(billingApi.middleware)
      .concat(schemaApi.middleware)
      .concat(interactiveQueryApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export const persistor = persistStore(store);
export type AppStore = typeof store;
