import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SplitPane, { Pane } from "split-pane-react";
import { CustomizedTreeView } from "../schema/CustomTreeView";
import { KeyboardArrowLeft } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import './Database.scss'
import Profile from "../Profile";
import { VirtuosoTable } from "../VirtuosoTable";
import { DockTopIcon, DockRightIcon, DockBottomIcon } from "../CustomIcons/VegaIcons";
import { toastError } from "../Toasts";
import { useGetCatalogsQuery, useGetSchemaMutation, useGetViewSchemaMutation } from "../../api/schemaApi_rtk";
import { SchemaOfTableOrView } from "../models";
import { TabContent, TabMessage, Tabs } from "../Tabs";


interface schemas {
  [table: string]: SchemaOfTableOrView[];
}
export const Database = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const leftFullScreen = ["100%", "0%"];
  const defaultLayout = ['40%', 'auto'];
  const [sizes, setSizes] = useState<(string | number)[]>(defaultLayout);
  const rightArrowColor = sizes[0] === "100%" ? "blue" : "#676780";
  const bottomArrowColor = sizes[0] === "0%" ? "blue" : "#676780";

  const setNewSizes = (sizes: number[]) => {
    setSizes(sizes);
    if (sizes[0] !== 0) {
      setIsSidebarOpen(true);
    }
    if (sizes[0] === 0) {
      setIsSidebarOpen(false);
    }

  }

  const renderSash = (index: number, active: boolean) => {
    return <div className={`custom-sash ${active ? "active" : ""}`} />;
  };
  const rightFullScreen = ["0%", "80%"];
  const toggleSidebar = () => {
    console.log(sizes);
    if (sizes[0] === "0%" || sizes[0] === "100%") {
      setIsSidebarOpen(() => {
        setSizes(defaultLayout);
        return true;
      });
    } else {
      setIsSidebarOpen(() => {
        setSizes(isSidebarOpen ? rightFullScreen : defaultLayout);
        return !isSidebarOpen;
      });
    }
  };

  const setSidebarToFullScreen = () => {
    if (sizes[0] === "100%") {
      setSizes(defaultLayout);
    } else {
      setSizes(leftFullScreen);
    }
  }
  const [selectedTab, setSelectedTab] = useState<string>("Tables");
  const [selectedDatabase, setSelectedDatabase] = useState<string[]>([]);
  const [selectedTable, setSelectedTable] = useState<string>();
  const [databaseName, setDatabaseName] = useState<string>("");
  const [catalogTable, setCatalogTable] = useState<string[]>([]);
  const [selectedCatalog, setSelectedCatalog] = useState<string[]>([]);
  const [getSchemas] = useGetSchemaMutation();
  const [getViewsSchemas] = useGetViewSchemaMutation();

  const [schema, setSchema] = useState<schemas>();
  const [viewSchema, setViewSchema] = useState<schemas>();
  const [tableOrViewSchema, setTableOrViewSchema] = useState<SchemaOfTableOrView[]>([]);

  const handleTableClick = async (catalogId: string, databaseId: string, tableId: string) => {
    setDatabaseName(databaseId);
    setSelectedTable(tableId);
    // if (schema?.[catalogId + "__" + databaseId + "__" + tableId] === undefined) {

    await getSchemas({ catalogId, databaseId, tableId })
      .unwrap()
      .then((response) => {
        if (response !== undefined && response !== null) {
          setSchema((previous) => {
            return {
              ...previous,
              [catalogId + "__" + databaseId + "__" + tableId]: response?.map(schema => ({
                name: schema.name,
                type: schema.type
              }))
            }
          })
          setTableOrViewSchema(response?.map(schema => ({
            name: schema.name,
            type: schema.type
          })));
        }
        // console.log("fetch succeeded:", response);
      })
      .catch((error) => {
        // console.log("error:" + error);
        toastError("unable to fetch schemas");
      });

    setSelectedTab("Schemas");
  }
  const handleViewClick = async (catalogId: string, databaseId: string, viewId: string) => {
    setDatabaseName(databaseId);
    setSelectedTable(viewId);
    await getViewsSchemas({ catalogId, databaseId, viewId })
      .unwrap()
      .then((response) => {
        if (response !== undefined && response !== null) {
          setViewSchema((previous) => {
            return {
              ...previous,
              [catalogId + "__" + databaseId + "__" + viewId]: response?.map(schema => ({
                name: schema.name,
                type: schema.type
              }))
            }
          })
          setTableOrViewSchema(response?.map(schema => ({
            name: schema.name,
            type: schema.type
          })));
        }
        // console.log("fetch succeeded:", response);
      })
      .catch((error) => {
        // console.log("error:" + error);
        toastError("unable to fetch schemas");
      });

    setSelectedTab("Schemas");
  }
  const [tabValue, setTabValue] = useState('columns');
  const tabValueChange = (event: React.SyntheticEvent, tabValue: string) => {
    setTabValue(tabValue);
  };
  const catalogRows = catalogTable?.map(item => [item]) || [];
  const TableRows = selectedDatabase?.map(item => [item]) || [];
  const schemaRows = tableOrViewSchema?.map(schema => [schema.name, schema.type]) || [];
  const databaseRows = selectedCatalog?.map(database => [database]) || [];

  const schemaColumns = ["FIELD", "TYPE"];
  const tableColumns = ["TABLE NAME"];
  const catalogColumns = ["CATALOG NAME"];
  const databaseColumns = ["DATABASE NAME"]
  const schemaTabContent = {
    label: `${schemaRows.length} COLUMNS`,
    value: 'columns',
    component: <VirtuosoTable
      rows={schemaRows}
      columns={schemaColumns}
      tableHeight={"100%"} />

  }

  const tableTabContent = {
    label: `${TableRows.length} TABLES`,
    value: 'columns',
    component: <VirtuosoTable
      rows={TableRows}
      columns={tableColumns}
      tableHeight={"100%"} />

  }

  const catalogTabContent = {
    label: `${catalogRows.length} CATALOGS`,
    value: 'columns',
    component: <VirtuosoTable
      rows={catalogRows}
      columns={catalogColumns}
      tableHeight={"100%"} />

  }
  const databaseTabContent = {
    label: `${databaseRows.length} DATABASES`,
    value: 'columns',
    component: <VirtuosoTable
      rows={databaseRows}
      columns={databaseColumns}
      tableHeight={"100%"} />

  }

  let tabContent: TabContent[];
  switch (selectedTab) {
    case 'Tables':
      tabContent = [tableTabContent];
      break;
    case 'Schemas':
      tabContent = [schemaTabContent];
      break;
    case 'Catalog':
      tabContent = [catalogTabContent];
      break;
    case 'Database':
      tabContent = [databaseTabContent];
      break;
    default:
      tabContent = [];
  }
  return (
    <Box className="parent-container">

      <SplitPane
        split="vertical"
        sizes={sizes}
        onChange={(sizes) => { setNewSizes(sizes) }}
        sashRender={renderSash}
      >

        <Pane minSize={'150px'} maxSize={'100%'} style={{ paddingRight: "2px" }}>
          <div style={{ height: "100%", background: "#FBFBFF", boxShadow: "2px 0px 2px rgba(0, 0, 0, 0.2)" }}>
            <CustomizedTreeView
              children={<IconButton onClick={toggleSidebar}><KeyboardArrowLeft /></IconButton>}
              setSelectedTab={setSelectedTab}
              setSelectedDatabase={setSelectedDatabase}
              setSelectedTable={setSelectedTable}
              handleTableSchemaClick={handleTableClick}
              handleViewSchemaClick={handleViewClick}
              setCatalogTable={setCatalogTable}
              setSelectedCatalog={setSelectedCatalog}
              setDatabaseName={setDatabaseName}
            />

          </div>

        </Pane>
        <Pane >
          <Stack
            direction={"column"}
            width="100%"
            justifyContent={"flex-start"}
            alignItems="flex-start"
            spacing={1}
            padding={"10px"}
          >

            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"space-between"}
              alignItems="center"
              spacing={1}
            >
              <Stack
                direction={"row"}
                width="50%"
                justifyContent={"flex-start"}
                alignItems="center"
                spacing={1}
              >
                <Stack direction="row" alignItems={"center"} >
                  <Typography className={!selectedTable ? "bold" : "normal"}>{databaseName}</Typography>
                  {selectedTable && (
                    <>
                      <Typography className="normal"> / </Typography>
                      <Typography className="bold">{selectedTable}</Typography>
                    </>
                  )}
                </Stack>
                <IconButton onClick={setSidebarToFullScreen} style={{ color: rightArrowColor }}><DockRightIcon color={rightArrowColor} /></IconButton>
                <IconButton onClick={toggleSidebar} style={{ color: bottomArrowColor }}><DockBottomIcon color={bottomArrowColor} /></IconButton>

                {!isSidebarOpen && <IconButton onClick={toggleSidebar} ><SearchIcon /></IconButton>}
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
                width={"40%"}
              >

                <Profile />
              </Stack>
            </Stack>

          </Stack>
          <Box className="main-container">
            <Tabs tabValue={tabValue} tabValueChange={tabValueChange} tabContents={tabContent} tabHeight='100%' divider={false} />;


          </Box>
        </Pane>



      </SplitPane>

    </Box>
  );
}