import { Box, Stack, useMediaQuery } from "@mui/material";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import {
  useCreateInvitesMutation,
  useGetRolesQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../api/adminApi_rtk";
import { useAppSelector } from "../../app/hooks";
import { LoadingPage } from "../../loader/LoadingPage";
import { ROLES, USERS } from "../../testValues";
import { TextButton } from "../buttons/TextButton";
import { Role, User_Admin } from "../models";
import { BOX_STYLE } from "../models/constants";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { InvitePeople } from "./InvitePeople";
import { LogsTable } from "./LogsTable";
import { PeopleTable } from "./PeopleTable";
import { toastError, toastSuccess } from "../Toasts";
import { TabMessage } from "../Tabs";
import { IconOnButton } from "../buttons/IconOnButton";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Billing from "../Billing";


const Admin = () => {
  const PEOPLE = "people";
  const LOGS = "logs";
  const BILLING = "billing";
  const [section, setSection] = useState(PEOPLE);
  const [openInvite, setOpenInvite] = useState(false);
  // const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [users, setUsers] = useState<User_Admin[]>([]);

  const { organizationName, organizationId, user } = useAppSelector(loginAuthState);
  const [createInvites, { isLoading: addUsersLoading }] =
    useCreateInvitesMutation();
  const { data: rolesList, isLoading: getRolesLoading, refetch: getRoles } = useGetRolesQuery();
  const { data: usersList, isLoading: getUsersLoading, refetch: getUsers } = useGetUsersQuery(organizationId);

  useEffect(() => {
    if (rolesList !== undefined && rolesList?.length > 0) {
      setRoles(rolesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRolesLoading])
  useEffect(() => {
    if (usersList !== undefined && usersList?.length > 0) {
      setUsers(usersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersLoading])
  // useEffect(()=>{
  //   fetchUsers();
  // },[])

  // const fetchRoles = async () => {
  //   await getRoles()
  //     .unwrap()
  //     .then((response) => {

  //       if(response!==undefined && response?.length>0){

  //         setRoles(response);
  //         console.log({ response });
  //       }        
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       // alert("Error when fetching roles by organization");
  //       // setRoles(ROLES);
  //       toastError("unable to fetch rows");
  //     });
  // };

  // const fetchUsers = async () => {
  //   await getUsers(organizationId)
  //     .unwrap()
  //     .then((response) => {

  //       if(response!==undefined && response?.length>0){

  //         setUsers(response);
  //         console.log({ response });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //       // setUsers(USERS);
  //       toastError("unable to fetch users");
  //     });
  // };

  const refresh = () => {
    getRoles();
    getUsers();
  }
  const loadSection = (page: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSection(page);
  };

  const addUsers = async () => {
    setOpenInvite(false);
    if (roles !== undefined && user !== undefined
      && user?.name !== undefined) {
      const person = {
        // name: personName,
        email: personEmail,
        organization_id: user.org_id,
        inviterName: user.name,
        roleIds: roles.filter((role) => {
          return selectedRoles.includes(role.id);
        }).map((role) => role.id),
      };
      await createInvites(person)
        .unwrap()
        .then((response) => {
          console.log({ response });
          toastSuccess("your invite has been sent");
        })
        .catch((error) => {
          console.log({ error });
          toastError("unable to add users");
        });
    } else {
    }
  };
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");
  const boxWidth = isSmallScreen ? "900px" : "100%";

  return (
    <Fragment>
      <LoadingPage isLoading={getRolesLoading || getUsersLoading} />
      <Stack direction="column" marginTop={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} justifyContent={"flex-start"} spacing={1}>
            <TextButton
              buttonText="People"
              disabled={false}
              fullWidth={false}
              handleClick={loadSection(PEOPLE)}
              variant={section === PEOPLE ? "contained" : "outlined"}
            />
            { (process.env.REACT_APP_ENV==="dev") && 
            <>
            <TextButton
              buttonText="Logs"
              disabled={false}
              fullWidth={false}
              handleClick={loadSection(LOGS)}
              variant={section === LOGS ? "contained" : "outlined"}
            />
            <TextButton
              buttonText="Billing"
              disabled={false}
              fullWidth={false}
              handleClick={loadSection(BILLING)}
              variant={section === BILLING ? "contained" : "outlined"}
            />
            </>}
            <IconOnButton handleClick={refresh} materialIcon={<RefreshRoundedIcon />} />
          </Stack>
          {section === PEOPLE && (
            <TextButton
              buttonText="Invite People"
              disabled={false}
              fullWidth={false}
              handleClick={() => {
                setOpenInvite(true);
              }}
              variant={"contained"}
            />
          )}

        </Stack>
        <Box sx={{ ...BOX_STYLE, width: boxWidth }}>
          {section === PEOPLE ? (
            (users.length > 0 &&
              roles.length > 0) ? <PeopleTable users={users} roles={roles} /> : <TabMessage message={"There are no users"} />
          ) : (section === LOGS ?
            <LogsTable /> : <Billing />
          )}
        </Box>
      </Stack>
      <InvitePeople
        open={openInvite}
        setOpen={setOpenInvite}
        // personName={personName}
        // setPersonName={setPersonName}
        personEmail={personEmail}
        setPersonEmail={setPersonEmail}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        roles={roles}
        invitePeople={addUsers}
        isLoading={addUsersLoading}
      />
    </Fragment>
  );
};

export default Admin;
