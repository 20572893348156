import { Box, Divider, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export const EmailVerification = () => {
  const location = useLocation();
 
  const { email,tenantId } = location.state;
   return (
    <div>
       <Typography className="header" variant="h6">VegaDB</Typography>
      <Typography className="description" variant="body1">Providing the best price/performance of any known modern data warehouse at a large scale!</Typography>
    <Typography className="header" variant="h6" color="black">
      Email Verification
    </Typography>
    <Box
    sx={{
      width: 400,
      height: 250,
      margin: '0 auto', 
      position: 'absolute',
      top: '50%', 
      left: '50%',
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px'
    }}
  >
    <Stack>
    <Typography className="header" variant="body1" color="blue">
       Your Organization Name :  {tenantId}
    </Typography>
      
      <Typography className="header" variant="body1" color="black">
      We've sent an email to verify you account
    </Typography>
    <Typography className="header" variant="body2" color="#676780">
      ({email})
    </Typography>
    <Divider sx={{ margin: '10px 0' }} />
    <Typography className="header" variant="body2" color="#676780">
      The link will be valid for only 2 hours. If you weren't able to find the email in your inbox, please check the 
      spam / promotions tab.
    </Typography>
    
    </Stack>
  </Box>
  </div>
  );
    }