import { IconButton } from '@mui/material';

interface IconOnButtonProps {
  handleClick: () => void,
  materialIcon: React.ReactNode
}

export const IconOnButton: React.FC<IconOnButtonProps> = ({ handleClick, materialIcon }) => {
  return (
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={handleClick}
    >
      {materialIcon}
    </IconButton>
  );
}