import { Stack } from '@mui/material';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { forwardRef, Fragment, memo } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

interface VirtuosoTableProps {
  rows: string[][];
  columns: string[];
  tableHeight: string;
}

export const VirtuosoTable: React.FC<VirtuosoTableProps> = memo(({ rows, columns, tableHeight }) => {
  if (rows === undefined) {
    rows = [];
  }

  if (columns === undefined) {
    columns = [];
  }

  console.log("runs on sql editor change");

  const VirtuosoTableComponents: TableComponents<any> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column: string) => (
          <TableCell
            key={column}
            variant="head"
            // style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
              color: '#9796A7',
            }}
          >
            {column}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index: number, row: string[]) {
    console.log({ row });

    return (
      <Fragment>
        {columns.map((column: string, index: number) => (
          <TableCell key={`${column}-${row[index]}`}>
            {getCellContent(column, row[index])}
          </TableCell>
        ))}
      </Fragment>
    );
  }

  function getCellContent(columnName: string, value: string) {
    const iconColor = { color: '#676780' };
    switch (columnName) {
      case "Worksheet Name":
        return (
          <ContentWithIcon materialIcon={<DescriptionOutlinedIcon sx={iconColor} />} value={value} />
        )
      case "Folder Name":
        return (
          <ContentWithIcon materialIcon={<FolderOutlinedIcon sx={iconColor} />} value={value} />
        )
      default:
        return value;
    }
  }

  const ContentWithIcon = ({ materialIcon, value }: { materialIcon: React.ReactNode, value: string }) => {
    return (
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
        {materialIcon}<b>{value}</b>
      </Stack>
    )
  }

  return (

    <TableVirtuoso
      data={rows}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={rowContent}
    />
  )
})