import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { RootState } from "../app/store";
import { workspaceState } from '../components/Workspace/WorkspaceSlice';
import { sec } from "../security";
import { resetLoginAuthState } from "../components/onBoarding/LoginAuthSlice";
import { resetWorkspaceListState } from "../components/Workspace/WorkspaceListSlice";
import { resetWorkspaceState } from "../components/Workspace/WorkspaceSlice";
import { useAppDispatch } from "../app/hooks";
// export const Logout = ()=>{const dispatch = useAppDispatch();
//   dispatch(resetLoginAuthState());
//   dispatch(resetWorkspaceListState());
//   dispatch(resetWorkspaceState());}
export const fetchBaseQueryObj = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL,
  prepareHeaders: async (headers, { getState }) => {
    const accessToken = await sec.getAccessTokenSilently()({});
    // // If we have a token set in state,let's assume that we should be passing it.
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    } 
    // else {
    //   Logout();
    //   sec.logout();
    // }
    headers.set("Content-Type", "application/json");
    headers.set("credentials", "same-origin");
    headers.set("mode", "no-cors");

    return headers;
  },
});

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, WebApi, extraOptions) => {
  const state = WebApi.getState() as any;
  const workspace = workspaceState(state); 
  const workspaceId = workspace.workspace?.workspace_id; 
  const regionalUrl=process.env.REACT_APP_REGIONAL_URL;
  const env=process.env.REACT_APP_ENV
  let baseUrl: string;
  if (env === 'stage') {
    baseUrl = `https://${workspaceId}.stage.${regionalUrl}`;
  } else if (env === 'app') {
    baseUrl = `https://${workspaceId}.cloud.${regionalUrl}`;
  } else {
    baseUrl = `https://${workspaceId}.dev.${regionalUrl}`;
  }
  const preparedHeaders = async (headers: Headers) => {
    const accessToken = await sec.getAccessTokenSilently()({});
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    headers.set('Content-Type', 'application/json');
    headers.set('credentials', 'same-origin');
    headers.set('mode', 'no-cors');
    return headers;
  };

  const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders: preparedHeaders });
  return baseQuery(args, { ...WebApi, getState: () => state }, { ...extraOptions });
};

export const fetchBaseQueryObjWithoutToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL,
  prepareHeaders: async (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    headers.set("credentials", "same-origin");
    headers.set("mode", "no-cors");

    return headers;
  },
});

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURLS,
});
