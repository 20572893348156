import { MoreHoriz, KeyboardArrowDown, KeyboardArrowUp, KeyboardArrowRight } from "@mui/icons-material";
import { Collapse, IconButton, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Fragment, useState } from "react";
import { WHITE } from "./models/constants";

type CollapsibleTableProps = {
  data: any;
  mainId: string;
  page: string;
  skipKeys: string[];
  displayContent: Map<string, React.ReactNode>;
  menuOptions: Map<string, React.ReactNode>;
  isSubTreeLoaded: Map<string, boolean>;
  rowAction: (fetch_id: string) => void;
  height: string;
};

export const CollapsibleTable = ({ data, mainId, page, skipKeys, displayContent, menuOptions, rowAction, isSubTreeLoaded, height }: CollapsibleTableProps) => {
  const keys: string[] = Object.keys(data.values().next().value);
  const capitalize = (field: string) => {
    let headerName = field.replace(/([a-z])([A-Z])/g, '$1 $2')
    headerName = headerName.replace("_", " ");
    return headerName.charAt(0).toUpperCase() + headerName.slice(1)
  }
  const columns: string[] = keys
    .filter(key => !skipKeys.includes(key))
    .map(key => capitalize(key))
  const Row = ({ datum, index }: { datum: Object, index: number }) => {
    const [isCurrentSubTreeLoaded] = useState(isSubTreeLoaded.get(datum[mainId as keyof typeof datum].toString()))
    const [openCollapse, setOpenCollapse] = useState(isCurrentSubTreeLoaded && data.length === 1);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onRowClick = () => {
      if (!isCurrentSubTreeLoaded) {
        switch (page) {
          case 'worksheets':
            rowAction(datum[mainId as keyof typeof datum].toString());
        }
      }
      setOpenCollapse(!openCollapse)
    }

    return (<Fragment>
      <TableRow sx={{
        '&>.MuiTableCell-root': {
          borderBottom: 'unset',
          backgroundColor: WHITE,

        }
      }}>
        <TableCell key={index} align="center" sx={{
          borderTopLeftRadius: 18,
          borderBottomLeftRadius: !openCollapse ? 18 : 0
        }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onRowClick}
          >
            {isCurrentSubTreeLoaded
              ? (openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />)
              : <KeyboardArrowRight />
            }
          </IconButton>
        </TableCell>
        {keys.filter(key => !skipKeys.includes(key))
          .map(key => {
            return (<TableCell
              key={`${datum[key as keyof typeof datum].toString()}_${index}`}
              align="left">{datum[key as keyof typeof datum].toString()}</TableCell>)
          })}
        <TableCell align="right"
          key={`options_${index}`}
          sx={{
            borderTopRightRadius: 18,
            borderBottomRightRadius: !openCollapse ? 18 : 0
          }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMenu ? 'long-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHoriz />
          </IconButton>
          {((process.env.REACT_APP_ENV === "dev") || (page !== "worksheets")) &&
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                },
              }}
            >
              {menuOptions.get(datum[mainId as keyof typeof datum].toString())}
            </Menu>
          }
        </TableCell>
      </TableRow>
      <TableRow sx={{
        '&>.MuiTableCell-root': {
          borderBottom: 'unset'
        }
      }}>
        <TableCell sx={{ padding: 0 }} colSpan={6}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            {displayContent.get(datum[mainId as keyof typeof datum].toString())}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>)
  }

  return (
    <TableContainer sx={{ minHeight: height, height: "100%", }}>
      <Table aria-label='collapsible table' sx={{
        // borderCollapse: 'separate',
        // borderSpacing: '0 12px'
      }}>
        <TableHead>
          <TableRow>
            <TableCell key="Expand/Collapse" width={20} />
            {columns.map(column => <TableCell align="left" style={{ minWidth: "170px" }} key={column}>{column}</TableCell>)}
            <TableCell align="right" key="options">Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((datum: Object, index: number) => (
            <Row key={datum[mainId as keyof typeof datum].toString()} datum={datum} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}