import React from 'react';
import Icon from '../assets/WorkspaceSVG';
import { ReactComponent as DatabaseSVG } from '../assets/database_FILL0_wght400_GRAD0_opsz48.svg';
import WarehouseIcon from '../assets/Datawarehousesvg';
interface Props {
  src: string;
  alt: string;
}
const Image: React.FC<Props> = ({ src, alt }) => {
  switch (src) {
    case 'VegaLakeCloudUI_03.svg':
      return <Icon />;
    case 'database_FILL0_wght400_GRAD0_opsz48.svg':
      return <DatabaseSVG />;
      case 'Datawarehousesvg.svg':
        return <WarehouseIcon />
      
    default:
      return <div>{alt}</div>;
  }
};
export default Image;
