import {
  Box,
  Divider,
  Avatar,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  LinearProgress,
  debounce,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { TextButton } from "../buttons/TextButton";
import { CatalogOrDatabasesOrTablesOrViews, QueryTypeDefType } from "../models";
// import { HOMEPAGE } from '../models/constants';
import { ArrowOutwardSharp, Edit as EditIcon, KeyboardArrowLeft, Save as SaveIcon } from "@mui/icons-material";
import { decode, encode } from "js-base64";
import { useGetWarehouseListQuery } from "../../api/warehouseApi_rtk";
import {
  useGetWorksheetContentMutation,
  useGetWorksheetQuery,
  useGetWorksheetVersionsQuery,
  // useRunScriptMutation,
  useSaveWorksheetMutation,
  useUpdateWorksheetMutation,
} from "../../api/worksheetApi_rtk";
import {
  useCancelQueryMutation,
  useQueryResultMutation,
  useGetQueryStatusMutation,
  useSubmitQueryMutation,
} from "../../api/interactiveQueryApi_rtk";
// import {
//   VERSIONS,
//   WAREHOUSES,
//   // WORKSHEETS_MAP,
//   // INTERACTIVE_QUERY_RESPONSE,
// } from "../../testValues";
import { IconOnButton } from "../buttons/IconOnButton";
// import { DataGridTable } from "../DataGridTable";
import {
  DataWarehouseModel,
  InteractiveQueryModel,
  InteractiveQueryResponse,
  InteractiveQueryStatus,
  InteractiveQueryResult,
} from "../models";
import { WHITE } from "../models/constants";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { CustomizedTreeView } from "../schema/CustomTreeView";
import { TabMessage, Tabs } from "../Tabs";
import { workspaceState } from "../Workspace/WorkspaceSlice";
import { VirtuosoTable } from "../VirtuosoTable";
import { LinearProgressWithLabel } from "../../loader/LoadingPage";
import "./Worksheet.scss";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-github";
import { toastError } from "../Toasts";
import Profile from "../Profile";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import { DockTopIcon, DockRightIcon, DockBottomIcon } from "../CustomIcons/VegaIcons";
import { Ace } from "ace-builds";
import { useGetCatalogsQuery, useGetDatabasesMutation } from "../../api/schemaApi_rtk";



const defaultResult: InteractiveQueryResult = {
  columns: [],
  rows: [],
  query_id: "",
  completed_at: "",
  created_at: "",
  query: "",
  started_at: "",
  warehouse_id: ""
};

const initialQueryState: QueryTypeDefType = {
  queryString: "",
};

const CssTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomStyle: "dotted",
  },
});

export const Worksheet = () => {
  let { worksheetId } = useParams();
  const { workspace } = useAppSelector(workspaceState);
  const { organizationName } = useAppSelector(loginAuthState);
  const [onFirstEnteringPage, setFirstEnteringPage] = useState(false);
  ////////////////////////////////////Fetching worksheet////////////////////////////////
  const { data: Worksheet, isError: worksheetError, isSuccess: worksheetSuccess, refetch: getWorksheet } = useGetWorksheetQuery({
    organizationName,
    workspace_id: workspace?.workspace_id,
    worksheet_id: worksheetId,
  });
  const [worksheet, setWorksheet] = useState({
    worksheet_id: "",
    worksheet_name: "",
    created_time: "",
    created_by: "",
    lastCatalog: "",
    lastDatabase: "",
    lastQueryId: ""
  });
  const [hasWorksheetRefetched, setHasWorksheetRefetched] = useState<boolean>(false);
  useEffect(() => {
    if (worksheetError && !hasWorksheetRefetched) {
      getWorksheet()
        .unwrap()
        .then((response) => {
          setHasWorksheetRefetched(true);
        })
        .catch((error) => {
          console.log("error:" + error);
          toastError("unable to fetch the worksheet");
        });
    }
  }, [worksheetError, Worksheet, hasWorksheetRefetched]);
  useEffect(() => {
    if (Worksheet !== undefined && worksheetSuccess) {
      setWorksheet(Worksheet)
      console.log(Worksheet);
      if (Worksheet.lastCatalog !== "" && Worksheet.lastCatalog !== undefined && Worksheet.lastCatalog !== null) {
        setCurrentCatalog(Worksheet.lastCatalog);
      }
      if (Worksheet.lastDatabase !== "" && Worksheet.lastDatabase !== undefined && Worksheet.lastDatabase !== null) {
        setDatabase(Worksheet.lastDatabase);
      }
      if (Worksheet.lastQueryId !== "" && Worksheet.lastQueryId !== undefined && Worksheet.lastQueryId !== null) {
        setLoading(true);
        setQuerySubmissionResponse({ "query_id": Worksheet.lastQueryId } as InteractiveQueryResponse);
      }
    }
  }, [Worksheet]);
  /////////////////////////////updating worksheet name (if made editable)/////////////////
  const [updateWorksheet] = useUpdateWorksheetMutation();
  // const [worksheetNameEditable, setWorksheetNameEditable] = useState(false);
  const updateWorksheetName = async () => {
    console.log(worksheet.worksheet_name);
    if (currentCatalog !== undefined && database !== undefined && querySubmissionResponse !== undefined
      && currentCatalog !== "" && database !== "") {
      await updateWorksheet({
        organizationName,
        workspace_id: workspace?.workspace_id,
        worksheet: {
          worksheet_id: worksheetId,
          worksheet_name: worksheet.worksheet_name,
          lastCatalog: currentCatalog === undefined ? "" : currentCatalog,
          lastDatabase: database === undefined ? "" : database,
          lastQueryId: querySubmissionResponse === undefined ? "" : querySubmissionResponse.query_id,
          folder_id: worksheetId
        },
      })
        .unwrap()
        .then((response) => {
          console.log({ response });
        })
        .catch((error) => {
          console.log({ error });
          // toastError("unable to update worksheet name");
        });
    }
    // setWorksheetNameEditable(false);
  };
  /////////////////////////////Fetching Warehouses////////////////////////////////////////////////
  const { data: WarehousesList, isError, isFetching, isSuccess, refetch: getWarehouseList } = useGetWarehouseListQuery({
    workspace_id: workspace?.workspace_id
  });
  const [saveWorksheet] = useSaveWorksheetMutation();
  const [worksheetContent, setWorksheetContent] = useState({
    worksheet_id: worksheetId,
    contents: "",
    version: "",
    tenant_id: "",
  });

  const [warehouses, setWarehouses] = useState<DataWarehouseModel[]>([]);
  const [currentWarehouse, setCurrentWarehouse] = useState<string>();
  const [hasWarehouseRefetched, setHasWarehouseRefetched] = useState<boolean>(false);
  useEffect(() => {
    if (isError && !hasWarehouseRefetched) {
      getWarehouseList()
        .unwrap()
        .then((response) => {
          setHasWarehouseRefetched(true);
          console.log("fetch warehouse succeeded:", response);
        })
        .catch((error) => {
          console.log("error:" + error);
          toastError("unable to fetch warehouses");
        });
    }
  }, [isError, getWarehouseList, hasWarehouseRefetched]);

  useEffect(() => {
    if (WarehousesList !== undefined) {
      const filteredResponse = WarehousesList?.filter(
        (warehouse) => warehouse.status === "RUNNING"
      );
      setWarehouses(filteredResponse);
      console.log(filteredResponse);
      // if (filteredResponse !== undefined && filteredResponse !== null &&
      //   filteredResponse.length > 0) {
      //   setCurrentWarehouse(filteredResponse[0]?.name);
      // }
    }
  }, [WarehousesList]);
  //////////////////////////Fetching Catalogs////////////////////////
  const { data: Catalogs, isError: isCatalogsError, isLoading: isCatalaogsLoading, refetch: getCatalogs } = useGetCatalogsQuery();
  const [catalogs, setCatalogs] = useState<CatalogOrDatabasesOrTablesOrViews[]>([]);
  const [currentCatalog, setCurrentCatalog] = useState<string>("");
  const [hasCatalogsFetched, setCatalogsFetched] = useState<boolean>(false);
  useEffect(() => {
    // const fetchCatalogs = async () =>{
    if (isCatalogsError && !hasCatalogsFetched) {
      getCatalogs()
        .unwrap()
        .then((response) => {
          setCatalogsFetched(true);
        })
        .catch((error) => {
          console.log("catlogues:" + error);
          toastError("error while fetching catalogs");
        });
    };
    // }
    //  fetchCatalogs();
  }, [isCatalogsError, getCatalogs, hasCatalogsFetched]);
  useEffect(() => {
    if (Catalogs !== undefined && Catalogs?.length > 0) {
      setCatalogs(Catalogs);
      if (worksheet.lastCatalog !== "" && worksheet.lastCatalog !== undefined && worksheet.lastCatalog !== null
      ) {
        if(currentCatalog=== undefined || currentCatalog === null || currentCatalog===""){
          setCurrentCatalog(worksheet.lastCatalog);
        }
      }
    }
  }, [Catalogs]);
  /////////////////////////Fetching Databases///////////////////////
  const [getDatabases, { data: Databases }] = useGetDatabasesMutation();
  const [databases, setDatabases] = useState<CatalogOrDatabasesOrTablesOrViews[]>([]);
  const [database, setDatabase] = useState<string>("");
  const GetDatabasesList = async () => {
    if (currentCatalog !== "" && currentCatalog !== undefined) {
      await getDatabases(currentCatalog)
        .unwrap()
        .then((Databases) => {
          console.log(Databases);
          if (Databases !== undefined && Databases?.length > 0) {
            setDatabases(Databases);
            if (worksheet.lastDatabase !== "" && worksheet.lastDatabase !== undefined && worksheet.lastDatabase !== null 
           ) {
             if(database===undefined || database ===null || database===""){
              setDatabase(worksheet.lastDatabase);
             }
            }
          }
        })
        .catch((error) => {
          toastError("error while fetching databases");
        })
    }
  }
  useEffect(() => {
    GetDatabasesList();
  }, [currentCatalog]);

  // const [schemas, setSchemas] = useState<Schema[]>([]);
  // const [schema, setSchema] = useState<string>("");
  ///////////////////////Fetching Versions/////////////////////////
  const { data: WorksheetVersions, isSuccess: versionSuccess, isError: versionError, refetch: getWorksheetVersions } = useGetWorksheetVersionsQuery({ worksheetId });
  const [versions, setVersions] = useState<{ version: string }[]>([]);
  const [currentVersion, setCurrentVersion] = useState<string>("");
  const [hasVersionFetched, setVersionFetched] = useState<boolean>(false);
  useEffect(() => {
    if (versionError && !hasVersionFetched) {
      getWorksheetVersions()
        .unwrap()
        .then((response) => {
          setVersionFetched(true);
          // console.log("fetch succeeded:", response);
        })
        .catch((error) => {
          // console.log("error:" + error);
          toastError("unable to fetch the sql versions");
        });
    }
  }, [versionError, getWorksheetVersions, hasVersionFetched]);
  useEffect(() => {
    if (WorksheetVersions !== undefined && WorksheetVersions.length > 0) {
      console.log("fetchWorksheetVersions: " + WorksheetVersions[0]);
      const sortedWorksheetVersion = [...WorksheetVersions].sort((a, b) => parseInt(a.version) - parseInt(b.version));
      setVersions(sortedWorksheetVersion);
      setCurrentVersion(sortedWorksheetVersion[0].version);
    }
  }, [WorksheetVersions]);
  //////////////////Fetching worksheet query data from the selected version//////////////////
  const [getWorksheetContent] = useGetWorksheetContentMutation();
  useEffect(() => {
    const fetchWorksheetContent = async () => {
      await getWorksheetContent({
        worksheet_id: worksheetId,
        version: currentVersion,
      })
        .unwrap()
        .then((response) => {
          // console.log(response);
          if (response !== undefined) {
            const decodedString = decode(response.contents);
            // console.log({ decodedString });
            setWorksheetContent({
              ...response,
              contents: decodedString,
              version: currentVersion,
            });
            // setQuery({ queryString: decodedString });
            setUserQuery(decodedString);
          }
        }).catch((error) => {
          toastError("unable to get the worksheet information for this version");
        })
    }
    if (currentVersion !== "") fetchWorksheetContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]);

  // useEffect(() => {
  //   const fetchDatabasesSchemasTables = async () => {
  //     if (worksheetId!== undefined ){
  //     await submitQuery({
  //       // string: "",
  //       limit: 0,
  //       database: "",
  //       warehouse_id: worksheetId,
  //       query: "",
  //       catalog:""
  //     })
  //       .unwrap()
  //       .then((response) => {
  //         console.log("fetchDatabasesSchemasTables: ", { response });
  //       })
  //       .catch((error) => {
  //         console.log("fetchDatabasesSchemasTables: ", { error });
  //         // constructDatabasesSchemas();
  //         toastError("unable to fetch schema tables");
  //       });
  //     }
  //     fetchDatabasesSchemasTables();
  //   };
  // }, [currentWarehouse]);

  // const constructDatabasesSchemas = () => {
  //   const databases: Database[] = [
  //     {
  //       id: "1",
  //       name: "database_1",
  //     },
  //     {
  //       id: "2",
  //       name: "database_2",
  //     },
  //     {
  //       id: "3",
  //       name: "database_3",
  //     },
  //   ];
  //   setDatabases(databases);
  //   setDatabase(databases[0].id);
  //   const schemas: Schema[] = [
  //     {
  //       id: "1",
  //       name: "schema_1",
  //     },
  //     {
  //       id: "2",
  //       name: "schema_2",
  //     },
  //     {
  //       id: "3",
  //       name: "schema_3",
  //     },
  //   ];
  //   setSchemas(schemas);
  //   setSchema(schemas[0].id);
  // };
  /////////////////////////////Change the tab///////////////////////////////////////////
  const [queryStatus, setQueryStatus] = useState({} as InteractiveQueryStatus);
  interface statusOutput {
    status: string;
  }
  const StatusOfQuery: React.FC<statusOutput> = ({ status }) => {
    switch (status) {
      // case 'SUBMITTED':
      //   return <TabMessage message={"Query is submitted and running"} />;
      case 'FAILED':
        return <div>Query has failed</div>;
      case 'CANCELLED':
        return <div>Query has been cancelled</div>;
      default:
        return <></>;
    }
  };

  const [tabValue, setTabValue] = useState("output");
  const tabValueChange = (event: React.SyntheticEvent, tabValue: string) => {
    setTabValue(tabValue);
  };
  const [outPutContent, setOutPutContent] = useState<React.ReactNode>(
    <TabMessage message={"Query will be processed on run script"} />
  );
  const tabContent = [
    {
      label: "Output",
      value: "output",
      component:
        <>
          {outPutContent}
          {queryStatus !== undefined && StatusOfQuery({ status: queryStatus?.status })}
          {queryStatus !== undefined && queryStatus?.status === 'FAILED' && 
          queryStatus?.status_meta !== undefined
            && queryStatus?.status_meta !== null && queryStatus?.status_meta?.query_error !== undefined && queryStatus?.status_meta?.query_error !== null
            && <div>{queryStatus?.status_meta?.query_error}</div>}
        </>,
    },
    ...(process.env.REACT_APP_ENV === "dev"
      ? [
        {
          label: "Query Details",
          value: "details",
          component: <TabMessage message={"Yet to implement"} />,
        },
        {
          label: "Query Workflow",
          value: "workflow",
          component: <TabMessage message={"Yet to implement"} />,
        },
      ]
      : []),
  ];

  ///////////////////////////handling basic inputs for the querying//////////////////////////////////////////
  // const [worksheetDetailError, setWorksheetDetailError] = useState({
  //   warehouseId: false,
  //   id: false,
  //   worksheet_name: false,
  //   created_time: false,
  //   created_by: false,
  //   sql_query: false,
  // });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "warehouse") {
      setCurrentWarehouse(value);
    } else if (name === "version") {
      setCurrentVersion(value);
    } else if (name === "database") {
      setDatabase(value);
    } else if (name === "catalog") {
      setCurrentCatalog(value);
    }
  };
  ////////////////////////////////States setup for querying//////////////////////////////////
  const [editorRef, setEditorRef] = useState<Ace.Editor>();
  const [query, setQuery] = useState(initialQueryState);
  const [userQuery, setUserQuery] = useState(initialQueryState.queryString);
  const [selectedQuery, setSelectedQuery] = useState("");
  const [hasQueryChanged, setHasQueryChanged] = useState(false);

  const [startInterval, setStartInterval] = useState(false);

  const runQuery = () => {
    // console.log({ query, userQuery, selectedQuery });
    if (editorRef !== undefined) {
      // console.log("directly from editor");
      const selection = editorRef.getSelectedText();
      const value = editorRef.getValue();
      runQueryScripts((selection !== undefined && selection !== "" && selection !== null) ? selection :
        value
      );
    }
    // Your code here to start the processing
    // setRunScript(true);
    setFirstEnteringPage(true);
  };

  const [queryResults, setQueryResults] = useState(
    {} as InteractiveQueryResult
  );

  const onChange = (queryString: string) => {
    // console.log(queryString);
    if (queryString === worksheetContent.contents) {
      setHasQueryChanged(false);
    } else {
      setHasQueryChanged(true);
      setUserQuery(queryString);
      setWorksheetContent({ ...worksheetContent, contents: queryString });
    }
  };

  const handleSelectionChange = (value: any, event?: any) => {
    const selectedText = value.session.$searchHighlight.regExp?.source;
    // console.log({ selectedText });
    if (selectedText !== undefined) setSelectedQuery(selectedText);
    else setSelectedQuery("");
  };

  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [querySubmissionResponse, setQuerySubmissionResponse] = useState<
    InteractiveQueryResponse
  >();

  const [submitQuery] = useSubmitQueryMutation();
  const [getQueryStatus, { isLoading: statusIsLoading }] = useGetQueryStatusMutation();
  const [getQueryResult] = useQueryResultMutation();

  // const [runScript, setRunScript] = useState(true);

  // useEffect(() => {
  //   if (runScript) runQueryScripts();
  // }, [runScript]);

  const runQueryScripts = async (query: string) => {
    setOutPutContent(<div>Query is submitted and running </div>);
    if (currentCatalog !== "" && currentCatalog !== undefined
      && currentWarehouse !== undefined && currentWarehouse !== ""
      && database !== undefined && database !== "" && query !== undefined && query !== null && query !== "") {
      await submitQuery({
        // string: query.queryString,
        limit: 0,
        database: database,
        warehouse_id: currentWarehouse,
        // schema: schema,
        catalog: currentCatalog,
        query: query
      }).unwrap()
        .then((response) => {
          // console.log("runQueryScripts: ", { response });
          setQuerySubmissionResponse(response);
          setCurrentIndex(0);
          setStartInterval(true);
          setLoading(true);
          // setRunScript(false);
        })
        .catch((error) => {
          console.log("runQueryScripts: ", {
            error,
          });
          // setQuerySubmissionResponse(INTERACTIVE_QUERY_RESPONSE);
          // setCurrentIndex(0);
          // setStartInterval(true);
          toastError("unable to run script");
        });
    }
    setQueryStatus({} as InteractiveQueryStatus);
    // setQuery(initialQueryState);
  };
  /////////////////////////Periodic Saving of worksheet version/////////////////////////////////
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    saveWorksheetVersion();
  }, [timer]);

  const MINUTE_MS = 60000;

  const TEN_SECONDS_MS = MINUTE_MS * 6;
  const saveWorksheetVersion = async () => {
    // console.log("content: ", worksheetContent.contents, { worksheetContent, hasQueryChanged });
    if (worksheetContent.contents !== "" && hasQueryChanged) {
      // Encode the String
      const encodedString = encode(worksheetContent.contents);
      console.log({ encodedString });
      await saveWorksheet({
        organizationName,
        worksheet: {
          worksheet_id: worksheetId,
          contents: encodedString,
        },
      })
        .unwrap()
        .then((response) => {
          console.log("saveWorksheetVersion: ", { response });
          setVersions(response);
          setCurrentVersion(response[0].version);
          setHasQueryChanged(false);
          setTimer(0);
        })
        .catch((error) => {
          console.log("saveWorksheetVersion: ", { error });
          // if (worksheetId !== undefined) {
          //   setVersions(VERSIONS);
          //   setCurrentVersion(VERSIONS[0].version);
          // }
          toastError("unable to save worksheet version");
        });
    }
  };

  useEffect(() => {
    // setRunScript(false);
    setStartInterval(false);
    setShowProgress(false);
    setProgress(0);
    // console.log("useParams:", { worksheetId });
    // fetchWorksheet();
    // fetchWarehouses();
    // fetchWorksheetVersions();
    // fetchDatabasesSchemasTables();

    // This will fire only on mount.
    const interval = setInterval(() => {
      setTimer((prevTime) => prevTime + TEN_SECONDS_MS / 1000);
    }, TEN_SECONDS_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveWorksheetVersionWithDelay = debounce(saveWorksheetVersion,5000);
  useEffect(()=>{
    saveWorksheetVersionWithDelay();
    return () => {saveWorksheetVersionWithDelay.clear()};
  },[userQuery]);

  const HALF_SECOND_MS = MINUTE_MS / 12;
  //////////////////***********************////////////////////// */
  // useEffect(() => {
  //   // This will fire only on mount.
  //   console.log({ startInterval, currentIndex, querySubmissionResponse });
  //   if (startInterval && currentIndex < querySubmissionResponse.length) {
  //     setOutPutContent(
  //       <LinearProgressWithLabel
  //         value={progress}
  //         statement={`${currentIndex + 1}/${querySubmissionResponse.length}`}
  //       />
  //     );
  //     const interval = setInterval(async () => {
  //       console.log("runs every half second");
  //       const currentObject = querySubmissionResponse[currentIndex];
  //       if (await checkStatus(currentObject)) {
  //         console.log({ currentIndex, progress });
  //         setCurrentIndex((prevIndex) => prevIndex + 1);
  //         setProgress(
  //           (prevProgress) =>
  //             prevProgress + 100 / querySubmissionResponse.length
  //         );
  //       }
  //     }, HALF_SECOND_MS);

  //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  //   } else if (
  //     startInterval &&
  //     querySubmissionResponse.length > 0 &&
  //     currentIndex === querySubmissionResponse.length
  //   ) {
  //     console.log("after completion", {
  //       startInterval,
  //       currentIndex,
  //       querySubmissionResponse,
  //     });

  //     getResults(querySubmissionResponse[currentIndex - 1]);
  //     // setRunScript(false);
  //     setStartInterval(false);
  //     setShowProgress(false);
  //     setProgress(0);
  //   }
  // }, [startInterval, currentIndex]);
  //////////////////////////////////////////////////////////////////////////

  const FINISHED = "SUCCESS";
  function getRandomNegativeNumber(min: number, max: number) {
    const randomNumber = Math.random() * (max - min) + min;
    return randomNumber;
  }

  const [statusTimer, setStatusTimer] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const randomNegativeNumber = getRandomNegativeNumber(-10, -1);
    setStatusTimer(randomNegativeNumber);
    updateWorksheetName();
  }, [querySubmissionResponse]);
  useEffect(() => {
    const checkStatus = async (currentObject: InteractiveQueryResponse) => {
      await getQueryStatus(currentObject.query_id)
        .unwrap()
        .then((response) => {
          // const status = response.status === FINISHED;
          // if (status) {
          setQueryStatus(response);
          console.log("hhkhkjhkjhkj"+response?.status);
          // }
          // return status;
        })
        .catch((error) => {
          console.log({ error });
          setQueryStatus({} as InteractiveQueryStatus);
          // toastError("unable to check query status");
          // setLoading(false);
        });
    };
    const checkForTheStatus = async () => {
      if (querySubmissionResponse !== undefined) {
        if (queryStatus !== undefined && queryStatus?.status === FINISHED) {
          console.log(FINISHED);
          getResults(querySubmissionResponse);
        } else if (queryStatus !== undefined && queryStatus?.status === 'FAILED') {
          // toastError("error while running your query");
          console.log("hhhjhkj");
          setLoading(false);
          setOutPutContent(<></>);
        } else {
          checkStatus(querySubmissionResponse);
          await new Promise(resolve => setTimeout(resolve, 500));
          setStatusTimer(statusTimer + 1);
        }
      }
    }
    if (statusTimer > 1400) {
      setLoading(false);
      toastError("unable to check query status");
    } else {
      checkForTheStatus();
    }
  }, [statusTimer]);

  const getResults = async (currentObject: InteractiveQueryResponse) => {
    await getQueryResult(currentObject.query_id)
      .unwrap()
      .then((response) => {
        setQueryResults(response);
      })
      .catch((error) => {
        // setQueryResults({
        //   ...defaultResult,
        //   query_id: currentObject.query_id,
        //   query_text: currentObject.query_text,
        //   created_at: currentObject.created_at,
        //   started_at: currentObject.started_at,
        //   columns: ["columns_1", "columns_2", "columns_3", "columns_4"],
        //   rows: [
        //     ["row_1", "row_2", "row_3", "row_4"],
        //     ["row_1", "row_2", "row_3", "row_4"],
        //     ["row_1", "row_2", "row_3", "row_4"],
        //     ["row_1", "row_2", "row_3", "row_4"],
        //   ],
        //   error: "",
        // });
        // console.log({ error });
        // setQueryStatus({} as InteractiveQueryStatus);
        if(currentWarehouse!=="" && currentWarehouse!== undefined && currentWarehouse!==null){
          toastError("unable to get results");
        }
      });
    setLoading(false);
    // setRunScript(true);
  };

  useEffect(() => {
    console.log({ queryStatus, queryResults });
    if (
      queryResults !== undefined &&
      queryResults.columns !== undefined &&
      queryResults.columns.length === 0 &&
      queryStatus !== undefined &&
      queryStatus.status === FINISHED
    ) {
      console.log("zero columns");
      setOutPutContent(
        <TabMessage message={"statement is successfully executed"} />
      );
    }
     else if (
      queryResults !== undefined &&
      queryResults.columns !== undefined &&
      queryResults.columns.length > 0 &&
      queryResults.rows !== undefined &&
      queryResults.rows.length >= 0 &&
      queryStatus.status === FINISHED
    ) {
      console.log("working");
      setOutPutContent(
        <Box sx={{ height: "35vh" }}>
          <VirtuosoTable
            columns={queryResults?.columns.map(col => col?.name)}
            rows={queryResults.rows}
            tableHeight="33vh"
          />
        </Box>
      );
      // if (
      //   queryResults !== undefined &&
      //   queryResults.error !== "" &&
      //   queryStatus.status === FINISHED
      // ) {
      //   console.log("error");
      //   setOutPutContent(<TabMessage message={queryResults.error} />);
      // }
    } else {
      setOutPutContent(<></>);
    }
    // setQueryStatus({} as InteractiveQueryStatus);
  }, [queryResults]);
  /////////////////////////////////CAncelling the query//////////////////////////////////////////////////
  const [cancelQuery] = useCancelQueryMutation();
  const CancelTheScriptRun = async () => {
    if (querySubmissionResponse !== undefined) {
      await cancelQuery(querySubmissionResponse?.query_id)
        .unwrap()
        .then((response) => {
          console.log(response);
          const randomNegativeNumber = getRandomNegativeNumber(-10, -1);
          setStatusTimer(randomNegativeNumber);
        })
        .catch((error) => {
          console.log(error);
          toastError("unable to cancel the query");
        });
    }
  }
  ////////////////////////////////////////BASIC LAYOUT ///////////////////////////////////////
  const layoutCSS = {
    height: '100%',
    // display: 'flex',F
    // alignItems: 'center',
    // justifyContent: 'center'
  };
  const renderSash = (index: number, active: boolean) => {
    return <div className={`custom-sash ${active ? "active" : ""}`} />;
  };
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");

  const boxWidth = isSmallScreen ? "950px" : "100%";

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const defaultLayout = ['18%', 'auto'];
  const defaultSqlEditorLayout = ["60%", 'auto'];
  const rightFullScreen = ["0%", "100%"];
  const leftFullScreen = ["100%", "0%"];
  const [sizes, setSizes] = useState<(string | number)[]>(defaultLayout);
  const [mainSizes, setMainSizes] = useState<(string | number)[]>(defaultSqlEditorLayout);
  const minWidthOfSqlEditor = sizes[0] === "100%" ? "0px" : '870px';
  const minWidthOfContainer = sizes[0] === "100%" ? "0px" : boxWidth;
  const boxStyle = {
    width: "100%",
    height: mainSizes,
    minHeight: "280px",
    background: WHITE,
    borderRadius: "18px",
  };
  const setNewSizes = (sizes: number[]) => {
    setSizes(sizes);
    if (sizes[0] !== 0) {
      setIsSidebarOpen(true);
    }
    if (sizes[0] === 0) {
      setIsSidebarOpen(false);
    }
  }
  const toggleSidebar = () => {
    console.log(sizes);
    if (sizes[0] === "0%" || sizes[0] === "100%") {
      setIsSidebarOpen(() => {
        if (sizes[0] === "100%") { setMainSizes(defaultSqlEditorLayout); }
        setSizes(defaultLayout);
        return true;
      });
    } else {
      setIsSidebarOpen(() => {
        setSizes(isSidebarOpen ? rightFullScreen : defaultLayout);
        return !isSidebarOpen;
      });
    }
  };
  const setOutputToFullScreen = () => {
    if (mainSizes[1] === "100%") {
      setSizes(defaultLayout);
      setMainSizes(defaultSqlEditorLayout);
    } else {
      setSizes(rightFullScreen);
      setMainSizes(rightFullScreen);
    }
  }
  const setSqlEditorToFullScreen = () => {
    if (mainSizes[0] === "100%") {
      setSizes(defaultLayout);
      setMainSizes(defaultSqlEditorLayout);
    } else {
      setSizes(rightFullScreen);
      setMainSizes(leftFullScreen);
    }
  }
  const setSidebarToFullScreen = () => {
    if (sizes[0] === "100%") {
      setSizes(defaultLayout);
      setMainSizes(defaultSqlEditorLayout);
    } else {
      setSizes(leftFullScreen);
    }
  }
  const rightArrowColor = sizes[0] === "100%" ? "blue" : "#676780";
  const bottomArrowColor = mainSizes[0] === "100%" ? "blue" : "#676780";
  const topArrowColor = mainSizes[1] === "100%" ? "blue" : "#676780";

  useEffect(() => {
    if (editorRef !== undefined || editorRef !== null) {
      editorRef?.resize();
    }
  }, [sizes, mainSizes])
  /////////////////////////////Format of "time" of querying///////////////////////////////////////////////////

  const formatAsPerTimer = (seconds: number) => {
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }

  const dropdownWidth_1 = { width: 0.98 };
  return (
    <Box height="100vh" sx={{ background: "#FBFBFF", minWidth: minWidthOfContainer }} >

      <SplitPane
        split="vertical"
        sizes={sizes}
        onChange={(sizes) => { setNewSizes(sizes) }}
        sashRender={renderSash}
      >
        <Pane minSize={'150px'} maxSize={'40%'} style={{ paddingRight: "2px" }}>
          <div style={{ ...layoutCSS, background: "#FBFBFF", boxShadow: "2px 0px 2px rgba(0, 0, 0, 0.2)" }}>
            <CustomizedTreeView>
              <IconButton onClick={toggleSidebar} ><KeyboardArrowLeft /></IconButton>
            </CustomizedTreeView>
          </div>
        </Pane>
        <Pane style={{ minWidth: minWidthOfSqlEditor }}>
          <Stack
            direction={"column"}
            width="100%"
            justifyContent={"flex-start"}
            alignItems="flex-start"
            spacing={1}
            padding={"10px"}
          >
            <Stack
              direction={"row"}
              width="100%"
              justifyContent={"space-between"}
              alignItems="center"
              spacing={1}
            >
              <Stack
                direction={"row"}
                width="50%"
                justifyContent={"flex-start"}
                alignItems="center"
                spacing={1}
              >
                <Typography className="text-common-styles" variant="body2" paddingLeft="10px">
                  Last edited {formatAsPerTimer(timer)}
                </Typography>
                <IconButton onClick={setSidebarToFullScreen} style={{ color: rightArrowColor }}><DockRightIcon color={rightArrowColor} /></IconButton>
                <IconButton onClick={setSqlEditorToFullScreen} style={{ color: bottomArrowColor }}><DockBottomIcon color={bottomArrowColor} /></IconButton>
                <IconButton onClick={setOutputToFullScreen} style={{ color: topArrowColor }} ><DockTopIcon color={topArrowColor} /></IconButton>
                {/* <CssTextField
                  disabled={!worksheetNameEditable}
                  id="worksheet_name"
                  name="worksheet_name"
                  value={worksheet.worksheet_name}
                  onChange={handleChange}
                  error={worksheetDetailError.worksheet_name}
                  helperText={
                    worksheetDetailError.worksheet_name
                      ? "Please enter your worksheet name."
                      : ""
                  }
                  variant="standard"
                // sx={{ minWidth: "150px" }}
                /> */}
                {/* {worksheetNameEditable ? (
                  <IconOnButton
                    handleClick={() => updateWorksheetName()}
                    materialIcon={<SaveIcon />}
                  />
                ) : (
                  <IconOnButton
                    handleClick={() => setWorksheetNameEditable(true)}
                    materialIcon={<EditIcon />}
                  />
                )} */}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
                width={"40%"}
              >
                <Profile />
              </Stack>
            </Stack>
          </Stack>
          <SplitPane
            split="horizontal"
            sizes={mainSizes}
            onChange={setMainSizes}
            sashRender={renderSash}
            style={{ padding: "10px" }}
          >
            <Box
              sx={{
                background: WHITE,
                borderRadius: "18px",
                marginTop: "0 !important",
                padding: "5px",
                boxShadow: "0 0 1px inset rgba(103, 103, 103, 0.5)",
                marginBottom: "5% !important",
                overflow: "hidden",
                ...layoutCSS
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                margin={"5px"}
                padding={"5px 0"}
              >
                <Stack
                  direction={"column"}
                  width="20%"
                  justifyContent={"flex-start"}
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Typography className="sql-editor-header">
                    {worksheet.worksheet_name}
                  </Typography>
                </Stack>
                {!isSidebarOpen && <IconButton onClick={toggleSidebar}><SearchIcon /></IconButton>}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems='flex-end'
                  spacing={1}
                  width={"70%"}
                // minWidth="550px"
                >
                  <TextField
                    id="outlined-select-a-catalog"
                    name="catalog"
                    select
                    label="Catalog"
                    defaultValue=""
                    value={currentCatalog}
                    onChange={handleChange}
                    sx={dropdownWidth_1}
                    size="small"
                    onMouseOver={getCatalogs}
                  >
                    {(catalogs?.length > 0) ? (
                      catalogs.map((catalog) => (
                        <MenuItem key={catalog?.name} value={catalog?.name}>
                          {catalog?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key={"0"} value={""}>
                        {"There are no catalogs"}
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    id="outlined-select-a-database"
                    name="database"
                    select
                    label="Database"
                    defaultValue=""
                    value={database}
                    onChange={handleChange}
                    sx={dropdownWidth_1}
                    size="small"
                    onMouseOver={GetDatabasesList}
                  >
                    {(databases?.length > 0) ? (
                      databases.map((database) => (
                        <MenuItem key={database?.name} value={database?.name}>
                          {database?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key={"0"} value={""}>
                        {"There are no databases"}
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    id="outlined-select-a-warehouse"
                    name="warehouse"
                    select
                    label="Warehouse"
                    defaultValue=""
                    value={currentWarehouse}
                    onChange={handleChange}
                    sx={dropdownWidth_1}
                    size="small"
                  >
                    {warehouses?.length > 0 ? (
                      warehouses.map((warehouse) => (
                        <MenuItem key={warehouse.id} value={warehouse.id}>
                          {`${warehouse.name} - `} <span style={{ fontSize: "10px" }}>{warehouse.size}</span>
                          {/* {warehouse.name} */}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key={"0"} value={""}>
                        {"There are no warehouses"}
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    id="outlined-select-a-version"
                    name="version"
                    select
                    label="Version"
                    defaultValue=""
                    value={currentVersion}
                    onChange={handleChange}
                    sx={{ width: 0.75 }}
                    size="small"
                  >
                    {(versions?.length > 0) ? (
                      versions.map(({ version }) => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem key={"0"} value={""}>
                        {"There are no versions"}
                      </MenuItem>
                    )}
                  </TextField>
                  <Box paddingRight="10px">
                    {((onFirstEnteringPage) && (queryStatus?.status === "SUBMITTED" || loading)) ?
                      <TextButton
                        handleClick={CancelTheScriptRun}
                        buttonText={"Cancel"}
                        variant={"contained"}
                        fullWidth={false}
                        disabled={
                          false
                        }
                        width={"100px"}
                        color={"red"}

                      />
                      :
                      <TextButton
                        handleClick={runQuery}
                        buttonText={"Run Script"}
                        variant={"contained"}
                        fullWidth={false}
                        disabled={
                          worksheet.worksheet_name === "" ||
                          currentWarehouse === "" || currentWarehouse === undefined ||

                          currentCatalog === "" || currentCatalog === undefined ||
                          database === "" || database === undefined ||
                          (queryStatus.status === "SUBMITTED")
                        }
                        width={"100px"}
                      />}
                  </Box>
                </Stack>
              </Stack>
              <Divider />
              {loading && <LinearProgress />}
              <Box
                sx={{
                  height: "calc(100% - 60px)",
                  overflowY: "auto",
                }}
              >
                <AceEditor
                  className="sqlEditor"
                  width="100%"
                  height="100%"
                  mode="mysql"
                  theme="github"
                  onChange={onChange}
                  value={worksheetContent.contents}
                  name="sql_editor"
                  editorProps={{ $blockScrolling: false }}
                  onSelectionChange={handleSelectionChange}
                  highlightActiveLine={true}
                  showPrintMargin={true}
                  showGutter={true}
                  fontSize={18}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  onLoad={editorInstance => {
                    editorInstance.container.style.resize = "both";
                    setEditorRef(editorInstance);
                  }}
                />
              </Box>
            </Box>
            <Pane minSize={'30%'} style={{ paddingRight: "2px", borderRadius: "18px" }}>
              <Box
                sx={{
                  background: WHITE,
                  ...layoutCSS,
                }}
              >
                <Tabs
                  tabValue={tabValue}
                  tabValueChange={tabValueChange}
                  tabContents={tabContent}
                  tabHeight="33vh"
                  divider={true}
                />
              </Box>
            </Pane>
          </SplitPane>
        </Pane>
      </SplitPane>
    </Box>
  );
};
