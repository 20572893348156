import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { DataGridTable } from "../DataGridTable";
import { User_Admin, Role } from "../models";
import { useState, Fragment, useEffect } from "react";
import { MoreHoriz } from "@mui/icons-material";
import { LoadingPage } from "../../loader/LoadingPage";

type ModifiedUsers = {
  organizationId: string;
  name: string;
  email: string;
  userId: string;
  rolesLst: Role[];
  roles: React.ReactNode;
  options: React.ReactNode;
};

type People = {
  roles: Role[];
  users: ModifiedUsers[];
};

export const PeopleTable = ({
  users,
  roles,
}: {
  users: User_Admin[] | undefined;
  roles: Role[] | undefined;
}) => {
  const [people, setPeople] = useState<People>();

  const MenuOptions = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div
        style={{
          width: '170px',
          display: 'flex',
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openMenu ? "long-menu" : undefined}
          aria-expanded={openMenu ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHoriz />
        </IconButton>
        {(process.env.REACT_APP_ENV === "dev") &&
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem>Delete User</MenuItem>
          </Menu>
        }
      </div>
    );
  };

  const formatUsers = () => {
    if ((users !== undefined && users.length > 0) && (roles !== undefined && roles.length > 0)) {
      const formattedUsers: ModifiedUsers[] = users.map(
        ({ email, name, organizationId, userId, rolesLst }) => {
          return {
            email,
            name,
            organizationId,
            userId,
            rolesLst,
            roles: (
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={roles}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                isOptionEqualToValue={(option: Role, value: Role) =>
                  option.id === value.id
                }
                defaultValue={rolesLst}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        minWidth: '1px',
                      },
                    }}
                    sx={{
                      borderRadius: 1,
                      margin: 1,
                      width: '100%',

                    }}
                  />
                )}
                sx={{ width: "500px" }}
              />
            ),
            options: <MenuOptions />,
          };
        }
      );
      setPeople({
        roles,
        users: formattedUsers,
      });
    }
  };

  useEffect(() => {
    formatUsers();
  }, []);
  useEffect(() => {
    console.log({ people });
  }, [people]);

  return (
    <Fragment>
      <LoadingPage isLoading={people === undefined} />
      {people !== undefined && (
        <DataGridTable
          data={people.users}
          handleRowClick={() => { }}
          mainId="userId"
          skipKeys={["roles", "userId", "rolesLst"]}
          renderCells={["options"]}
          table="admin"
          showSearch={true}
        />
      )}
    </Fragment>
  );
};
