import { Button, Typography, Stack } from "@mui/material";
import { MouseEvent } from 'react';
import './TextButton.scss';

interface ButtonProps {
  handleClick: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void),
  buttonText: string,
  variant: "text" | "outlined" | "contained" | undefined,
  fullWidth: boolean,
  disabled: boolean,
  materialIcon?: React.ReactNode,
  width?: number | string,
  color?: string
}

export const TextButton: React.FC<ButtonProps> = ({ handleClick, buttonText, variant, fullWidth, disabled: isDisabled, materialIcon, width,color }) => {
  return (
    <Button
      className='button-01'
      variant={variant}
      onClick={handleClick}
      fullWidth={fullWidth}
      disabled={isDisabled}
      sx={{ width: width, backgroundColor:color }}
    >
      <Stack direction={'row'} justifyContent={"center"} alignContent={"center"} spacing={2}>
        <Typography
          className='button-text-01 text-common-styles'
          variant="button"
          display="flex"
          gutterBottom>
          {buttonText}
        </Typography>
        {materialIcon}
      </Stack>
    </Button>
  );
}