import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTablesApi } from "../../api/schemaApi";
import { RootState } from "../../app/store";

export interface GetTableState {
  tables: string[];
  error: string;
  status: "idle" | "loading" | "failed";
}

const initialState: GetTableState = {
  tables: [],
  error: "",
  status: "idle",
};

export const getTables = createAsyncThunk(
  "worksheet/getTables",
  async (schema: string) => {
    try {
      const response = await getTablesApi(schema).then(
        (response) => {
          return response;
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const GetTableSlice = createSlice({
  name: "tableDef",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTables.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTables.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload !== null && action.payload !== undefined) {
          if (action.payload.status === 200) {
            state.tables = action.payload.data.tables;
            state.error = "";
          } else if (
            action.payload.code &&
            action.payload.code === "ERR_NETWORK"
          ) {
            state.tables = initialState.tables;
            state.error = action.payload.message;
          }
        }
      })
      .addCase(getTables.rejected, (state) => {
        state.tables = initialState.tables;
        state.status = "failed";
      });
  },
});

export const { reset: resetGetTableState } = GetTableSlice.actions;

export const getTableState = (state: RootState) => state.getTables;

export default GetTableSlice.reducer;
