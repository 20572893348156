import configJsonDev from "./auth_config_dev.json";
import configJsonStage from "./auth_config_stage.json";
import configJsonMain from "./auth_config_main.json";

export function getConfig() {
  const env=process.env.REACT_APP_ENV;
  console.log("env"+env);
  let configJson = {};
  if(env ==="app") {
    configJson = configJsonMain;
  } else if (env ==="stage") {
    configJson = configJsonStage;
  } else {
    configJson = configJsonDev;
  }
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
    redirectUrl: configJson.redirectUrl
  };
}
