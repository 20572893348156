import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryObj, dynamicBaseQuery } from "./fetchBaseQueryObj";
import {
  Warehouse,
  DataWarehouse,
  DataWarehouseModel,
  DataWarehouseCreateModel,
  DataWarehouseUpdateModel,
} from "../components/models";
import { VEGA_GLOBAL, API_VERSION } from "../components/models/constants";

type WarehouseResponse = {
  data: Warehouse;
};

type WarehousesFetchReq = {
  // organizationName: string;
  workspace_id: string | undefined;
};

type WarehouseUpdateReq = {
  warehouse_id: string | undefined;
  warehouse: DataWarehouseUpdateModel;
};

const WAREHOUSE = "warehouses";
const DATA_WAREHOUSE = "vegadb/datawarehouse/";

// export const warehouseApi = createApi({
//   reducerPath: "warehousePath",
//   baseQuery: fetchBaseQueryObj,
//   endpoints: (builder) => ({
//     postWarehouse: builder.mutation<WarehouseResponse, Warehouse>({
//       query: (dataWarehouse) => ({
//         url: `${VEGA_GLOBAL}${WAREHOUSE}launchWarehouse`,
//         method: "POST",
//         body: dataWarehouse,
//       }),
//       transformResponse: (response: WarehouseResponse, _meta, _arg) => response,
//       transformErrorResponse: (
//         response: { status: string | number },
//         _meta,
//         _arg
//       ) => response.status,
//     }),
//     getWarehouses: builder.mutation<DataWarehouse[], WarehousesFetchReq>({
//       query: ({ organizationName, workspace_id }) => ({
//         url: `${VEGA_GLOBAL}${WAREHOUSE}getWarehouses/${workspace_id}/${organizationName}`,
//         method: "GET",
//       }),
//       transformResponse: (response: DataWarehouse[], _meta, _arg) => response,
//       transformErrorResponse: (
//         response: { status: string | number },
//         _meta,
//         _arg
//       ) => response.status,
//     }),
//   }),
// });
// export const {
//   usePostWarehouseMutation,
//   useGetWarehousesMutation,
// } = warehouseApi;

export const warehouseRegionalApi = createApi({
  reducerPath: "warehouseRegionPath",
  baseQuery: dynamicBaseQuery,
  // tagTypes: ['warehouses'],
  endpoints: (builder) => ({
    getWarehouse: builder.query<DataWarehouseModel, string>({
      query: (warehouseId) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}/${warehouseId}`,
        method: "GET",
      }),
      transformResponse: (response: DataWarehouseModel, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    createWarehouse: builder.mutation<
      DataWarehouseModel,
      DataWarehouseCreateModel
    >({
      query: (dataWarehouse) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}`,
        method: "POST",
        body: dataWarehouse,
      }),
      // invalidatesTags: ['warehouses'],
      transformResponse: (response: DataWarehouseModel, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    updateWarehouse: builder.mutation<DataWarehouseModel, WarehouseUpdateReq>({
      query: ({ warehouse_id, warehouse }) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}/${warehouse_id}`,
        method: "PUT",
        body: warehouse,
      }),
      // invalidatesTags: ['warehouses'],
      transformResponse: (response: DataWarehouseModel, _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    deleteWarehouse: builder.mutation<void, string>({
      query: (warehouse_id) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}/${warehouse_id}`,
        method: "DELETE",
      }),
      // invalidatesTags: ['warehouses'],
      transformResponse: (response: void, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getWarehouseList: builder.query<DataWarehouseModel[], WarehousesFetchReq>({
      query: () => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}`,
        method: "GET",
      }),
      // providesTags: ['warehouses'],
      transformResponse: (response: DataWarehouseModel[], _meta, _arg) =>
        response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    startWarehouse: builder.mutation<void, string>({
      query: (warehouse_id) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}/${warehouse_id}/start`,
        method: "POST",
      }),
      // invalidatesTags: ['warehouses'],
      transformResponse: (response: void, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    stopWarehouse: builder.mutation<void, string>({
      query: (warehouse_id) => ({
        url: `${API_VERSION}${DATA_WAREHOUSE}${WAREHOUSE}/${warehouse_id}/stop`,
        method: "POST",
      }),
      // invalidatesTags: ['warehouses'],
      transformResponse: (response: void, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
  }),
});

export const {
  useCreateWarehouseMutation,
  useDeleteWarehouseMutation,
  useGetWarehouseListQuery,
  useGetWarehouseQuery,
  useStartWarehouseMutation,
  useStopWarehouseMutation,
  useUpdateWarehouseMutation,
} = warehouseRegionalApi;
