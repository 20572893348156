import { FormControl, TextField } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import { TextButton } from "../buttons/TextButton";
import { DialogComponent } from "../DialogComponent";

type CreateFolderProps = {
  folderName: string;
  setFolderName: Dispatch<SetStateAction<string>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  createFolder: () => void;
  isLoading:boolean;
};

export const CreateFolder: React.FC<CreateFolderProps> = ({
  folderName,
  setFolderName,
  open,
  setOpen,
  createFolder,
  isLoading
}) => {
  const [folderNameError, setFolderNameError] = useState(false);

  const handleClose = () => {
    setFolderName("");
    setFolderNameError(false);
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setFolderName(value);
    setFolderNameError(value === "");
  };

  const FolderDialogContent = () => {
    return (
      <FormControl component="fieldset">
        {/* <FormLabel id='folder_name'>Folder Name</FormLabel> */}
        <TextField
          autoFocus
          margin="normal"
          id="folder_name"
          label="Title"
          name="folder_name"
          placeholder="Ex: Finance"
          value={folderName}
          onChange={handleChange}
          type="text"
          fullWidth
          variant="outlined"
          error={folderNameError}
          helperText={folderNameError ? "Please enter your Folder name." : ""}
        />
      </FormControl>
    );
  };

  const FolderDialogActions = () => {
    return (
      <Fragment>
        <TextButton
          handleClick={handleClose}
          buttonText={"Cancel"}
          variant={"outlined"}
          fullWidth={false}
          disabled={false}
        />
        <TextButton
          handleClick={createFolder}
          buttonText={"Continue"}
          variant={"contained"}
          fullWidth={false}
          disabled={folderName === ""}
        />
      </Fragment>
    );
  };

  return (
    <DialogComponent
      open={open}
      title="Create Folder"
      handleClose={handleClose}
      dialogContent={<FolderDialogContent />}
      dialogActions={<FolderDialogActions />}
      isLoading={isLoading}
    />
  );
};
