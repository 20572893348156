import { Typography } from '@mui/material';
// import { useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { Worksheet } from './Worksheet';
import { FoldersWorksheetTree } from './FoldersWorksheetTree';
import { DISPLAY_WORKSHEET } from '../models/constants';


export const Worksheets = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<FoldersWorksheetTree />} />
        <Route path={`${DISPLAY_WORKSHEET}/:worksheetId`} element={<Worksheet />} />
      </Routes>
    </div>
  )
}
