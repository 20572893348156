import { useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { resetLoginAuthState } from './components/onBoarding/LoginAuthSlice';
import { resetWorkspaceState } from './components/Workspace/WorkspaceSlice';
import { resetWorkspaceListState } from './components/Workspace/WorkspaceListSlice';

const SessionChecker = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();

    const handleLogout = useCallback(() => {
        localStorage.removeItem('organizationId');

        dispatch(resetLoginAuthState());
        dispatch(resetWorkspaceListState());
        dispatch(resetWorkspaceState());
        logout();
    }, [dispatch, logout]);

    useEffect(() => {
        const checkSession = async () => {
            try {
                // Attempt to silently get the token
                await getAccessTokenSilently();
                console.log('Session is valid');
            } catch (error: any) {
                // Handle token expiration or any other error
                if (error?.error === 'login_required' || error?.error === 'consent_required') {
                    handleLogout();
                }
            }
        };

        const intervalId = setInterval(() => {
            checkSession();
        }, 60000); // Check every minute

        return () => clearInterval(intervalId);
    }, [getAccessTokenSilently, handleLogout]);

    return null;
};

export default SessionChecker;
