import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DataWarehouseModel } from "../models";
import type { PayloadAction } from "@reduxjs/toolkit";

const warehouse = {} as DataWarehouseModel;
const warehouses: DataWarehouseModel[] = [];

const initialState = {
  warehouse,
  warehouses,
};

const WarehouseSlice = createSlice({
  name: "warehouses",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setWarehouse: (state, action: PayloadAction<DataWarehouseModel>) => {
      state.warehouse = action.payload;
    },
    setWarehouses: (state, action: PayloadAction<DataWarehouseModel[]>) => {
      state.warehouses = action.payload;
    },
  },
});

export const {
  reset: resetWarehouseState,
  setWarehouse,
  setWarehouses,
} = WarehouseSlice.actions;

export const warehouseState = (state: RootState) => state.warehouse;

export default WarehouseSlice.reducer;
