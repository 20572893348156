import { SvgIcon, SvgIconProps } from "@mui/material"

export const DatabaseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M 0 5 C -1 11 24 11 23 5 C 22 1 1 1 0 5 M 0 15 C 2 18 21 18 23 15 C 24 21 -1 21 0 15 M 0 10 C 2 13 21 13 23 10 C 24 16 -1 16 0 10" />
    </SvgIcon>
  );
}

interface IconProps {
  color: "blue" | "#676780";
}
export const DockTopIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M3 5v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2zm2 14v-9h14.001l.001 9H5z" />
    </svg>
  );
};

export const DockRightIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="mdi-dock-right"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M15 18H4V6H15Z"
      />
    </svg>
  );
};

export const DockBottomIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="mdi-dock-bottom"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 13H4V6H20Z"
      />
    </svg>
  );
};