import { Box, Stack, Typography } from '@mui/material';
import { TextButton } from './buttons/TextButton';
import Image from './Image';

interface EmptyPageProps {
  title: string,
  body: string,
  button: string,
  handleOpen: () => void,
  image: string,
  altImage: string
}

export const EmptyPage = ({ title, body, button, handleOpen, image, altImage }: EmptyPageProps) => {
  return (<Box className='blankWorkspace'>
    <Stack className='blankWorkspace centered'
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={1}>
      <Image src={`${image}.svg`} alt={altImage} />
      <Typography className='title-bold text-common-styles' gutterBottom>
        {title}
      </Typography>
      <Typography
        className='paragraph-03 text-common-styles'
        variant='body1'
        paragraph gutterBottom>
        {body}
      </Typography>
      <TextButton handleClick={handleOpen} buttonText={button} variant={'contained'} fullWidth={false}
        disabled={false} />
    </Stack>
  </Box >);
}