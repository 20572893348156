import { Visibility, VisibilityOff } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

interface PasswordConditionProps {
  conditionIcon: boolean,
  passwordCondition: string
}

const PasswordValidationIcon = ({ conditionIcon, passwordCondition }: PasswordConditionProps) => {
  return (<Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    spacing={2}>
    {!conditionIcon && <CheckCircleIcon className="passwordConditionMatchIcon" />}
    {conditionIcon && <CancelIcon className="passwordErrorIcon" />}
    <Typography className="passwordError">{passwordCondition}</Typography>
  </Stack>);
}

interface PasswordProps {
  password: string,
  setPassword: Dispatch<SetStateAction<string>>,
  setIsDisabled: Dispatch<SetStateAction<boolean>>
}

export const Password = ({ password, setPassword, setIsDisabled }: PasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const [inputError, setInputError] = useState({
    emptyPassword: false,
    shortPassword: true,
    weakPassword: true,
  });

  const passwordConditions = {
    emptyPassword: "A password is required to create an account.",
    shortPassword: "Please make sure your password is minimum 8 characters long.",
    weakPassword: "Contains minimum 1 Capital letter (A-Z), 1 special character (!-~) and 1 Number (0-9)."
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validatePassword = (password: string) => {
    console.log(inputError.emptyPassword);

    setPassword(password);
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    setInputError((prevState)=>{
      setIsDisabled((password.length === 0) || (password.length < 8) || !strongRegex.test(password));
      return {
      ...prevState,
      emptyPassword: password.length === 0,
      shortPassword: password.length < 8,
      weakPassword: !strongRegex.test(password)
    };
  });
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        name="password"
        value={password}
        placeholder="Enter your password"
        onChange={(e) => validatePassword(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop='1.5%'
        spacing={2}>
        <Typography className="error" display={inputError.emptyPassword ? "block" : "none"}>{passwordConditions.emptyPassword}</Typography>
        <PasswordValidationIcon conditionIcon={inputError.shortPassword} passwordCondition={passwordConditions.shortPassword} />
        <PasswordValidationIcon conditionIcon={inputError.weakPassword} passwordCondition={passwordConditions.weakPassword} />
      </Stack>
    </FormControl >
  );
};
