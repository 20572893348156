import React, { useState} from "react";
import { loginAuthState } from "../onBoarding/LoginAuthSlice";
import { useAppSelector } from "../../app/hooks";
import { Address, stripeCustomerModel } from "../models";
import { useInitiateSubscriptionMutation, useInitiateSubscriptionUpdateMutation } from "../../api/billingApi_rtk";
import { toastError} from "../Toasts";
import {
    AddressElement
} from "@stripe/react-stripe-js";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { Box } from "@mui/system";
import { Grid, TextField, Typography } from "@mui/material";
import { Elements } from "./Elements";

type formErrortype = {
    email: boolean;
    addressEntry: boolean;
}
type cardAndCompanyDetails = {
    email: string;
}
type showComponent = {
    setShowComponent: React.Dispatch<React.SetStateAction<boolean>>
    showComponent: boolean;
    type:string;
}

export const PaymentMethodsInitiationAndUpdation: React.FC<showComponent> = ({ setShowComponent, showComponent,type }) => {
    const companyDetails = useAppSelector(loginAuthState);
    const [cardHolderName, setCardHolderName] = useState<string>("");
    const [addressState, setAddressState] = useState<Address>({
        line1: "",
        line2: "",
        city: "",
        country: "",
        state: "",
        postal_code: ""
    });
    const [cardDetails, setCardDetails] = useState<cardAndCompanyDetails>({
        email: "",
    });
    const [clientSecret, setClientSecret] = useState<string>();
    const [customerModel, setCustomerModel] = useState<stripeCustomerModel>({});
    const [subscription, { isLoading:isInitialSubscriptionLoading }] = useInitiateSubscriptionMutation();
    const [updateSubscription, { isLoading:isUpdateSubscriptionLoading }] = useInitiateSubscriptionUpdateMutation();
    const [formError, setFormError] = useState<formErrortype>({
        email: true,
        addressEntry: true
    });
    const initiateSubscription = type==="subscribe" ? subscription : updateSubscription;
    const isLoading = type ==="subscribe"? isInitialSubscriptionLoading : isUpdateSubscriptionLoading;
    const setUpIntentGenerator = async () => {
        if (addressState.line1 !== "" && !formError.email && !formError.addressEntry) {
            const customer: stripeCustomerModel = {
                tenantId: companyDetails.organizationName,
                email: cardDetails.email,
                card_name_and_billing_address: {},
                subscription_id: "",
                stripe_payment_intent_id: "",
                stripe_payment_intent_secret: "",
                stripe_payment_method_id: "",
                stripe_customer_id: ""
            }
            await initiateSubscription(customer).unwrap()
                .then((response) => {
                    setClientSecret(response.stripe_payment_intent_secret);
                    setCustomerModel(response);
                    // toastSuccess("enter your card Details");
                })
                .catch((error) => {
                    console.log({ error })
                    toastError("internal error");
                }
                );
        }
    }
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setCardDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (cardDetails.email !== "") {
            const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!regex.test(cardDetails.email)) {
                setFormError({
                    ...formError,
                    email: false
                })
            }

        }
    };

    const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
        const { value } = event;
        setAddressState({
            line1: value.address.line1,
            line2: value.address.line2,
            city: value.address.city,
            country: value
                .address.country,
            state: value.address.state,
            postal_code: value.address.postal_code
        });
        setCardHolderName(value.name);
        if (cardHolderName !== "" &&
            addressState.line1 !== "") {
            setFormError({
                ...formError,
                addressEntry: false
            })
        }
    }

    return (
        <>
            <Box>
                <Grid container spacing={2}
                    xs={11.5}
                    sx={{
                        backgroundColor: '#EEEEEE',
                        borderRadius: "10px",
                        margin: "10px auto",
                        padding: "15px",
                        position: "relative",
                    }}>

                    <Grid item xs={6}>
                        <Box>

                            <Box >
                                <Typography variant="body2" sx={{ color: "#794EFF", marginBottom: "1rem" }}>
                                    1. BILLING ADDRESS
                                </Typography>
                                <AddressElement options={{
                                    mode: "billing"
                                }} onChange={handleAddressChange} />
                                <Box sx={{ marginTop: "1rem" }}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        value={cardDetails.email}
                                        onChange={handleChange}
                                        required
                                        fullWidth
                                        onBlur={setUpIntentGenerator}

                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item xs={5.5}>
                        <Elements
                            email={cardDetails.email}
                            addressState={addressState}
                            customerModel={customerModel}
                            setCustomerModel={setCustomerModel}
                            setClientSecret={setClientSecret}
                            cardHolderName={cardHolderName}
                            setShowComponent={setShowComponent}
                            type={type}
                        />
                        </Grid>

                </Grid>
            </Box>


        </>
    );
}