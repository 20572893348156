import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "./fetchBaseQueryObj";
import { CatalogOrDatabasesOrTablesOrViews,SchemaOfTableOrView } from "../components/models";

const BASE_URL = "/api/v1/vegadb/datawarehouse/sql/catalogs";

export const schemaApi = createApi({
    reducerPath: "schemaPath",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
      getCatalogs: builder.query<CatalogOrDatabasesOrTablesOrViews[],void>({
        query: () => ({
          url: `${BASE_URL}`,
          method: "GET",
        }),
        transformResponse: (response: CatalogOrDatabasesOrTablesOrViews[], _meta, _arg) => response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }),
      getDatabases: builder.mutation<CatalogOrDatabasesOrTablesOrViews[],string>({
        query: (catalogId) => ({
          url: `${BASE_URL}/${catalogId}/databases`,
          method: "GET",
        }),
        transformResponse: (response: CatalogOrDatabasesOrTablesOrViews[], _meta, _arg) =>
          response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }),
      getTables: builder.mutation<CatalogOrDatabasesOrTablesOrViews[],{catalogId:string,databaseId:string}>({
        query: ({catalogId,databaseId}) => ({
          url: `${BASE_URL}/${catalogId}/databases/${databaseId}/tables`,
          method: "GET",
        }),
        transformResponse: (response: CatalogOrDatabasesOrTablesOrViews[], _meta, _arg) =>
          response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }), 
      getSchema: builder.mutation<SchemaOfTableOrView[],{catalogId:string,databaseId:string,tableId:string}>({
        query: ({catalogId,databaseId,tableId}) => ({
          url: `${BASE_URL}/${catalogId}/databases/${databaseId}/tables/${tableId}/schema`,
          method: "GET",
        }),
        transformResponse: (response: SchemaOfTableOrView[], _meta, _arg) =>
          response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }),
      getviews: builder.mutation<CatalogOrDatabasesOrTablesOrViews[],{catalogId:string,databaseId:string}>({
        query: ({catalogId,databaseId}) => ({
          url: `${BASE_URL}/${catalogId}/databases/${databaseId}/views`,
          method: "GET",
        }),
        transformResponse: (response: CatalogOrDatabasesOrTablesOrViews[], _meta, _arg) =>
          response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }), 
      getViewSchema: builder.mutation<SchemaOfTableOrView[],{catalogId:string,databaseId:string,viewId:string}>({
        query: ({catalogId,databaseId,viewId}) => ({
          url: `${BASE_URL}/${catalogId}/databases/${databaseId}/views/${viewId}/schema`,
          method: "GET",
        }),
        transformResponse: (response: SchemaOfTableOrView[], _meta, _arg) =>
          response,
        transformErrorResponse: (
          response: { status: string | number },
          _meta,
          _arg
        ) => response.status,
      }),                
    }),
  });
  
  export const {
    useGetCatalogsQuery,
    useGetDatabasesMutation,
    useGetTablesMutation,
    useGetSchemaMutation,
    useGetviewsMutation,
    useGetViewSchemaMutation
  } = schemaApi;