import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Workspace } from "../models";
import type { PayloadAction } from "@reduxjs/toolkit";

type WorkspaceData = {
  workspace?: Workspace;
};

const workspace: WorkspaceData = {
  workspace: undefined,
};

const WorkspaceSlice = createSlice({
  name: "workspace",
  initialState: workspace,
  reducers: {
    reset: () => workspace,
    setState: (state, action: PayloadAction<Workspace>) => {
      state.workspace = action.payload;
    },
  },
});

export const { reset: resetWorkspaceState, setState: setWorkspace } =
  WorkspaceSlice.actions;

export const workspaceState = (state: RootState) => state.workspace;

export default WorkspaceSlice.reducer;
