import {
  LinearProgressProps,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LAVENDER_VIOLET } from "../components/models/constants";

export const LoadingPage = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; statement?: string; }
) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: '100%'
      }}
    >
      <Box sx={{ width: "80%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {props.statement !== undefined &&
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color={LAVENDER_VIOLET}>{props.statement}</Typography>
        </Box>}
    </Box>
  );
};
