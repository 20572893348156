import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Box,
} from "@mui/material";

export interface DialogComponentProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  dialogContent: React.ReactNode;
  dialogActions: React.ReactNode;
  isLoading:boolean;
}

export const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  handleClose,
  title,
  dialogContent,
  dialogActions,
  isLoading
}) => {
  return (
    <Dialog className="form-dialog" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      {isLoading&&<LinearProgress/>}
      <DialogActions sx={{ padding: "20px 24px", paddingTop: "unset" }}>
        {dialogActions}
      </DialogActions>
    </Dialog>
  );
};
