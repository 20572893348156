import { Auth0Provider } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { App } from "./App";
import { store, persistor } from './app/store';
import { getConfig } from "./config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LAVENDER_VIOLET } from "./components/models/constants";
import "./index.scss";
import React from "react";

Sentry.init({
  dsn: "https://8380598f575d48b5bfca562fe4740f5f@o4504808375255040.ingest.sentry.io/4504811578589184",
  integrations: [new BrowserTracing({
    tracePropagationTargets: ["dev.vegalake.com", "app.vegalake.com", "dev.aws.api.vegalake.com", "*.dev.aws.api.vegalake.com", "127.0.0.1"],
  })],
  environment: process.env.REACT_APP_SENTRY_ENV_DEV,
  tracesSampleRate: 1.0,
  enableTracing: true
});

const config = getConfig();
export const OrganizationContext: React.Context<string | undefined> = React.createContext<string | undefined>(undefined);

export const Auth0ProviderWithOrganization = (props: { children: React.ReactNode }) => {
  // Retrieve organization ID from localstorage
  const organizationId = localStorage.getItem('organizationId') || undefined;

  return (
    <OrganizationContext.Provider value={organizationId}>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        authorizationParams={{
          audience: config.audience,
          redirect_uri: config.redirectUrl,
          organization: organizationId
        }}
      >
        {props.children}
      </Auth0Provider>
    </OrganizationContext.Provider>
  );
};


const appTheme = createTheme({
  palette: {
    primary: {
      main: LAVENDER_VIOLET,
    },
  },
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Auth0ProviderWithOrganization>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <ThemeProvider theme={appTheme}>
            <App />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </Auth0ProviderWithOrganization>
);
