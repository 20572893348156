import { DatabaseTableType } from "../components/models";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_NEW_TESTING_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        console.log("Unauthorized");
      }
      if (error.response.status === 404) {
        console.log("Page not found");
      } else if (error.response.status === 500) {
        console.log("Internal error", error.response.data.message);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);

export const getSchemaApi = () => {
  return instance
    .get("/metadata/tenant5/fetchschemas")
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const getTablesApi = (database: string) => {
  return instance
    .get(`/metadata/tenant5/fetchTables-by-schema/${database}`)
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const getTableDefApi = ({ table, database }: DatabaseTableType) => {
  return instance
    .get(`/metadata/tenant5/fetchtablemetadata/${table}/${database}`)
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
