import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Stack, Typography, MenuItem } from "@mui/material";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IconOnButton } from "../buttons/IconOnButton";
import { TextButton } from "../buttons/TextButton";
import { OrgWorkspace, WorkspaceDetail } from "../models";
import { WorkspaceListState } from "./WorkspaceListSlice";
import './Workspaces.scss';
import { CollapsibleTable } from "../CollapsibleTable";
import { setWorkspace } from './WorkspaceSlice';
import { EmptyPage } from '../EmptyPage';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { loginAuthState, setHeader } from '../onBoarding/LoginAuthSlice';
import { ButtonLink } from '../../ButtonLink';
import { useEnablePaasServiceMutation, usePostWorkspaceMutation } from '../../api/workspaceApi_rtk';
import { toastError } from '../Toasts';

type WorkspaceListGrid = {
  handleOpen: () => void,
  refresh: () => void,
}

type Workspace = {
  workspaceDetail: WorkspaceDetail
}

export const WorkspaceList: React.FC<WorkspaceListGrid> = ({ handleOpen, refresh }) => {
  const dispatch = useAppDispatch();
  const { workspaces } = useAppSelector(WorkspaceListState);

  const navigate = useNavigate();
  const handleNavigate = () => {
    dispatch(setHeader("Data Warehouse"));
    navigate("/warehouses");
  }
  const [postPaasService] = useEnablePaasServiceMutation();
  const loginAuth = useAppSelector(loginAuthState);

  const handleNavigateCloud = async (workspace: WorkspaceDetail) => {
    // dispatch(setWorkspace(workspace));

    const orgWorkspace: OrgWorkspace = {
      org_name: loginAuth.organizationName,
      workspace: workspace
    };
    postPaasService(orgWorkspace)
      .unwrap()
      .then((response) => {
        console.log({ response });
        dispatch(setWorkspace(response));
        navigate("/enable-PaaS", { state: { workspace_id: response.workspace_id } });
      })
      .catch((error) => {
        console.log({ error });
        toastError('Unable to enable PaaS service');
      });
  };

  const DisplayContent: React.FC<Workspace> = ({ workspaceDetail: workspace }) => {
    return ((workspace.status === "CREATED" && workspaces?.length === 1))
      ? (<Typography sx={{
        backgroundColor: "#F8FFF2", padding: '1em',
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18
      }}>

        <b style={{ color: "#00943B" }}>Workspace setup is successfully completed!</b> Create a  <ButtonLink
          variant="body1"
          navigateTo={() => handleNavigate()}
          buttonText=" DataWarehouse"
        /> to start analyzing data.
        <br />
        {(process.env.REACT_APP_ENV === "dev") &&
          <>Click here to enable
            <ButtonLink
              variant="body1"
              navigateTo={() => handleNavigateCloud(workspace)}
              buttonText=" PaaS Service"

            />.</>}
        {"  "}Wait 2-3 minutes to continue exploring after creation of your workspace.
      </Typography>)
      : (<Typography sx={{
        backgroundColor: "#D5C8FF", padding: '1em',
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18
      }}>
        <b style={{ color: "#00943B" }}>Workspace setup is successfully completed!</b> Create a  <ButtonLink
          variant="body1"
          navigateTo={() => handleNavigate()}
          buttonText=" DataWarehouse"
        /> to start analyzing data.
        <br />
        {(process.env.REACT_APP_ENV === "dev") &&
          <> Click here to enable    <ButtonLink
            variant="body1"
            navigateTo={() => handleNavigateCloud(workspace)}
            buttonText=" PaaS Service"
          />.</>}
        {"  "}Wait 2-3 minutes to continue exploring after creation of your workspace.
      </Typography>)
  }
  const MenuOptions: React.FC<Workspace> = ({ workspaceDetail: workspace }) => {
    return (<div>
      <MenuItem onClick={() => {
        dispatch(setWorkspace(workspace));
      }}>
        Default Workspace
      </MenuItem>
      {/* <MenuItem onClick={() => alert(`Delete function is not implemented, Workspace id: ${workspace.workspace_id}`)}>
        Delete Workspace
      </MenuItem> */}
    </div>);
  }
  const displayContent = new Map<string, React.ReactNode>();
  const menuOptions = new Map<string, React.ReactNode>();
  const isSubTreeLoaded = new Map<string, boolean>();
  workspaces?.forEach(workspaceDetail => {
    displayContent.set(workspaceDetail.workspace_id, <DisplayContent workspaceDetail={workspaceDetail} />);
    menuOptions.set(workspaceDetail.workspace_id, <MenuOptions workspaceDetail={workspaceDetail} />);
    isSubTreeLoaded.set(workspaceDetail.workspace_id, true);
  })
  return (
    <>{(workspaces !== undefined && workspaces?.length !== 0)
      ? <Fragment>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="stretch"
          spacing={1}
          marginTop={1}
          marginBottom={2}
        >
          <IconOnButton handleClick={refresh} materialIcon={<RefreshRoundedIcon />} />
          <TextButton handleClick={handleOpen} buttonText={"Create Workspace"} variant={'contained'} fullWidth={false}
            disabled={false} />
        </Stack>
        <CollapsibleTable
          data={workspaces}
          mainId='workspace_id'
          page='workspaces'
          skipKeys={[
            "workspace_id",
            "status",
            "redirectUrl",
            "role_name",
            "s3BucketName",
            "stack_name",
            "vegaUserName",
            "tenant_id"
          ]}
          displayContent={displayContent}
          menuOptions={menuOptions}
          isSubTreeLoaded={isSubTreeLoaded}
          rowAction={function (fetch_id: string): void {
            throw new Error('Function not implemented.');
          }}
          height={"500px"} />
      </Fragment>
      : <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <IconOnButton handleClick={refresh} materialIcon={<RefreshRoundedIcon />} />
        <EmptyPage
          title='Create workspace to get started!'
          body='You can create multiple workspaces and assign relevant cloud resources.'
          button='Create Workspace'
          handleOpen={handleOpen}
          image='VegaLakeCloudUI_03'
          altImage='Data services and monitors'
        />
      </Stack>}
     </>)};