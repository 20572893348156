import { GetTokenSilentlyOptions, LogoutOptions} from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse} from '@auth0/auth0-spa-js'
type tokensGenerator = {
    (
      options: GetTokenSilentlyOptions & { detailedResponse: true }
    ): Promise<GetTokenSilentlyVerboseResponse>;
    (options?: GetTokenSilentlyOptions): Promise<string>;
    (options: GetTokenSilentlyOptions): Promise<
      GetTokenSilentlyVerboseResponse | string
    >;
  };
type logoutType = (options?: LogoutOptions | undefined) => void;
let getAccessTokenSilently:tokensGenerator;
let logout: logoutType;

export const sec = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: (func:tokensGenerator) => (getAccessTokenSilently = func),
    logout: () => logout,
    setLogout: (func:logoutType) => (logout=func)
};