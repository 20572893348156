import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { Homepage } from "./components/Homepage";
import { HOMEPAGE, LOG_IN, SIGN_UP, VERIFY_PAGE, INVITEE_SIGN_UP } from "./components/models/constants";
import { Login } from "./components/onBoarding/Login";
import { SignUp } from "./components/onBoarding/SignUp";
import { InviteeSignUp } from "./components/onBoarding/InviteeSignUp";
import { EmailVerification } from "./components/onBoarding/EmailVerification";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { sec } from "./security";
import Admin from "./components/Admin";
import { Warehouses } from "./components/Warehouse/Warehouses";
import { Worksheets } from "./components/Worksheet/Worksheets";
import { ConfigureCloudProvider } from "./components/Workspace/ConfigureCloudProvider";
import { CustomizedTreeView } from "./components/schema/CustomTreeView";
import { Workspaces } from "./components/Workspace/Workspaces";
import SessionChecker from "./session-checker";
import { Database } from "./components/Database/Database";
const ParentInviteeSignUp = withAuthenticationRequired(InviteeSignUp);
const ParentHomepage = withAuthenticationRequired(Homepage);
const ParentWorkspaces = withAuthenticationRequired(Workspaces);
const ParentConfigureCloudProvider = withAuthenticationRequired(ConfigureCloudProvider);
const ParentWarehouses = withAuthenticationRequired(Warehouses);
const ParentDatabases = withAuthenticationRequired(Database);
const ParentWorksheets = withAuthenticationRequired(Worksheets);
const ParentAdmin = withAuthenticationRequired(Admin);
export const App = () => {
  const {  getAccessTokenSilently, logout } = useAuth0();
    sec.setAccessTokenSilently(getAccessTokenSilently);
    sec.setLogout(logout);
  return (
    <div className="mainPage">
      <ToastContainer />
      <SessionChecker />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={`/${SIGN_UP}/*`} element={<SignUp />} />
        <Route path={`/${VERIFY_PAGE}`} element={<EmailVerification/>} />
        <Route path={`/${LOG_IN}`} element={<Login />} />
        <Route path={`/${INVITEE_SIGN_UP}/*`} element={<ParentInviteeSignUp />} />
        <Route path="workspaces" element={<ParentHomepage><ParentWorkspaces /></ParentHomepage>} />
        <Route path="enable-PaaS" element={<ParentHomepage><ParentConfigureCloudProvider /></ParentHomepage>} />
        <Route path="warehouses" element={<ParentHomepage><ParentWarehouses /></ParentHomepage>} />
        <Route path="database" element={<ParentHomepage><ParentDatabases /></ParentHomepage>} />
        <Route path="worksheets/*" element={<ParentHomepage><ParentWorksheets /></ParentHomepage>} />
        <Route path="admin/*" element={<ParentHomepage><ParentAdmin /></ParentHomepage>}/>
        <Route path="/*" element={<Login />} />
      </Routes>
    </div>
  );
};

