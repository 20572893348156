import {
  WorkspaceDetail,
  DataWarehouseModel,
  InteractiveQueryResponse,
} from "./components/models";

export const WORKSPACES: WorkspaceDetail[] = [
  {
    workspace_id: "628c4caa-4aeb-4c1b-b1dd-705b93e6c22f",
    workspace_name: "Headquarter-1",
    aws_region: "ap-south-1",
    cloud_provider: "aws",
    redirectUrl: "",
    role_name: "vdb-027e9413ba7646a883e04587caf6f2dfiam-role",
    s3BucketName: "vdb-926e3dec409b4b43ab44d9c678ddcff7-s3-root-bucket",
    stack_name: "vegadb-Headquarter-1-stack",
    vegaUserName: "auth0|63fc5517fe194bcbb381cdc4",
    status: "CREATED",
    tenant_id: "7481236857",
    pricingTier: "basic",
  },
  {
    workspace_id: "628c4caa-4aeb-4c1b-b1dd-705b93e6c22G",
    workspace_name: "Headquarter-2",
    aws_region: "ap-south-1",
    cloud_provider: "aws",
    redirectUrl: "",
    role_name: "vdb-027e9413ba7646a883e04587caf6f2dfiam-role",
    s3BucketName: "vdb-926e3dec409b4b43ab44d9c678ddcff7-s3-root-bucket",
    stack_name: "vegadb-Headquarter-2-stack",
    vegaUserName: "auth0|63fc5517fe194bcbb381cdc4",
    status: "PROVISIONING",
    tenant_id: "7481236857",
    pricingTier: "basic",
  },
];

export const WAREHOUSES_1 = [
  {
    id: "warehouse_0",
    name: "Warehouse0",
    status: "Running",
    auto_stop_time: 5,
    size: "2xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
  {
    id: "warehouse_1",
    name: "Warehouse1",
    status: "Suspended",
    auto_stop_time: 5,
    size: "xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
  {
    id: "warehouse_2",
    name: "Warehouse2",
    status: "Running",
    auto_stop_time: 10,
    size: "2xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
  {
    id: "warehouse_3",
    name: "Warehouse3",
    status: "Running",
    auto_stop_time: 5,
    size: "2xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
  {
    id: "warehouse_4",
    name: "Warehouse4",
    status: "Suspended",
    auto_stop_time: 5,
    size: "xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
  {
    id: "warehouse_5",
    name: "Warehouse5",
    status: "Running",
    auto_stop_time: 10,
    size: "2xbasic",
    created_at: "24 Dec, 2022",
    auto_scale: false,
  },
] as DataWarehouseModel[];

export const WAREHOUSES = [
  {
    id: "0ad18ec4-a39d-43bb-a501-4ab67d8c4182",
    name: "sample",
    size: "2xsmall",
    auto_scale: false,
    auto_scale_max_size: "",
    auto_resume: false,
    auto_stop: false,
    auto_stop_time: 0,
    multi_cluster: false,
    multi_cluster_min: 0,
    multi_cluster_max: 0,
    multi_cluster_current_size: 0,
    current_size: "",
    serverless: false,
    status: "stopped",
    spot: false,
    created_at: "0001-01-01 00:00:00 +0000 +0000",
  },
  {
    id: "d42d3186-8608-430b-96d7-09b98bc4b55a",
    name: "sample",
    size: "2xsmall",
    auto_scale: false,
    auto_scale_max_size: "",
    auto_resume: false,
    auto_stop: false,
    auto_stop_time: 0,
    multi_cluster: false,
    multi_cluster_min: 0,
    multi_cluster_max: 0,
    multi_cluster_current_size: 0,
    current_size: "",
    serverless: false,
    status: "running",
    spot: false,
    created_at: "0001-01-01 00:00:00 +0000 +0000",
  },
  {
    id: "7263c484-211b-428d-830f-9b84177af513",
    name: "sample",
    size: "2xsmall",
    auto_scale: false,
    auto_scale_max_size: "",
    auto_resume: false,
    auto_stop: false,
    auto_stop_time: 0,
    multi_cluster: false,
    multi_cluster_min: 0,
    multi_cluster_max: 0,
    multi_cluster_current_size: 0,
    current_size: "",
    serverless: false,
    status: "stopped",
    spot: false,
    created_at: "0001-01-01 00:00:00 +0000 +0000",
  },
  {
    id: "4f4b2839-9eef-4759-827d-b5f4a0e3ce8e",
    name: "Warehouse_1",
    size: "",
    auto_scale: false,
    auto_scale_max_size: "",
    auto_resume: false,
    auto_stop: false,
    auto_stop_time: 0,
    multi_cluster: false,
    multi_cluster_min: 0,
    multi_cluster_max: 0,
    multi_cluster_current_size: 0,
    current_size: "",
    serverless: false,
    status: "stopped",
    spot: false,
    created_at: "0001-01-01 00:00:00 +0000 +0000",
  },
] as DataWarehouseModel[];

export const WORKSHEETS_MAP: {
  [key: string]: {
    worksheet_id: string;
    worksheet_name: string;
    created_time: string;
    created_by: string;
    contents: string;
    version: string;
  };
} = {
  "1": {
    worksheet_id: "1",
    worksheet_name: "Marketing spends",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
    contents: "SELECT * FROM table_2;",
    version: "1",
  },
  "11": {
    worksheet_id: "11",
    worksheet_name: "Marketing ends",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
    contents: "SELECT * FROM table_1;",
    version: "1",
  },
  "e9fcd031-6781-4a9d-94e7-9d9d82a9ba8c": {
    worksheet_id: "e9fcd031-6781-4a9d-94e7-9d9d82a9ba8c",
    worksheet_name: "Marketing trends",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
    contents: "SELECT * FROM table_3;",
    version: "1",
  },
};

export const WORKSHEETS: {
  [key: string]: {
    worksheet_id: string;
    worksheet_name: string;
    recently_accessed: string;
    created_time: string;
    created_by: string;
    folder_id: string;
  }[];
} = {
  "1": [
    {
      worksheet_id: "1",
      worksheet_name: "Marketing spends",
      recently_accessed: "Last opened 30 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "11",
      worksheet_name: "Marketing ends",
      recently_accessed: "Last opened 32 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "12",
      worksheet_name: "Marketing treads",
      recently_accessed: "Last opened 35 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "13",
      worksheet_name: "Marketing sends",
      recently_accessed: "Last opened 32 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "14",
      worksheet_name: "Marketing gains",
      recently_accessed: "Last opened 36 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "15",
      worksheet_name: "Marketing loses",
      recently_accessed: "Last opened 3 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
    {
      worksheet_id: "16",
      worksheet_name: "Marketing washes",
      recently_accessed: "Last opened 7 mins ago",
      created_time: "Created on 24 Nov, 2022",
      created_by: "Nick",
      folder_id: "1",
    },
  ],
  "27": [
    {
      worksheet_id: "27",
      worksheet_name: "User Onboarding",
      recently_accessed: "Last opened 04 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "28",
      worksheet_name: "User exiting",
      recently_accessed: "Last opened 07 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "29",
      worksheet_name: "User hiring",
      recently_accessed: "Last opened 21 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "20",
      worksheet_name: "User firing",
      recently_accessed: "Last opened 64 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "21",
      worksheet_name: "User data",
      recently_accessed: "Last opened 04 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "22",
      worksheet_name: "User management",
      recently_accessed: "Last opened 04 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "23",
      worksheet_name: "User Stalling",
      recently_accessed: "Last opened 04 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
    {
      worksheet_id: "25",
      worksheet_name: "User Walling",
      recently_accessed: "Last opened 04 days ago",
      created_time: "Created on 23 Nov, 2022",
      created_by: "Jesse",
      folder_id: "27",
    },
  ],
  "37": [
    {
      worksheet_id: "37",
      worksheet_name: "Payment issues",
      recently_accessed: "Last opened 02 months ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
    {
      worksheet_id: "35",
      worksheet_name: "Payment fixes",
      recently_accessed: "Last opened 06 months ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
    {
      worksheet_id: "34",
      worksheet_name: "Payment successes",
      recently_accessed: "Last opened 04 months ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
    {
      worksheet_id: "33",
      worksheet_name: "Payment failures",
      recently_accessed: "Last opened 09 months ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
    {
      worksheet_id: "32",
      worksheet_name: "Payment gains",
      recently_accessed: "Last opened 08 days ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
    {
      worksheet_id: "31",
      worksheet_name: "Payment uses",
      recently_accessed: "Last opened 08 days ago",
      created_time: "Created on 19 Nov, 2022",
      created_by: "Ben",
      folder_id: "37",
    },
  ],
};

export const FOLDERS = [
  {
    folder_id: "1",
    folder_name: "Marketing",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
  },
  {
    folder_id: "12",
    folder_name: "Treads",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
  },
  {
    folder_id: "14",
    folder_name: "Gains",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
  },
  {
    folder_id: "15",
    folder_name: "Loses",
    created_time: "Created on 24 Nov, 2022",
    created_by: "Nick",
  },
  {
    folder_id: "27",
    folder_name: "User",
    created_time: "Created on 23 Nov, 2022",
    created_by: "Jesse",
  },
  {
    folder_id: "37",
    folder_name: "Payment",
    created_time: "Created on 19 Nov, 2022",
    created_by: "Ben",
  },
  {
    folder_id: "35",
    folder_name: "Billing",
    created_time: "Created on 19 Nov, 2022",
    created_by: "Ben",
  },
];

export const VERSIONS: {
  version: string;
}[] = [
  {
    version: "1",
  },
  {
    version: "2",
  },
  {
    version: "3",
  },
  {
    version: "4",
  },
  {
    version: "5",
  },
];

export const ROLES = [
  {
    id: "1",
    name: "Admin",
    description: "Organization Admin",
  },
  {
    id: "2",
    name: "User",
    description: "Organization User",
  },
];

export const USERS = [
  {
    userId: "1",
    name: "Abi",
    email: "Added on 24 Nov, 2022",
    organizationId: "last active on 24 Nov, 2022",
    rolesLst: [{ id: "2", name: "User", description: "Organization User" }],
  },
  {
    userId: "12",
    name: "Nick",
    email: "Added on 24 Nov, 2022",
    organizationId: "last active on 24 Nov, 2022",
    rolesLst: [{ id: "2", name: "User", description: "Organization User" }],
  },
  {
    userId: "14",
    name: "Robert",
    email: "Added on 24 Nov, 2022",
    organizationId: "last active on 24 Nov, 2022",
    rolesLst: [{ id: "2", name: "User", description: "Organization User" }],
  },
  {
    userId: "15",
    name: "Ameer",
    email: "Added on 23 Nov, 2022",
    organizationId: "last active on 23 Nov, 2022",
    rolesLst: [{ id: "1", name: "Admin", description: "Organization Admin" }],
  },
  {
    userId: "27",
    name: "Aurar",
    email: "Added on 23 Nov, 2022",
    organizationId: "last active on 23 Nov, 2022",
    rolesLst: [{ id: "1", name: "Admin", description: "Organization Admin" }],
  },
  {
    userId: "37",
    name: "Zhukov",
    email: "Added on 19 Nov, 2022",
    organizationId: "last active on 19 Nov, 2022",
    rolesLst: [{ id: "1", name: "Admin", description: "Organization Admin" }],
  },
  {
    userId: "35",
    name: "Razique",
    email: "Added on 19 Nov, 2022",
    organizationId: "last active on 19 Nov, 2022",
    rolesLst: [
      { id: "1", name: "Admin", description: "Organization Admin" },
      { id: "2", name: "User", description: "Organization User" },
    ],
  },
];

export const SQL_HISTORIES = [
  {
    history_id: "1",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "12",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "14",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "15",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "27",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "37",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
  {
    history_id: "35",
    sql: "select count (*) from ontime;",
    status: "Success",
    warehouse: "Data Warehouse 01 (Basic - 2X)",
    duration: "200ms",
  },
];

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
export const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

 export const mockSignUpResponse= { data: 
  { name: "vikash", 
 email: "vikash@vegalake.com", 
 verify_email: true, },
 tenantId: "tenant123", 
 email: "vikash@vegalake.com", };

 export const mockOrganization=[
  { 
    org_name: "vikash@vegalake.com",
    org_id: "123456789" 
   },
 ];

 
// export const INTERACTIVE_QUERY_RESPONSE: InteractiveQueryResponse[] = [
//   {
//     created_at: "",
//     query_id: "1",
//     query: "SELECT * FROM table_1;",
//     started_at: "",
//   },
//   {
//     created_at: "",
//     query_id: "2",
//     query: "SELECT * FROM table_2;",
//     started_at: "",
//   },
//   {
//     created_at: "",
//     query_id: "3",
//     query: "SELECT * FROM table_3;",
//     started_at: "",
//   },
// ];
