import { loadStripe} from "@stripe/stripe-js";
import { Appearance } from "@stripe/stripe-js";
import StripeConfig from "../../stripe_configuration.json";
import { Elements } from "@stripe/react-stripe-js";
import { BillingInterface } from "./BillingInterface";

const Billing =() =>{
    const StripePromise = loadStripe(StripeConfig.public_key);
        const appearance:Appearance = {
            theme: "flat",
            variables: {
                colorPrimary: 'red',
                colorBackground: 'white',
                colorText: 'black',
                colorDanger: '#df1b41',
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                spacingUnit: '3px',
                borderRadius: '8px',
              
               
                // See all possible variables below
              }
          };
          return (
            <Elements stripe={StripePromise} options={{appearance}}>
                <BillingInterface />   
            </Elements>);
}

export default Billing;