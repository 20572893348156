import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "./fetchBaseQueryObj";
import {
  InteractiveQueryModel,
  InteractiveQueryResponse,
  InteractiveQueryStatus,
  InteractiveQueryResult,
  InteractiveListOfQuery
} from "../components/models";

const BASE_URL = "/api/v1/vegadb/datawarehouse/sql/interactive_query";

export const interactiveQueryApi = createApi({
  reducerPath: "interactiveQueryPath",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    submitQuery: builder.mutation<InteractiveQueryResponse,InteractiveQueryModel>({
      query: (interactiveQuery) => ({
        url: `${BASE_URL}`,
        method: "POST",
        body: interactiveQuery,
      }),
      transformResponse: (response: InteractiveQueryResponse, _meta, _arg) =>response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getQueryStatus: builder.mutation<InteractiveQueryStatus, string>({
      query: (queryId) => ({
        url: `${BASE_URL}/${queryId}`,
        method: "GET",
      }),
      transformResponse: (response: InteractiveQueryStatus, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    queryResult: builder.mutation<InteractiveQueryResult, string>({
      query: (queryId) => ({
        url: `${BASE_URL}/${queryId}/result`,
        method: "GET",
      }),
      transformResponse: (response: InteractiveQueryResult, _meta, _arg) =>response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    cancelQuery: builder.mutation<string, string>({
      query: (queryId) => ({
        url: `${BASE_URL}/${queryId}/cancel`,
        method: "POST",
      }),
      transformResponse: (response: string, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
    getAllSqlQueries: builder.query<InteractiveListOfQuery,void>({
      query: () => ({
        url: `${BASE_URL}`,
        method: "GET",
      }),
      transformResponse: (response: InteractiveListOfQuery, _meta, _arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        _meta,
        _arg
      ) => response.status,
    }),
  }),
});

export const {
  useCancelQueryMutation,
  useQueryResultMutation,
  useGetQueryStatusMutation,
  useSubmitQueryMutation,
  useGetAllSqlQueriesQuery
} = interactiveQueryApi;